import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CupsService } from 'src/services/cups.service';

@Component({
  selector: 'app-evem-request',
  templateUrl: './evem-request.component.html',
  styleUrls: ['./evem-request.component.css']
})
export class EvemRequestComponent implements OnInit {
  cupRequestData: any;
  type: any;
  formData = {};
  apiResp: any;
  selectedStatus: any;
  requestAPI = {
    "rebate_submissions_id": 0,
    "pm_request_id": 0,
    "request_id": 0,
    "distributor_invoice_number": "",
    "shipped_date": "2021-11-25T01:13:20.502Z",
    "quantity_shipped": 0,
    "total_creditdue": 0,
    "comments": "",
    "invoice": "",
    "quantity": 0,
    "enter_po": "",
    "rebate_final": 0,
    "created_by": "",
    "cups_intostock_id": 0,
    "businessunit_id": 0,
    "distributor_supported_price":0,
    "price_diff":0,
    "price_to_end_user": 0,
    "basf_invoice_price": 0
  };
  formMode: any;
  selectedBusinessUnit: any;
  bu: any;
  EVID = 2;
  EMID = 1;
  PMID = 3;
  EDID = 4;
  priceData: any;
  statusOptions = [
    { id: 1, name: 'Pending'},
    { id: 2, name: 'Rejected'},
    { id: 3, name: 'Approved'}
  ];
  userAdmin: boolean = false;

  constructor(private router: Router, private _service: CupsService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit() {
    this.userAdmin = (sessionStorage.getItem('IsAdmin') === "1" || sessionStorage.getItem('IsAdmin') === "true") ? true : false;
    if (!history.state.data) {
      this.router.navigate(['/rebates']);
    } else {
      this.bu = sessionStorage.getItem('BU');
      switch (this.bu) {
        case 'EM': this.selectedBusinessUnit = this.EMID; break;
        case 'EV': this.selectedBusinessUnit = this.EVID; break;
        case 'PM': this.selectedBusinessUnit = this.PMID; break;
        case 'ED': this.selectedBusinessUnit = this.EDID; break;
        default: this.selectedBusinessUnit = this.EMID; break;
      }
      this.cupRequestData = history.state.data.rowData;
      this.type= history.state.data.type;
      if (history.state.data.type === 'edit') {
        this.formData = JSON.parse(JSON.stringify(this.cupRequestData));
        this.formMode = 'Edit';
        this.formData['shipped_date'] = moment(this.formData['shipped_date']).format('yyyy-MM-DD');
        this.generateDateFormats();
      } else {
        this._service.getRebateFormData(this.cupRequestData.request_id, this.cupRequestData.businessunit_id).subscribe(response => {
          if (!response['isError']) {
            this.apiResp = response;
            this.formData = JSON.parse(JSON.stringify(this.apiResp.rebateEvemData[0]));
            this.generateDateFormats();
          } else {
            this._service.displayToastr(response);
          }
        })
      }
    }
  }

  getFormattedDate(date) {
    return moment(date).format("yyyy-MM-DD");
  }
  getFormattedEndDate(date) {
    const dateNow = new Date();
    const iDate = new Date(date);

    if(iDate.getTime() > dateNow.getTime()) {
      const dateYesterday = new Date(dateNow);
      dateYesterday.setDate(dateYesterday.getDate());
      
      return moment(dateYesterday).format("yyyy-MM-DD");
    }
    else {      
      return moment(date).format("yyyy-MM-DD");
    }
  }

  generateDateFormats() {
    this.formData['submission_date'] = moment(this.formData['submission_date']).format('YYYY/MM/DD');
    this.formData['effective_date'] = moment(this.formData['effective_date']).format('YYYY/MM/DD');
    this.formData['expiration_date'] = moment(this.formData['expiration_date']).format('YYYY/MM/DD');
    this.formData['cups_intostock_id'] = this.cupRequestData.cups_intostock_id;
    this.selectedStatus = this.statusOptions.find(so => so.id === this.cupRequestData.status);
    this.formData['distributor_invoice_number'] = this.cupRequestData.distributor_invoice_number;
  }

  doCalculations() {
    let basfInvoicePrice = Number(this.formData['basf_invoice_price']);
    let distributedSupportPrice = Number(this.formData['distributor_supported_price']);
    this.formData['price_diff'] = (basfInvoicePrice - distributedSupportPrice).toFixed(2).toString();
    if (this.formData['quantity_shipped']) {
      let quantity_shipped = Number(this.formData['quantity_shipped']);
      this.formData['total_creditdue'] = (quantity_shipped * Number(this.formData['price_diff'])).toFixed(2);
    }
  }

  getDistributorPrice(date: any) {
    let foundPrices = [];
    if (this.priceData.length) {
      this.priceData.forEach(pd => {
        let currentDate = new Date(pd.effective_date_of_change);
        let shippedDate = new Date(date);
        if (shippedDate.getTime() > currentDate.getTime()) {
          foundPrices.push(pd);
        }
      });
      if (foundPrices.length && (this.selectedBusinessUnit === 1 || this.selectedBusinessUnit === 4) ) {
        this.assignEMPrices(foundPrices);
      }
      else if (foundPrices.length && this.selectedBusinessUnit === 2) {
        this.assignEVPrices(foundPrices);
      } else {
        this.formData['basf_invoice_price'] = this.cupRequestData['distributor_price_from_basf'];
        this.formData['distributor_supported_price'] = this.cupRequestData['net_support_price'];
        this.formData['price_to_end_user'] = this.cupRequestData['support_price_to_end_customer'];
      }
    } else {
      this.formData['basf_invoice_price'] = this.cupRequestData['distributor_price_from_basf'];
      this.formData['distributor_supported_price'] = this.cupRequestData['net_support_price'];
      this.formData['price_to_end_user'] = this.cupRequestData['support_price_to_end_customer'];
    }
    this.doCalculations();
  }

  assignEMPrices(foundPrices: any[]) {
    let minDate = foundPrices.reduce((a, b) => new Date(a.effective_date_of_change) > new Date(b.effective_date_of_change) ? a : b)
    this.formData['basf_invoice_price'] = minDate['distributor_price_from_basf'];
    this.formData['distributor_supported_price'] = minDate['net_support_price'];
    this.formData['price_to_end_user'] = minDate['support_price_to_end_customer'];
  }

  assignEVPrices(foundPrices: any[]) {
    foundPrices = foundPrices.filter(fp => fp.cupReqId === this.formData['request_reference_number']);
    let minDate = foundPrices.reduce((a, b) => new Date(a.effective_date_of_change) > new Date(b.effective_date_of_change) ? a : b)
    this.formData['basf_invoice_price'] = minDate['distributor_price_from_basf'];
    this.formData['distributor_supported_price'] = minDate['net_support_price'];
    this.formData['price_to_end_user'] = minDate['support_price_to_end_customer'];
  }

  receivePriceData(priceData: any) {
    this.priceData = priceData;
  }


  submitRebate() {
    if (this.checkValidations()) {
      this.requestAPI.created_by = sessionStorage.getItem('userid');
      this.requestAPI.distributor_invoice_number = this.formData['distributor_invoice_number'];
      this.requestAPI.quantity_shipped = Number(this.formData['quantity_shipped']);
      this.requestAPI.request_id = this.formData['request_id'];
      this.requestAPI.shipped_date = this.formData['shipped_date'];
      this.requestAPI.total_creditdue = this.formData['total_creditdue'];
      this.requestAPI.comments = (this.formData['comments'] !== null) ? this.formData['comments'] : '';
      this.requestAPI.cups_intostock_id = this.formData['cups_intostock_id'];
      this.requestAPI.price_to_end_user = this.formData['price_to_end_user'];
      this.requestAPI.price_diff = this.formData['price_diff'];
      this.requestAPI.basf_invoice_price = this.formData['basf_invoice_price'];
      this.requestAPI.distributor_supported_price = this.formData['distributor_supported_price'];
      if (this.formMode === 'Edit') {
        this.requestAPI.rebate_submissions_id = this.formData['rebate_submissions_id'];
        this.requestAPI.cups_intostock_id = 5;
        this.requestAPI['rebate_reference_number'] = this.formData['rebate_reference_number'];
      }
      this.requestAPI.businessunit_id = this.selectedBusinessUnit;
      if ((this.selectedBusinessUnit === 1 || this.selectedBusinessUnit === 4) && this.userAdmin) {
        this.generateAdminValues();
      }
      else {
        this.generateNonAdminValues();
      }
      this.spinner.show();
      this._service.createRebate(this.requestAPI).subscribe(response => {
        if (!response['isError']) {
        this.apiResp = response;
        this.spinner.hide();
        this._service.displayToastr(response);
        this.router.navigate(['/rebates']);
        } else{
          this.spinner.hide();
          this._service.displayToastr(response);
        }
      })
    }
  }

  generateNonAdminValues() {
    this.requestAPI['status'] = 1;
    this.requestAPI['credit_invoice'] = "";
    this.requestAPI['value'] = 0;
    this.requestAPI['admincomments'] = "";
    this.requestAPI['rebate_reference_number'] = "";
  }

  generateAdminValues() {
    this.requestAPI['status'] = this.selectedStatus.id;
    this.requestAPI['rebate_reference_number'] = this.formData['rebate_reference_number'];
    this.requestAPI['credit_invoice'] = this.formData['credit_invoice'];
    this.requestAPI['value'] = this.formData['value'];
    this.requestAPI['admincomments'] = this.formData['admincomments'];
  }

  cancel() {
    this.router.navigate(['/rebates']);
  }

  checkValidations() {
    if (!this.formData['distributor_invoice_number']) {
      this.toastr.error("Distributor Invoice Number Required");
      return false;
    }
    if (!this.formData['shipped_date']) {
      this.toastr.error("Date Shipped to End User Required");
      return false;
    }
    if (!this.formData['quantity_shipped']) {
      this.toastr.error("Quanitity Shipped Required");
      return false;
    }
    return true;
  }
}
