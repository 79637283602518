import { Directive, HostListener,Input } from "@angular/core";
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[numbersOnly]'
})
export class NumbersOnlyDirective {
  @Input('field') field;

  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event']) onInput(event): void {
    if (this.field === 'float') {
      const value = event.target.value;
      if (value != '') {
        this.ngControl.control.setValue(parseFloat(value).toFixed(2));
        event.target.value = (value.match(/\d+\.?\d{0,2}/g) || [null])[0];
      } else {
        this.ngControl.control.setValue(value);
        event.target.value = (value.match(/\d+\.?\d{0,2}/g) || [null])[0];
      }
    }
    if (this.field === 'integers') {
        const value = event.target.value;
        this.ngControl.control.setValue(parseInt(value) || '');
      }
  }
}
