import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CupsService } from 'src/services/cups.service';

@Component({
  selector: 'app-distributor-grid',
  templateUrl: './distributor-grid.component.html',
  styleUrls: ['./distributor-grid.component.css']
})
export class DistributorGridComponent implements OnInit {
  userId:any;
  isdistributor:any;
  apiResp: any;
  sortedCols:any;
  distributorGridData: any;
  selectedCols: any;
  cols: any;
  showForm = false;
  showView = false;
  selectedRow = {};
  formType = "";
  @Input() businessUnit: any;
  constructor(private router: Router, private spinner: NgxSpinnerService, private toastr: ToastrService, private _service: CupsService) { }

  ngOnInit() {
    this.showForm = false;
    this.getSessionValues()
    this.getDistributorGrid();

  }
  getSessionValues(){
    this.userId = sessionStorage.getItem('userid');
    this.isdistributor=sessionStorage.getItem('isDistributor');
  }

  getDistributorGrid() {
    this._service.getDistributorsGridData(this.businessUnit,this.userId,this.isdistributor).subscribe(response => {
      this.showForm = false;
      if (!response['isError']) {
        this.apiResp = response;
        if (this.businessUnit != 1 && this.businessUnit !=4) {
          this.apiResp.headerColumns = this.apiResp.headerColumns.filter(h => h.field != 'distributor_state');
        }
        this.cols = this.apiResp.headerColumns.filter(h => h.is_grid_display);
        this.sortedCols = JSON.parse(JSON.stringify(this.cols.filter(x=>x.is_show)));
        this.sortedCols = this.sortedCols.sort((a, b) => {
          return a.header > b.header ? 1 : -1;
        });
        this.selectedCols = this.apiResp.headerColumns.filter(h => h.is_grid_display && h.is_show && h.is_user_visible);
        this.distributorGridData = this.apiResp.distributorData;
      } else {
        this._service.displayToastr(response);
      }
    })
  }

  onChangeDDValue() {
    this.sortColumns();
  }
  updateUserColumns(){
    this._service.updateUserGridCols(this.selectedCols,this.userId,this.isdistributor).subscribe((response:any)=>{
      this._service.displayToastr(response);
    });
  }

  sortColumns() {
    this.selectedCols = this.selectedCols.sort((a, b) => {
      return a.serial_no - b.serial_no;
    })
  }

  addNewDistributor() {
    this.showForm = true;
    this.formType = 'New';
  }

  refreshDistributors(eventvalue) {
    if(eventvalue) {
      this.getDistributorGrid();
    } else {
      this.showForm = false;
    }
  }

  onSelectRow(rowObject) {
    if (rowObject.type == 'view') {
      this.showView = true;
    } else {
      this.showForm = true; 
      this.formType = 'Edit';     
    }
    this.selectedRow = rowObject['rowData'];
  }

  backToGrid(_event: any) {
    this.showForm = false;
    this.showView = false;
  }

}
