import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CupsService } from 'src/services/cups.service';
@Component({
  selector: 'app-sales-commerical-manager',
  templateUrl: './sales-commerical-manager.component.html',
  styleUrls: ['./sales-commerical-manager.component.css']
})
export class SalesCommericalManagerComponent implements OnInit {
  BusinessUnitData: any;
  CountryData: any;
  RegionData: any;
  response: any;
  BusinessUnit: any;
  SBusinessUnit: any;
  selectedSBusiness: any;
  selectedBusiness:any;
  cols:any;
  selectedValues:any
  UsersData=[];
  Bu_user:any;
  filteredFields = [];
  usersData: any;
  filteredData: any;
  tempFilteredData: any;
  GridData: any;
  selectedSalesManager: any;
  selectedCommercialManager: any;
  selectedCountry: any;
  selectedRegion: any;
  availableSalesManagers: any;
  availableCommercialManagers: any;

  constructor(private _service: CupsService,private spinner: NgxSpinnerService, private toastr: ToastrService) { }
  ngOnInit() {
    this.cols = [
      {
        "header_id": 2,
        "field": "user_name",
        "header": "User Name",
        "is_show": true,
        "is_editable": false,
        "serial_no": 2
      },
      {
        "header_id": 5,
        "field": "region_name",
        "header": "Region",
        "is_show": true,
        "is_editable": false,
        "serial_no": 5
      },
      {
        "header_id": 6,
        "field": "country_name",
        "header": "Country",
        "is_show": true,
        "is_editable": false,
        "serial_no": 6
      },
      {
        "header_id": 7,
        "field": "role_name",
        "header": "Type",
        "is_show": true,
        "is_editable": false,
        "serial_no": 7
      }
    ]
    this._service.getAdminConfigMaster().subscribe((res1) => {
      if (!res1['isError']) {
        this.response = res1;
        this.BusinessUnitData = this.response.buData;
        this.CountryData = this.response.countryData;
        this.RegionData = this.response.regionData;
        let key = 'businessunit_id';
        this.BusinessUnit = [...new Map(this.BusinessUnitData.map(item =>
          [item[key], item])).values()];

        const currentBU = sessionStorage.getItem("BU");
        this.BusinessUnit.forEach(bu => {
          if(bu.businessunit_name === currentBU) {
            this.selectedBusiness = bu.businessunit_id;
            this.getBuUser(this.selectedBusiness);
            this.getAllSalesCommericalManger(this.selectedBusiness);
          }
        });
      } else {
        this._service.displayToastr(res1);
      }
    })
  }

  getAllSalesCommericalManger(id) {
    this._service.getAllSalesCommericalManger(id).subscribe(res => {
      if (!res['isError']) {
        this.UsersData = res['response'];
      } else {
        this._service.displayToastr(res);
      }
    })
  }

  getBuUser(value) {
    let bu_id = value;
    this._service.getBUUsers(bu_id).subscribe((res) => {
      if (!res['isError']) {
        this.Bu_user = this.getUniqueListBy(res['lstUsers'],'user_name');
      } else {
        this._service.displayToastr(res);
      }
    })
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  filterUsersByCountry(_event) {
    this.availableCommercialManagers = null;
    this.availableSalesManagers = null;
    switch(this.selectedCountry.country_id) {
      case 1: this.getUsersByUSCountry(); break;
      case 2: this.getUsersByMexicoCountry(); break;
      default: this.getUsersByUSCountry(); break;
    }
  }

  getUsersByUSCountry() {
    this.availableCommercialManagers = null;
    this.availableSalesManagers = null;
      this.availableCommercialManagers = JSON.parse(JSON.stringify(this.Bu_user));
      this.availableSalesManagers = JSON.parse(JSON.stringify(this.Bu_user));
  }

  getUsersByMexicoCountry() {
    this.availableCommercialManagers = null;
    this.availableSalesManagers = null;
    this.availableCommercialManagers = null;
      this.availableCommercialManagers = JSON.parse(JSON.stringify(this.Bu_user));
      this.availableSalesManagers = JSON.parse(JSON.stringify(this.Bu_user));
  }

  submitData(value){
    if (this.validationSalesCommercialmanager()) {
      let data = {
        "businessUnit": this.selectedBusiness,
        "country": (this.selectedCountry.country_id) ? this.selectedCountry.country_id : 0,
        "region_id": (this.selectedRegion.region_id) ? this.selectedRegion.region_id : 0,
        "salesManager_id": (this.selectedSalesManager) ? this.selectedSalesManager.user_id : 0,
        "commercialManager_id": (this.selectedCommercialManager) ? this.selectedCommercialManager.user_id : 0,
        "created_user_id": sessionStorage.getItem('userid')
      }
      this._service.postSalescommercialUsers(data).subscribe(res => {
        this.postSalesCommercialResp(res);
        this.getAllSalesCommericalManger(this.selectedBusiness);
      })
    }
  }

  postSalesCommercialResp(res: any) {
    if (!res['isError']) {
      if (res == "Data saved successfully.") {
        this.GridData = this.UsersData;
      }
      else {
        this.UsersData = []
      }
    } else {
      this._service.displayToastr(res);
    }
  }

  validationSalesCommercialmanager() {
    if(!this.selectedSalesManager && !this.selectedCommercialManager) {
      this.toastr.error('Select any user either for Sales Manager or Commercial Manager');
      return false;
    } else {
      return true;
    }
  }

  filterData(filterObj) {
    let {value, filter } = filterObj;
    if (value == '') {
      if (this.filteredFields.length) {
        this.filteredFields = this.filteredFields.filter(ff => ff.fieldName !== filter);
      } else {
        this.filteredFields = [];
      }
    } else {
      let foundField = this.filteredFields.filter(ff => ff.fieldName == filter);
      if (foundField.length) {
        this.filteredFields.forEach(ff => {
          if (ff.fieldName == filter) {
            ff.value = value;
          }
        })
      } else {
        let fieldObj = {
          fieldName: filter,
          value: value
        };
        this.filteredFields.push(fieldObj);
      }
    }
    if (this.filteredFields.length) {
      let temp = this.usersData;
      this.filteredFields.forEach(ff => {
        temp = temp.filter(fd => fd[ff.fieldName].toUpperCase().includes(ff.value.toUpperCase()));
      })
      this.filteredData = temp;
    } else {
      this.filteredData = JSON.parse(JSON.stringify(this.usersData));
    }
    this.tempFilteredData = JSON.parse(JSON.stringify(this.filteredData));
  }
}
