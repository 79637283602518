import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CupsService } from 'src/services/cups.service';

@Component({
  selector: 'app-pm-rebate-request',
  templateUrl: './pm-rebate-request.component.html',
  styleUrls: ['./pm-rebate-request.component.css']
})
export class PmRebateRequestComponent implements OnInit {

  cupRequestData: any;
  formData: any;
  apiResp: any;
  type: any;
  requestAPI = {
    "rebate_submissions_id": 0,
    "pm_request_id": 0,
    "request_id": 0,
    "distributor_invoice_number": "",
    "shipped_date": "",
    "quantity_shipped": 0,
    "total_creditdue": 0,
    "comments": "",
    "invoice": "",
    "quantity": 0,
    "enter_po": "",
    "rebate_final": 0,
    "created_by": "",
    "cups_intostock_id": 5,
    "businessunit_id": 0,
    "rebate":0,
    "net_support_cost": 0,
    "list_price_LB": 0
  };
  formMode = 'New';
  selectedBusinessUnit: any;
  bu: any;
  EVID = 2;
  EMID = 1;
  PMID = 3;
  EDID = 4;
  priceData: any;
  constructor(private router: Router, private _service: CupsService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit() {    
    if (!history.state.data) {
    this.router.navigate(['/rebates']);
    } else {
      this.cupRequestData = history.state.data.rowData;
      this.type= history.state.data.type;
      if (history.state.data.type === 'edit') {
        this._service.getRebateFormData(this.cupRequestData.pm_request_id, '3').subscribe(response => {
          if (!response['isError']) {
          this.apiResp = response;
          this.cupRequestData = JSON.parse(JSON.stringify(this.apiResp.rebatePMData[0]));
          } else {
            this._service.displayToastr(response);
          }
        })
        this.formData = JSON.parse(JSON.stringify(this.cupRequestData));
        this.formMode = 'Edit';
        this.formData['shipped_date'] = moment(this.formData['shipped_date']).format('yyyy-MM-DD');
      } else {
        this.formMode = 'New';
      this._service.getRebateFormData(this.cupRequestData.pm_request_id, '3').subscribe(response => {
        if (!response['isError']) {
        this.apiResp = response;
        this.formData = JSON.parse(JSON.stringify(this.apiResp.rebatePMData[0]));
        } else {
          this._service.displayToastr(response);
        }
      })
    }
    }
  }

  getDistributorPrice(date: any) {
    let foundPrices: any[] =[];
    if(this.priceData.length) {
      this.priceData.forEach(pd => {
        let currentDate =  new Date(pd.effective_date_of_change);
        let shippedDate = new Date(date);
        if(shippedDate.getTime() > currentDate.getTime()) {
          foundPrices.push(pd);
        }
      });
      if (foundPrices.length) {
        let minDate = foundPrices.reduce((a, b) => new Date(a.effective_date_of_change) > new Date(b.effective_date_of_change) ? a : b)
        this.formData['net_supported_cost'] = minDate['supported_buy_price'];
        this.formData['rebate'] = minDate['rebate'];
        this.formData['list_price_LB'] = minDate['distributor_price_from_basf'];
        this.formData['supported_list_price_LB'] = Number(minDate['supported_buy_price']) + Number(minDate['distributor_price_from_basf']);
      } else {
        this.formData['rebate'] = this.cupRequestData['rebate'];
        this.formData['net_supported_cost'] = this.cupRequestData['net_support_cost'];
        this.formData['supported_list_price_LB'] = this.cupRequestData['supported_list_price_LB'];
        this.formData['list_price_LB'] = this.cupRequestData['list_price_LB'];
      }
      this.doCalculations();
    }
  }

  doCalculations() {
   this.formData.rebate_final = (Number(this.formData.rebate) * Number(this.formData.quantity)).toFixed(2);
  }

  receivePriceData(priceData: any) {
    this.priceData = priceData;
  }

  submitRebate() {
    if (this.checkValidations()) {
      this.requestAPI.created_by = sessionStorage.getItem('userid');
      this.requestAPI.invoice = this.formData.invoice;
      this.requestAPI.quantity = Number(this.formData.quantity);
      this.requestAPI.pm_request_id = this.formData.pm_request_id;
      this.requestAPI.shipped_date = (new Date(this.formData.shipped_date)).toISOString();this.requestAPI.comments = (this.formData.comments !== null) ? this.formData.comments : '';
      this.requestAPI.enter_po = this.formData.enter_po;
      this.requestAPI.rebate_final = this.formData.rebate_final;
      this.requestAPI.rebate_submissions_id = this.formMode === 'Edit' ? this.formData.rebate_submissions_id : 0;
      this.requestAPI.businessunit_id = 3;
      this.requestAPI['credit_invoice'] = "";
      this.requestAPI['value'] = 0;
      this.requestAPI['admincomments'] = "";
      this.requestAPI['rebate_reference_number'] = this.formMode === 'Edit' ? this.formData.rebate_reference_number : "";
      this.requestAPI['rebate'] = this.formData['rebate'];
      this.requestAPI['net_support_cost'] = this.formData['net_supported_cost'];
      this.requestAPI['supported_list_price_LB'] = this.formData['supported_list_price_LB'];
      this.requestAPI.list_price_LB = this.formData['list_price_LB'];
      this.spinner.show();
      this._service.createRebate(this.requestAPI).subscribe(response => {
        if (!response['isError']) {
          this.apiResp = response;
          this.spinner.hide();
          this.router.navigate(['/rebates']);
          this._service.displayToastr(response);
        } else {
          this.spinner.hide();
          this._service.displayToastr(response);
        }
      })
    }
  }

  getFormattedDate(date) {
    return moment(date).format("yyyy-MM-DD");
  }
  
  getFormattedEndDate(date) {
    const dateNow = new Date();
    const iDate = new Date(date);

    if(iDate.getTime() > dateNow.getTime()) {
      const dateYesterday = new Date(dateNow);
      dateYesterday.setDate(dateYesterday.getDate());
      
      return moment(dateYesterday).format("yyyy-MM-DD");
    }
    else {      
      return moment(date).format("yyyy-MM-DD");
    }
  }
  
  checkValidations() {
    if (!this.formData['invoice']) {
      this.toastr.error("Invoice Number is Required");
      return false;
    }
    if (!this.formData['quantity']) {
      this.toastr.error("Quantity Value is Required");
      return false;
    }
    if (!this.formData['shipped_date']) {
      this.toastr.error("Shipped date is Required");
      return false;
    }
    if (!this.formData['enter_po']) {
      this.toastr.error("PO number is Required");
      return false;
    }
    return true;
  }

  cancel() {
    this.router.navigate(['/rebates']);
  }

}
