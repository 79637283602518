import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CupsService } from '../../../../services/cups.service';

@Component({
  selector: 'app-distributor-mapping',
  templateUrl: './distributor-mapping.component.html',
  styleUrls: ['./distributor-mapping.component.css']
})
export class DistributorMappingComponent implements OnInit {

  distributorUserData: any;
  distributorsData: any;
  selectedDistributorData: any;
  form: FormGroup;
  cols = [];
  distributorMappingData = [];
  constructor(private _service: CupsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
    this.form = this.fb.group({
      distributor_user_id: [{ disabled: true, value: "" }, Validators.required],
      distributor_id: [{ disabled: true, value: "" }, Validators.required]
    })
  }

  ngOnInit() {
    this.cols = [
      {
        "header_id": 1,
        "field": "customer",
        "header": "Customer",
        "is_show": true,
        "is_editable": false,
        "serial_no": 1,
        "isHyperlink": false
      },
      {
        "header_id": 2,
        "field": "product",
        "header": "Product",
        "is_show": true,
        "is_editable": false,
        "serial_no": 2,
        "isHyperlink": false,
      },
      {
        "header_id": 3,
        "field": "distributor",
        "header": "Distributor",
        "is_show": true,
        "is_editable": false,
        "serial_no": 3,
        "isHyperlink": false,
      },
      {
        "header_id": 4,
        "field": "division",
        "header": "Division",
        "is_show": true,
        "is_editable": false,
        "serial_no": 4,
        "isHyperlink": false
      },
      {
        "header_id": 5,
        "field": "ship_to",
        "header": "Ship To",
        "is_show": true,
        "is_editable": false,
        "serial_no": 5,
        "isHyperlink": false
      },
      {
        "header_id": 6,
        "field": "sbu",
        "header": "SBU",
        "is_show": true,
        "is_editable": false,
        "serial_no": 6,
        "isHyperlink": false
      },
      {
        "header_id": 7,
        "field": "industry",
        "header": "Industry",
        "is_show": true,
        "is_editable": false,
        "serial_no": 7,
        "isHyperlink": false
      },
      {
        "header_id": 8,
        "field": "volume_cy_lbs",
        "header": "Volume CY (lbs)",
        "is_show": true,
        "is_editable": false,
        "serial_no": 8,
        "isHyperlink": false
      },
      {
        "header_id": 9,
        "field": "volume_py_lbs",
        "header": "Volume PY (lbs)",
        "is_show": true,
        "is_editable": false,
        "serial_no": 9,
        "isHyperlink": false
      },
      {
        "header_id": 10,
        "field": "volume_cy_mts",
        "header": "Volume CY (mts)",
        "is_show": true,
        "is_editable": false,
        "serial_no": 10,
        "isHyperlink": false
      },
      {
        "header_id": 11,
        "field": "volume_py_mts",
        "header": "Volume PY (mts)",
        "is_show": true,
        "is_editable": false,
        "serial_no": 11,
        "isHyperlink": false
      },
      {
        "header_id": 12,
        "field": "volume_cy_kgs",
        "header": "Volume CY (kgs)",
        "is_show": true,
        "is_editable": false,
        "serial_no": 12,
        "isHyperlink": false
      },
      {
        "header_id": 13,
        "field": "volume_py_kgs",
        "header": "Volume PY (kgs)",
        "is_show": true,
        "is_editable": false,
        "serial_no": 13,
        "isHyperlink": false
      },
    ];
    this.getAllDistributorUser();
  }

  getAllDistributorUser() {
    this.form.controls['distributor_user_id'].enable();
    this.distributorUserData = [];
    this._service.getDistributorUsers().subscribe((res: any) => {
      if (res) {
        this.distributorUserData = res.distributorUsers;
      }
    });
  }

  getDistributorMapping() {

  }

  getAllDistributors() {
    this._service.getDistributors().subscribe((res: any) => {
      if (res) {
        this.distributorsData = res.distributors;
      }
    });
  }

  distributorUserChange() {
    if (this.form.value.distributor_user_id != '') {
      this.form.controls['distributor_id'].enable()
    }
    this.distributorsData = []
    this.selectedDistributorData = [];
    this.getAllDistributors();

    this._service.getDistributorsByUser(this.form.get('distributor_user_id').value).subscribe((res: any) => {
      if (res) {
        this.selectedDistributorData = res.distributors;
        this.form.controls['distributor_id'].setValue(this.selectedDistributorData)
      }
    });
  }

  submitData() {
    if (this.form.invalid) {
      return;
    }

    let distributor_user_id = this.form.get('distributor_user_id').value;
    let jsonObj = this.form.get('distributor_id').value;
    if (jsonObj) {
      jsonObj.map(e => {
        e.distributor_User_id = distributor_user_id;
      });
    }
    this._service.saveDistributorMapping(jsonObj).subscribe(res => {
      this.toastr.success("Distributor user mapped successfully");
      this.form.reset();
    });
  }



}
