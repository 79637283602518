import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, UrlTree, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthguardServiceService } from '../services/authguard-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  loginvalue: any;
  expireDate: Date;
  authService: any;
  childactive = false;
  routparams: any;
  constructor(private actroute: ActivatedRoute, private Authguardservice: AuthguardServiceService, public router: Router) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const expectedurl = route.data.expectedurl;
    this.routparams = route.queryParams;

    if (!this.Authguardservice.gettoken()) {
      if (this.routparams != null) {
        sessionStorage.setItem('returnUrlparams', JSON.stringify(this.routparams));
        if (JSON.stringify(this.routparams).includes('RequestId')) {
          sessionStorage.setItem('paramsExist', 'true');
        }
        sessionStorage.setItem('returnUrlpath', JSON.stringify(state.url));
      }
      else {
        sessionStorage.setItem('paramsExist', 'false');
      }
      sessionStorage.setItem('returnUrl', expectedurl);
      this.router.navigate(['/saml'], { queryParams: { returnUrl: expectedurl } });
      return false;
    } else {
      this.loginvalue = JSON.parse(sessionStorage.getItem('loginJWT'));
      if (expectedurl == 'admin') {
        if (JSON.parse(sessionStorage.getItem('IsAdmin')))
          return true;
        else {
          this.router.navigate(['/error']);
          return false;
        }
      }
      else {
        return true;
      }

    }

  }

  canActivateChild(_childRoute: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    return true;
  }
}
