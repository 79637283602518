import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { CupsService } from 'src/services/cups.service';

@Component({
  selector: 'app-user-grid',
  templateUrl: './user-grid.component.html',
  styleUrls: ['./user-grid.component.css'],
  providers: [ConfirmationService]

})
export class UserGridComponent implements OnInit {
  cols: any;
  usersData: any;
  userAPIResp: any;
  filteredFields: any;
  filteredData: any;
  tempFilteredData: any;
  selectedRow: any;
  formType: any;
  showForm = false;
  businessUnits = [
    { id: 1, businessUnit: 'EM'},
    { id: 2, businessUnit: 'EV'},
    { id: 3, businessUnit: 'PM'},
    { id: 4, businessUnit: 'ED'}
  ]
  constructor(private service: CupsService, private spinner: NgxSpinnerService, private toastr: ToastrService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.cols = [
      {
        "header_id": 1,
        "field": "basf_user_id",
        "header": "BASF UserId",
        "is_show": true,
        "is_editable": false,
        "serial_no": 1
      },
      {
        "header_id": 2,
        "field": "first_name",
        "header": "FirstName",
        "is_show": true,
        "is_editable": false,
        "serial_no": 2
      },
      {
        "header_id": 3,
        "field": "last_name",
        "header": "LastName",
        "is_show": true,
        "is_editable": false,
        "serial_no": 3
      },
      {
        "header_id": 4,
        "field": "email",
        "header": "Email",
        "is_show": true,
        "is_editable": false,
        "serial_no": 4
      },
      {
        "header_id": 5,
        "field": "is_admin",
        "header": "Admin",
        "is_show": true,
        "is_editable": false,
        "serial_no": 5
      },
      {
        "header_id": 6,
        "field": "businessUnits",
        "header": "Business Units",
        "is_show": true,
        "is_editable": false,
        "serial_no": 6
      }
    ]
   this.getUsers();
  }

  getUsers() {
    this.spinner.show();
    this.service.getUsers().subscribe(response => {
      this.spinner.hide();
      if (!response['isError']) {
        this.userAPIResp = response;
        this.showForm = false;
        this.usersData = JSON.parse(JSON.stringify(this.userAPIResp.lstUsers.filter(ua => !ua.is_distributor_user && ua.is_active)));
        this.usersData.forEach(u => {
          let fBusinesUnits = this.userAPIResp.lstuserBu.filter(ua => ua.userid === u.user_id && !ua.is_distributor_user);
          if (fBusinesUnits.length > 0) {
            let tBUnit = '';
            fBusinesUnits.forEach(fb => {
              let fbUnit = this.businessUnits.find(bu => bu.id === fb.businessunit_id);
              if (tBUnit == '') {
                tBUnit = fbUnit.businessUnit;
              } else {
                tBUnit = tBUnit + ',' + fbUnit.businessUnit;
              }
            });
            u.businessUnits = tBUnit;
          } else {
            u.businessUnits = '';
          }
        });
        this.usersData = JSON.parse(JSON.stringify(this.usersData.filter(ud =>  ud.businessUnits === sessionStorage.getItem('BU') )));
       } else {
        this.service.displayToastr(response);
      }
    });
  }

  filterData(filterObj) {
    let {value, filter } = filterObj;
    if (value == '') {
      if (this.filteredFields.length) {
        this.filteredFields = this.filteredFields.filter(ff => ff.fieldName !== filter);
      } else {
        this.filteredFields = [];
      }
    } else {
      let foundField = this.filteredFields.filter(ff => ff.fieldName == filter);
      if (foundField.length) {
        this.filteredFields.forEach(ff => {
          if (ff.fieldName == filter) {
            ff.value = value;
          }
        })
      } else {
        let fieldObj = {
          fieldName: filter,
          value: value
        };
        this.filteredFields.push(fieldObj);
      }
    }
    if (this.filteredFields.length) {
      let temp = this.usersData;
      this.filteredFields.forEach(ff => {
        temp = temp.filter(fd => fd[ff.fieldName].toUpperCase().includes(ff.value.toUpperCase()));
      })
      this.filteredData = temp;
    } else {
      this.filteredData = JSON.parse(JSON.stringify(this.usersData));
    }
    this.tempFilteredData = JSON.parse(JSON.stringify(this.filteredData));
  }

  onSelectRow(rowObject) {
    let { rowData, type } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));
    if (type == 'edit') {
      this.formType = 'Edit';
      this.showForm = true;
    } else {
      this.confirmationService.confirm({
        message: 'Please ensure the approval process for mapped price supports has been taken care for the following user. Kindly proceed to continue deletion. ?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.deleteUser(rowData);
        },
        reject: () => {
        }
    });
    }
  }

  addNewUser() {
    this.selectedRow = {
      "user_id": 0,
      "basf_user_id": "",
      "first_name": "",
      "last_name": "",
      "email": "",
      "is_admin": false
    };
    this.formType = "New";
    this.showForm = true;
  }

  refreshUsers(eventvalue) {
    if(eventvalue) {
      this.getUsers();
    } else {
      this.showForm = false;
    }
  }

  deleteUser(rowData) {
    let payLoad = {
      "user_id": rowData.user_id,
      "created_by": sessionStorage.getItem('userid'),
      "is_active": false
    };
    this.spinner.show();
    this.service.deleteUser(payLoad).subscribe(response => {
      this.spinner.hide();
      if (!response['isError']) {
        this.service.displayToastr(response);
        this.getUsers();
      } else {
        this.service.displayToastr(response);
      }
    })
  }
}
