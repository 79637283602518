import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CupsService } from 'src/services/cups.service';
import { FilterUtils } from 'primeng/api';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { SupportRequestGridComponent } from '../support-request-grid/support-request-grid.component';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent {
  cupsRequestData: any;
  cols: any[];
  sortedCols: any[];
  apiResp: any;
  defaultWidth: '100%';
  viewColHeight: any
  selectedCols: any[];
  userId: any;
  userAdmin: boolean = false;
  filteredData: any;
  filteredFields = [];
  tempFilteredData: any;
  title = "Price Support Request";
  gridAPIResp: any;
  APPROVED = '1';
  TO_BE_APPROVED = '2';
  REJECTED_CUPS = '3';
  EXPIRED_CUPS = '4';
  ALL_CUPS = '5';
  EVID = 2;
  EMID = 1;
  PMID = 3;
  EDID = 4;
  selectedBusinessUnit: any;
  selectedRows = [];
  selectedCupsData: any;
  rebatesCols: any;
  rebatesData = [];
  bu = "EM";
  displayDownloadBtn = false;
  massChangeDialog = false;
  selectedMassRows = [];
  uploadedFiles: any[] = [];
  fileName = "";
  fileType = "";
  fileUpload = false;
  filesize = "";
  fileBuffer: any;
  fileObject: any;
  displayUpload = false;
  massCancellationDialog: boolean;
  displayDelegationBtn: boolean = false;
  displayDelgationPopup: boolean = false;
  toApprovers = [];
  fromApprovers = [];
  delegationRequest = {};
  currentDate: any;
  approvers: any;
  selectedToApprover: any;
  selectedFromApprover: any;
  isDistributor: boolean = false;
  paramsData: any;
  routparams: any;
  displayMassChangeBtn: boolean = false;
  issueInMassChange: boolean = false;
  prodData = [];
  isListPricebind: boolean = false;
  uploadType = "";
  displayMassChangeOpt: boolean = false;
  displayMassCancellationOpt: boolean = false;
  fileUploadType = '';
  isSalesRep: boolean = false;
  displayColOrderLabel: boolean = false;
  emMassChangeCols = [
    {
      header: 'CUP Ref#',
      field: 'request_reference_number',
      editField: false
    },
    {
      header: 'Expiration Date',
      field: 'expiration_date',
      editField: false
    },
    {
      header: 'Distributor',
      field: 'distributor_name',
      editField: false
    },
    {
      header: 'Distributor #',
      field: 'distributor_sold_to_name',
      editField: false
    },
    {
      header: 'End User Name',
      field: 'customer_name',
      editField: false
    },
    {
      header: 'Support Type',
      field: 'cups_intostock',
      editField: false
    },
    {
      header: 'End User Ship To',
      field: 'ship_to_name',
      editField: false
    },
    {
      header: 'Product Name',
      field: 'product_name',
      editField: false
    },
    {
      header: 'Product Manager',
      field: 'product_manager_name',
      editField: false
    },
    {
      header: 'Material #',
      field: 'article_number',
      editField: false
    },
    {
      header: 'Estimated Annual Volume',
      field: 'estimated_annual_volume',
      editField: false
    },
    {
      header: 'Annual Vol UOM',
      field: 'estimated_annual_volume_UOM',
      editField: false
    },
    {
      header: 'Bulk/Pkg',
      field: 'bulk_package_type',
      editField: false
    },
    {
      header: 'Freight',
      field: 'freight_terms_to_end_customer',
      editField: false
    },
    {
      header: 'Effective Date of Change',
      field: 'date',
      editField: true
    },
    {
      header: 'Distributor Price from BASF($/KG)',
      field: 'distributor_price_from_basf',
      editField: true
    },
    {
      header: 'Change in Distributor Price from BASF($/KG)',
      field: 'distributor_price_from_basf_MC',
      editField: true
    },
    {
      header: 'Support Price to End Customer($/KG)',
      field: 'support_price_to_end_customer',
      editField: false
    },
    {
      header: 'Change in Support Price to End Customer($/KG)',
      field: 'support_price_to_end_customer_MC',
      editField: true
    },
    {
      header: 'Discount %',
      field: 'discount_percent',
      editField: false
    },
    {
      header: 'Freight Allow',
      field: 'frieght_allowance',
      editField: false
    },
    {
      header: 'Other Allow',
      field: 'other_allowance',
      editField: false
    },
    {
      header: 'Net Support Price',
      field: 'net_support_price',
      editField: false
    },
    {
      header: 'Updated Net Support Price',
      field: 'net_support_price_MC',
      editField: true
    },
    {
      header: 'Credit Back Amount',
      field: 'credit_back_amount',
      editField: false
    },
    {
      header: 'Updated Credit Back Amount',
      field: 'credit_back_amount_MC',
      editField: true
    },
    {
      header: 'Type of Change (For % 1, $ 2)',
      field: 'typeOfChange',
      editField: true
    },
    {
      header: 'Direction of Change (For Increase 1, Decrease 0)',
      field: 'directionOfChange',
      editField: true
    },
    {
      header: 'Change Value for Distributor Price from BASF',
      field: 'dpbValue',
      editField: true
    },
    {
      header: 'Change Value for Support Price to End Customer',
      field: 'specValue',
      editField: true
    },
    {
      header: 'Comments',
      field: 'massChangeComment',
      editField: true
    },
  ]

  massCancellationCols = [
    {
      header: 'REF#',
      field: 'request_reference_number'
    },
    {
      header: 'Expiration Date',
      field: 'expiration_date'
    },
    {
      header: 'Comments',
      field: 'cancel_comments'
    },
    {
      header: 'Distributor',
      field: 'distributor_name'
    },
    {
      header: 'Account',
      field: 'customer_name'
    },
    {
      header: 'Product Name',
      field: 'product_name'
    },
    {
      header: 'Article #',
      field: 'article_number'
    }
  ]
  displayEMRebatesForm: boolean = false;
  getUpdatedRebatesData: boolean = false;
  updatedRebatesData: any;
  updatedRebatesCols: any[];
  priceData: any;
  orgSelectedMassRows: any;
  funcType: any;
  massChangeExisting: boolean = false;
  emPopupHeader: any;
  isSalesSupportDistributor = 0;
  @ViewChild(SupportRequestGridComponent, { static: false }) gridChild: SupportRequestGridComponent | undefined;
  constructor(private _cupsService: CupsService, private router: Router, private spinner: NgxSpinnerService, private toastr: ToastrService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.spinner.show();
    this.getSessionValues();
    this.generateBU_ID();
    if (sessionStorage.getItem('paramsExist') !== "true") {
      this.displayMassChangeBtn = true;
      this.getGridData(this.APPROVED, this.selectedBusinessUnit);
    } else {
      this.paramsData = JSON.parse(sessionStorage.getItem('returnUrlparams'));
      this.loadGridData();
    }
    this.viewColHeight = (window.innerHeight - 190) + "px";
    FilterUtils['custom'] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return parseInt(filter) > value;
    }

    this.isSalesSupportDistributor = Number(sessionStorage.getItem('SalesSupportDistributor'));
  }

  getSessionValues() {
    this.userId = sessionStorage.getItem('userid');
    this.userAdmin = (sessionStorage.getItem('IsAdmin') === "1" || sessionStorage.getItem('IsAdmin') === "true") ? true : false;
    this.isDistributor = (sessionStorage.getItem('isDistributor') === "1" || sessionStorage.getItem('isDistributor') === "true") ? true : false;
    this.isSalesRep = (sessionStorage.getItem('isSalesRep') === "1" || sessionStorage.getItem('isSalesRep') === "true") ? true : false;
  }

  generateBU_ID() {
    this.bu = sessionStorage.getItem('BU');
    switch (this.bu) {
      case 'EM': this.selectedBusinessUnit = this.EMID; this.isSalesRep = (this.isSalesRep == false ? ((sessionStorage.getItem('isSalesSupport') === "1" || sessionStorage.getItem('isSalesSupport') === "true") ? true : false) : this.isSalesRep); break;
      case 'EV': this.selectedBusinessUnit = this.EVID; break;
      case 'PM': this.selectedBusinessUnit = this.PMID; break;
      case 'ED': this.selectedBusinessUnit = this.EDID; this.isSalesRep = (this.isSalesRep == false ? ((sessionStorage.getItem('isSalesSupport') === "1" || sessionStorage.getItem('isSalesSupport') === "true") ? true : false) : this.isSalesRep); break;
      default: this.selectedBusinessUnit = this.EMID; break;
    }
  }

  loadGridData() {

    if (atob(this.paramsData['RequestStatusId']) === "1" || atob(this.paramsData['RequestStatusId']) === "9") {
      this.displayMassChangeBtn = true;
      this.getGridData(this.APPROVED, Number(atob(this.paramsData['BusinessunitId'])));
    } else {
      this.getGridData(this.TO_BE_APPROVED, Number(atob(this.paramsData['BusinessunitId'])));
    }
  }

  onChangeDDValue(_value) {
    this.sortColumns();
  }
  updateUserColumns() {
    this._cupsService.updateUserGridCols(this.selectedCols, this.userId, this.isDistributor).subscribe((response: any) => {
      this._cupsService.displayToastr(response);
    });
    this.displayColOrderLabel = false;
  }

  showHideData(e) {
    this.cols = e;
  }

  sortColumns() {
    this.selectedCols = JSON.parse(JSON.stringify(this.sortSelectedColumns()));
  }

  sortSelectedColumns() {
    return this.selectedCols.sort((a, b) => {
      return a.serial_no - b.serial_no;
    })
  }

  onSelectRow(rowObject) {
    let { rowData, type } = rowObject;
    if (type == 'delete') {
      this._cupsService.deleteSupport(rowData.request_id, rowData.businessunit_id).subscribe(response => {
        this.spinner.hide();
        this._cupsService.displayToastr(response);
        this.router.navigate(['home']);
      })
      return 0;
    }
    if (type == 'ColReorder') {
      this.selectedCols.forEach((selCol: any) => {
        selCol.serial_no = rowObject.colData.find(col => col.header_id == selCol.header_id).serial_no;
      })
      this.displayColOrderLabel = true;
      return 0;
    }
    if (type == 'email') {
      this.spinner.show();
      if (this.selectedBusinessUnit === 3) {
        rowData.request_id = rowData.pm_request_id;
        rowData.businessunit_id = 3;
      }
      this._cupsService.sendEmailReminder(rowData.request_id, rowData.businessunit_id, rowData.request_reference_number, rowData.expiration_date.replaceAll('/', '-'), rowData.status_id).subscribe(response => {
        this.spinner.hide();
        this._cupsService.displayToastr(response);
      })
      return 0;
    }
    if (type == 'confirmationemail') {
      this.spinner.show();
      if (this.selectedBusinessUnit === 3) {
        rowData.request_id = rowData.pm_request_id;
        rowData.businessunit_id = 3;
      }
      this._cupsService.resendSupportConfirmationEmail(rowData.request_id, rowData.businessunit_id, rowData.request_reference_number, rowData.status_id).subscribe(response => {
        this.spinner.hide();
        this._cupsService.displayToastr(response);
      })
      return 0;
    }
    if (type == 'edit' || type == 'clone') {
      let path = (this.selectedBusinessUnit === 3) ? 'pm-cups-request' : 'cups-request';
      this.router.navigate([path], { state: { data: { cols: this.cols, rowData: rowData, type, gridData: this.cupsRequestData } } });
    } else {
      this.router.navigate(['cups-request/info'], { state: { data: { labelData: this.cols, rowData: rowData, type, gridData: this.cupsRequestData } } });
    }
  }

  filterData(filterObj) {
    let { value, filter } = filterObj;
    if (value == '') {
      if (this.filteredFields.length) {
        this.filteredFields = this.filteredFields.filter(ff => ff.fieldName !== filter);
      } else {
        this.filteredFields = [];
      }
    } else {
      let foundField = this.filteredFields.filter(ff => ff.fieldName == filter);
      if (foundField.length) {
        this.filteredFields.forEach(ff => {
          if (ff.fieldName == filter) {
            ff.value = value;
          }
        })
      } else {
        let fieldObj = {
          fieldName: filter,
          value: value
        };
        this.filteredFields.push(fieldObj);
      }
    }
    if (this.filteredFields.length) {
      let temp = this.cupsRequestData;
      this.filteredFields.forEach(ff => {
        temp = temp.filter(fd => fd[ff.fieldName].toUpperCase().includes(ff.value.toUpperCase()));
      })
      this.filteredData = temp;
    } else {
      this.filteredData = JSON.parse(JSON.stringify(this.cupsRequestData));
    }
    this.tempFilteredData = JSON.parse(JSON.stringify(this.filteredData));
  }

  exportExcel() {
    let currentDate = moment().format('MM_DD_YYYY_hh_mm_ss_a');
    let cols;
    if ((this.bu == 'EM' || this.bu == 'ED') && (this.isDistributor || sessionStorage.getItem('SalesSupportDistributor') != '0')) {
      cols = this.cols.filter(x => x.field != 'comments');
    } else {
      cols = this.cols;
    }
    this.exportAsExcelFile(this.filteredData, `All_Requests_${currentDate}`, 'sheet1', cols)
  }

  exportEMTemplate() {
    let filteredEmMassChangeCols = this.emMassChangeCols.filter(em => em.header !== 'Change in Distributor Price from BASF($/KG)');
    filteredEmMassChangeCols = filteredEmMassChangeCols.filter(em => em.header !== 'Change in Support Price to End Customer($/KG)');
    filteredEmMassChangeCols = filteredEmMassChangeCols.filter(em => em.header !== 'Updated Net Support Price');
    filteredEmMassChangeCols = filteredEmMassChangeCols.filter(em => em.header !== 'Updated Credit Back Amount');
    filteredEmMassChangeCols = filteredEmMassChangeCols.filter(em => em.header !== 'Type of Change (For % 1, $ 2)');
    filteredEmMassChangeCols = filteredEmMassChangeCols.filter(em => em.header !== 'Direction of Change (For Increase 1, Decrease 0)');
    filteredEmMassChangeCols = filteredEmMassChangeCols.filter(em => em.header !== 'Change Value for Distributor Price from BASF');
    filteredEmMassChangeCols = filteredEmMassChangeCols.filter(em => em.header !== 'Change Value for Support Price to End Customer');
    filteredEmMassChangeCols = filteredEmMassChangeCols.filter(em => em.header !== 'Comments');

    let selectedMassRows = JSON.parse(JSON.stringify(this.selectedMassRows));
    selectedMassRows.forEach(smr => {
      smr.dpbValue = 0;
      smr.specValue = 0;
    });
    this.updatedRebatesCols = JSON.parse(JSON.stringify(filteredEmMassChangeCols));
    this.updatedRebatesCols.forEach(urc => {
      urc.is_show = true;
    })
    this.updatedRebatesData = JSON.parse(JSON.stringify(selectedMassRows));
    this.funcType = "Mass Template";
    this.emPopupHeader = "Enter the Effective Date of Change";
    this.displayEMRebatesForm = true;
  }

  generateExcelForMassCancellation() {
    let currentDate = moment().format('MM_DD_YYYY_hh_mm_ss_a');
    if (this.selectedBusinessUnit === 3) {
      this.massCancellationCols.forEach(colInfo => {
        if (colInfo.header === 'Account') {
          colInfo.field = 'account';
        }
      });
    }
    if (this.selectedRows.length === 0) {
      this.selectedRows = [
        { 'request_reference_number': '', 'expiration_date': '', 'cancel_comments': '', 'distributor_name': '', 'customer_name': '', 'product_name': '', 'article_number': '' }
      ];
    } else {
      this.selectedRows.forEach(sr => {
        sr.cancel_comments = '';
      })
    }
    this.exportAsExcelFile(this.selectedRows, `MASS_CANCELLATION_${currentDate}`, 'sheet1', this.massCancellationCols);
    this.displayMassCancellationOpt = false;
  }

  exportAsExcelFile(json: any[], excelFileName: string, sheetName: string, columnValues: any[]): void {
    let fieldValues: any[] = columnValues.map(field => field.field);
    let headerValues: string[] = columnValues.map(header => header.header);
    let displayedJson: any[] = [];
    if (json != null && json.length > 0) {
      for (let originalValue of json) {
        let jsonObject: any = {};
        for (let fieldValue of fieldValues) {
          jsonObject[fieldValue] = originalValue[fieldValue];
        }
        let jsonString = JSON.stringify(jsonObject);
        displayedJson.push(JSON.parse(jsonString));
      }
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(displayedJson);
      let range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        let address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
        if (!worksheet[address]) continue;
        worksheet[address] = {
          v: headerValues[C],
          t: 's',
          s: {
            alignment: { textRotation: 90 },
            font: { bold: true }
          }
        };
      }
      XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: -1 });
      const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }

  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  navigateToCupsRequest(businessName) {
    this.router.navigate(['cups-request'], { state: { data: { cols: this.cols, type: 'New', businessName } } })
  }

  navigateToPMCupsRequest() {
    this.router.navigate(['pm-cups-request'], { state: { data: { cols: this.cols, type: 'New' } } })
  }

  onTabChange(event) {
    switch (event.index) {
      case 0: this.getGridData(this.APPROVED, this.selectedBusinessUnit); this.displayDelegationBtn = false; this.displayMassChangeBtn = true; break;
      case 1: this.getGridData(this.TO_BE_APPROVED, this.selectedBusinessUnit); this.displayDelegationBtn = true; this.displayMassChangeBtn = false; break;
      case 2: this.getGridData(this.REJECTED_CUPS, this.selectedBusinessUnit); this.displayDelegationBtn = false; this.displayMassChangeBtn = false; break;
      case 3: this.getGridData(this.EXPIRED_CUPS, this.selectedBusinessUnit); this.displayDelegationBtn = false; this.displayMassChangeBtn = false; break;
      case 4: this.getAllGridData(this.selectedBusinessUnit); this.displayDelegationBtn = false; this.displayMassChangeBtn = false; break;
    }
  }
  getAllGridData(formName) {
    this.spinner.show();
    this._cupsService.getAllGridData(formName, this.userId, this.isDistributor).subscribe(resp => {
      if (!resp['isError']) {
        this.selectedRows = [];
        this.selectedBusinessUnit = formName;
        this.gridAPIResp = resp;
        this.cols = this.gridAPIResp.headerColumns;
        this.cols = this.cols.filter(col => col.is_grid_display);
        if (this.bu == 'EM' || this.bu == 'ED') {
          this.hideCols();
        }
        this.selectedCols = JSON.parse(JSON.stringify(this.cols.filter(col => col.is_show && col.is_user_visible)));
        if (this.isDistributor || Number(sessionStorage.getItem('SalesSupportDistributor')) > 0) {
          this.selectedCols = this.selectedCols.filter(col => col.header !== 'Internal Comments');
        }
        this.sortedCols = JSON.parse(JSON.stringify(this.cols.filter(x => x.is_show)));
        this.sortedCols.sort((a, b) => {
          return a.header > b.header ? 1 : -1;
        });
        if (formName === this.EVID || formName === this.EMID || formName === this.EDID) {
          this.navigateToEMEVEDCupReview();
        }
        if (formName === this.PMID) {
          this.navigateToPMCupReview();
        }

        if (this.bu == 'EM' || this.bu == 'ED') {
          this.cupsRequestData.forEach(cup => {
            if (cup.cups_intostock !== 'CUP') {
              cup.distributor_price_from_basf = '';
            }
          })
        }
        this.filteredData = JSON.parse(JSON.stringify(this.cupsRequestData));
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this._cupsService.displayToastr(resp);
      }
    });
  }

  hideCols() {
    this.cols.forEach(item => {
      if ((item.field == 'freight_terms_to_end_customer' || item.field == 'zip_code' || item.field == 'distributor' || item.field == 'industry_market') || (this.isDistributor && item.field == 'comments')) {
        item.is_show = false;
      }
    });
  }

  navigateViewByBusinessUnit(formName) {
    if (formName === this.EVID || formName === this.EMID || formName === this.EDID) {
      this.navigateToEMEVEDCupReview();
    }
    if (formName === this.PMID) {
      this.navigateToPMCupReview();
    }
  }

  getGridData(requestStatus, formName) {
    this.spinner.show();
    this._cupsService.getGridData(requestStatus, formName, this.userId, this.isDistributor, sessionStorage.getItem('SalesSupportDistributor')).subscribe(response => {
      if (!response['isError']) {
        this.selectedRows = [];
        this.selectedBusinessUnit = formName;
        this.gridAPIResp = response;
        this.cols = this.gridAPIResp.headerColumns;
        this.cols = this.cols.filter(col => col.is_grid_display);


        if (!(requestStatus === "2" && !this.isDistributor && formName === 3)) {
          this.cols = this.cols.filter(col => col.field !== 'approval_pending_with');
        }

        if (this.bu == 'EM' || this.bu == 'ED') {
          this.hideCols();
        }

        this.selectedCols = JSON.parse(JSON.stringify(this.cols.filter(col => col.is_show && col.is_user_visible)));
        if (this.isDistributor || Number(sessionStorage.getItem('SalesSupportDistributor')) > 0) {
          this.selectedCols = this.selectedCols.filter(col => col.header !== 'Internal Comments');
        }
        this.sortedCols = JSON.parse(JSON.stringify(this.cols.filter(x => x.is_show)));
        this.sortedCols.sort((a, b) => {
          return a.header > b.header ? 1 : -1;
        });
        this.navigateViewByBusinessUnit(formName);
        if (requestStatus === '1') {
          this.setExpireRemainder();
        }
        if (this.bu == 'EM' || this.bu == 'ED') {
          this.cupsRequestData.forEach(cup => {
            if (cup.cups_intostock !== 'CUP') {
              cup.distributor_price_from_basf = '';
            }
          })
        }
        this.filteredData = JSON.parse(JSON.stringify(this.cupsRequestData));
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this._cupsService.displayToastr(response);
      }
    })
  }

  navigateToEMEVEDCupReview() {
    this.cupsRequestData = this.gridAPIResp.requestSubmissionData;
    if (sessionStorage.getItem('paramsExist') === 'true') {
      let rowObject = {
        type: (atob(this.paramsData['RequestStatusId']) !== "11") ? 'view' : 'edit',
        rowData: this.cupsRequestData.find(cup => cup.request_id === Number(atob(this.paramsData['RequestId'])))
      }
      if (rowObject.rowData && rowObject.rowData['sbu_name'] === '') {
        rowObject.type = 'edit';
      }
      this.onSelectRow(rowObject);
    }
  }

  navigateToPMCupReview() {
    this.cupsRequestData = this.gridAPIResp.pmRequestSubmissionData;
    if (sessionStorage.getItem('paramsExist') === 'true') {
      let rowObject = {
        type: 'view',
        rowData: this.cupsRequestData.find(cup => cup.pm_request_id === Number(atob(this.paramsData['RequestId'])))
      }
      this.onSelectRow(rowObject);
    }
  }

  setExpireRemainder() {
    this.cupsRequestData.forEach(cup => {
      if (this.getDaysDiff(cup.expiration_date, new Date()) <= 15) {
        cup.isExpireReminder = true;
      } else {
        cup.isExpireReminder = false;
      }
    })
  }

  receiveSelectedRows(event) {
    this.selectedRows = event;
  }

  navigateToRebate() {
    if (this.selectedRows.length === 1 && (this.selectedBusinessUnit === 1 || this.selectedBusinessUnit === 2 || this.selectedBusinessUnit === 4)) {
      this.navigateToEMRebates();
    }
    else if (this.selectedRows.length === 1 && this.selectedBusinessUnit === 3) {
      this.navigateToPMRebates();
    }
    else {
      this.toastr.warning("Select a single Approved CUP record to Rebate", "Warning!");
    }
  }

  navigateToPMRebates() {
    if (this.selectedRows[0].status_id === 9 && moment().isAfter(this.selectedRows[0].effective_date)) {
      this.router.navigate(['rebates/pm-rebate-form'], { state: { data: { rowData: this.selectedRows[0] } } });
    } else {
      this.toastr.warning("Select a single Activated CUP record which effective date is older than today's date to create a Rebate", "Warning!");
    }
  }

  navigateToEMRebates() {
    if (this.selectedRows[0].status_id === 1 && this.selectedRows[0].cups_intostock_id === 5 && moment().isAfter(this.selectedRows[0].effective_date)) {
      this.router.navigate(['rebates/evem-rebate-form'], { state: { data: { rowData: this.selectedRows[0] } } })
    } else {
      this.toastr.warning("Select request record which is Approved CUP request and effective date is older than today's date to Rebate", "Warning!");
    }
  }

  getFormattedData(date) {
    return moment(date).format("yyyy-MM-DD");
  }

  generateToApprovers() {
    if (this.selectedFromApprover) {
      this.toApprovers = this.approvers.filter(a => a.userid !== this.selectedFromApprover['userid']);
    }
  }

  generateFromApprovers() {
    if (this.selectedToApprover) {
      this.fromApprovers = this.approvers.filter(a => a.userid !== this.selectedToApprover['userid']);
    }
  }

  openDelegationPopup() {
    this.currentDate = new Date();
    this.spinner.show();
    this._cupsService.getApprovers(this.selectedBusinessUnit).subscribe(response => {
      if (!response['isError']) {
        this.approvers = response['response'];
        this.toApprovers = this.approvers;
        this.fromApprovers = this.approvers;
        if (!this.userAdmin) {
          this.selectedFromApprover = this.approvers.find(a => a.userid == this.userId);
          this.fromApprovers = this.approvers.filter(a => a.userid != this.userId);
        }
        this.spinner.hide();
        this.displayDelgationPopup = true;
      } else {
        this.spinner.hide();
        this._cupsService.displayToastr(response);
      }
    });
  }

  cancelDelegationPopup() {
    this.displayDelgationPopup = false;
  }

  submitDelegation() {
    this.spinner.show();
    this.delegationRequest['fromApproverUserId'] = this.selectedFromApprover['userid'];
    this.delegationRequest['toApproverUserId'] = this.selectedToApprover['userid'];
    this.delegationRequest['currentUserId'] = Number(this.userId);
    this.delegationRequest['businessUnit'] = this.selectedBusinessUnit;
    this.delegationRequest['toDate'] = moment(this.delegationRequest['toDate']).format("MM/DD/yyyy");
    this.delegationRequest['fromDate'] = moment(this.delegationRequest['fromDate']).format("MM/DD/yyyy");
    this._cupsService.submitDelegationRequest(this.delegationRequest).subscribe(response => {
      if (!response['isError']) {
        this.spinner.hide();
        this.displayDelgationPopup = false;
        this._cupsService.displayToastr(response);
      } else {
        this.spinner.hide();
        this._cupsService.displayToastr(response);
      }
    });
  }

  downloadTempalte() {
    if ((this.selectedBusinessUnit === 1 || this.selectedBusinessUnit === 2 || this.selectedBusinessUnit === 4) && !this.checkForCupsData()) {
      this.getTemplate();
    }
    if (((this.selectedBusinessUnit === 1 || this.selectedBusinessUnit === 2 || this.selectedBusinessUnit === 4) && this.checkForCupsData())) {
      this.toastr.warning("Select Approved CUP records which effective date is older than today's date to Generate Rebate Template", "Warning!");
    }
    if (this.selectedBusinessUnit === 3 && !this.checkForPMCupsData()) {
      this.getTemplate();
    }
    if (this.selectedBusinessUnit === 3 && this.checkForPMCupsData()) {
      this.toastr.warning("Select a Activated CUP records which effective date is older than today's date to Generate Rebate Template", "Warning!");
    }
  }

  getTemplate() {
    this.rebatesData = [];
    this.rebatesCols = [];
    this.getRebatesColsData(this.selectedBusinessUnit);
  }

  getRebatesColsData(businessUnit) {
    this.spinner.show();
    this._cupsService.getRebateGridData(businessUnit, 3, this.isSalesSupportDistributor).subscribe(response => {
      this.spinner.hide();
      if (!response['isError']) {
        this.gridAPIResp = response;
        this.rebatesCols = this.gridAPIResp.headerColumns;
        this.rebatesCols = this.rebatesCols.filter(rc => rc.is_grid_display);
        this.rebatesCols = this.rebatesCols.filter(rc => rc.field !== 'total_creditdue');
        this.rebatesCols = this.rebatesCols.filter(rc => rc.field !== 'rebate_reference_number');
        this.rebatesCols = this.rebatesCols.filter(rc => rc.field !== 'created_date');
        this.rebatesCols = this.rebatesCols.filter(rc => rc.field !== 'submission_date');
        this.rebatesCols = this.rebatesCols.filter(rc => rc.field !== 'rebate_final');
        this.rebatesCols = this.rebatesCols.filter(rc => rc.header !== 'Rebate Status');
        this.rebatesData = [];
        if (businessUnit === 1 || businessUnit === 2 || businessUnit === 4) {
          this.selectedRows.forEach((cr, index) => {
            this.getRebatesData(this.selectedBusinessUnit, cr.request_id, index);
          });
        }
        if (businessUnit === 3) {
          this.selectedRows.forEach((cr, index) => {
            this.getRebatesData(this.selectedBusinessUnit, cr.pm_request_id, index);
          });
        }
      } else {
        this._cupsService.displayToastr(response);
      }
    })
  }

  checkForCupsData() {
    let fCups = this.selectedRows.filter(r => r.cups_intostock_id !== 5 || moment().isBefore(r.effective_date));
    return fCups.length;
  }

  checkForPMCupsData() {
    let fCups = this.selectedRows.filter(r => r.status_id !== 9 || moment().isBefore(r.effective_date));
    return fCups.length;
  }

  getRebatesData(businessUnit, cupRequestId, _index) {
    let rebateData;
    this.spinner.show();
    this._cupsService.getRebateFormData(cupRequestId, businessUnit).subscribe(response => {
      this.spinner.hide();
      if (!response['isError']) {
        this.apiResp = response;
        if (businessUnit === 1 || businessUnit === 2 || businessUnit === 4) {
          rebateData = JSON.parse(JSON.stringify(this.apiResp.rebateEvemData[0]));
        }
        if (businessUnit === 3) {
          rebateData = JSON.parse(JSON.stringify(this.apiResp.rebatePMData[0]));
        }
        this.rebatesData.push(rebateData);
        if (this.selectedRows.length === this.rebatesData.length) {
          this.displayDownloadBtn = (businessUnit === 1 || businessUnit === 4) ? this.openRebateShippedDateForm() : true;
        }
      } else {
        this._cupsService.displayToastr(response);
      }
    })
  }

  openRebateShippedDateForm() {
    let tempUpdatedRebatesCols = JSON.parse(JSON.stringify(this.rebatesCols));
    tempUpdatedRebatesCols = tempUpdatedRebatesCols.filter(urc => urc.header != 'Dist. Invoice #');
    tempUpdatedRebatesCols = tempUpdatedRebatesCols.filter(urc => urc.header != 'Quantity Shipped');
    tempUpdatedRebatesCols = tempUpdatedRebatesCols.filter(urc => urc.header != 'Comments');
    tempUpdatedRebatesCols = tempUpdatedRebatesCols.filter(urc => urc.header != 'Rebate Status');
    this.updatedRebatesCols = JSON.parse(JSON.stringify(tempUpdatedRebatesCols));
    this.updatedRebatesData = JSON.parse(JSON.stringify(this.rebatesData));
    this.funcType = "Rebate Template";
    this.emPopupHeader = "Enter the User Ship. DT";
    this.displayEMRebatesForm = true;
    return false;
  }

  openMassChangeDateForm() {
    this.displayEMRebatesForm = true;
  }

  receiveUpdatedRebates(rebates) {
    if (this.funcType === 'Rebate Template') {
      this.rebatesData = JSON.parse(JSON.stringify(rebates));
      this.rebatesData.forEach(rd => {
        if (rd.shipped_date != null && rd.shipped_date != '' && rd.shipped_date != 'Invalid date') {
          rd.shipped_date = moment(rd.shipped_date).format('MM/DD/YYYY');
        }
      })
    } else {
      this.receiveUpdatedMassRows(rebates);
    }
  }

  receiveUpdatedMassRows(rebates) {
    this.rebatesData = JSON.parse(JSON.stringify(rebates));
    this.rebatesData.forEach(rd => {
      if (rd.date != null && rd.date != '' && rd.date != 'Invalid date') {
        rd.date = moment(rd.date).format('MM/DD/YYYY');
      }
    })
  }

  checkForValidShippedDate() {
    if (this.funcType === 'Rebate Template') {
      let foundNotValidShipedData = false;
      this.rebatesData.forEach(rd => {
        if (!(rd.shipped_date != null && rd.shipped_date != '' && rd.shipped_date != 'Invalid date')) {
          foundNotValidShipedData = true;
          this.toastr.warning(`For ${rd.request_reference_number} shipped date not provided`, 'Warning');
        }
      });
      if (!foundNotValidShipedData) {
        this.rebatesExportExcel();
      }
    } else {
      this.checkForValidEffectiveDate();
    }
  }

  checkForValidEffectiveDate() {
    let foundNotValidEffectiveDate = false;
    this.rebatesData.forEach(rd => {
      if (!(rd.date != null && rd.date != '' && rd.date != 'Invalid date')) {
        foundNotValidEffectiveDate = true;
        this.toastr.warning(`For ${rd.request_reference_number}change of effective date not provided`, 'Warning');
      }
    });
    if (!foundNotValidEffectiveDate) {
      this.massRowsExportExcel();
    }
  }

  rebatesExportExcel() {
    let currentDate = moment().format('MM_DD_YYYY_hh_mm_ss_a');
    this.exportAsExcelFile(this.rebatesData, `Rebates Template_${currentDate}`, 'sheet1', this.rebatesCols);
    this.displayDownloadBtn = false;
    this.displayEMRebatesForm = false;
  }

  massRowsExportExcel() {
    let filteredEmMassChangeCols = this.emMassChangeCols.filter(em => em.header !== 'Change in Distributor Price from BASF($/KG)');
    filteredEmMassChangeCols = filteredEmMassChangeCols.filter(em => em.header !== 'Change in Support Price to End Customer($/KG)');
    filteredEmMassChangeCols = filteredEmMassChangeCols.filter(em => em.header !== 'Updated Net Support Price');
    filteredEmMassChangeCols = filteredEmMassChangeCols.filter(em => em.header !== 'Updated Credit Back Amount');
    let currentDate = moment().format('MM_DD_YYYY_hh_mm_ss_a');
    let selectedMassRows = JSON.parse(JSON.stringify(this.rebatesData));
    selectedMassRows.forEach(smr => {
      smr.dpbValue = 0;
      smr.specValue = 0;
    });
    let title;
    if (this.selectedBusinessUnit == 1) {
      title = `EM_MASS_CHANGE_${currentDate}`;
    } else if (this.selectedBusinessUnit == 4) {
      title = `ED_MASS_CHANGE_${currentDate}`;
    }
    this.exportAsExcelFile(selectedMassRows, title, 'sheet1', filteredEmMassChangeCols);
    this.displayDownloadBtn = false;
    this.displayEMRebatesForm = false;
  }

  massChange() {
    this.selectedMassRows = JSON.parse(JSON.stringify(this.selectedRows));
    this.selectedMassRows.forEach(sm => {
      sm.typeOfChange = "";
      sm.dpbValue = "";
      sm.specValue = "";
      sm.date = moment(new Date()).format('L');
      sm.directionOfChange = "";
      sm.massChangeComment = "";
      sm.issueInMassChange = false;
      switch (this.selectedBusinessUnit) {
        case 1: sm.cupReqId = sm.request_id;
          sm.distributor_price_from_basf_MC = sm.distributor_price_from_basf;
          sm.support_price_to_end_customer_MC = sm.support_price_to_end_customer;
          sm.net_support_price_MC = sm.net_support_price
          sm.credit_back_amount_MC = sm.credit_back_amount;
          sm.isValidCreditAmountMC = true;
          break;
        case 2: sm.cupReqId = sm.request_id;
          sm.support_price_to_end_customer_MC = sm.support_price_to_end_customer;
          sm.net_support_price_MC = sm.net_support_price;
          sm.credit_back_amount_MC = sm.credit_back_amount;
          sm.scopeOfChange = "";
          sm.isValidCreditAmountMC = true;
          break;
        case 3: sm.cupReqId = sm.pm_request_id;
          sm.suggested_selling_price_MC = sm.suggested_selling_price;
          sm.supported_buy_price_MC = sm.supported_buy_price;
          sm.rebate_MC = sm.rebate;
          sm.isValidRebateAmount = true;
          break;
        case 4: sm.cupReqId = sm.request_id;
          sm.distributor_price_from_basf_MC = sm.distributor_price_from_basf;
          sm.support_price_to_end_customer_MC = sm.support_price_to_end_customer;
          sm.net_support_price_MC = sm.net_support_price
          sm.credit_back_amount_MC = sm.credit_back_amount;
          sm.isValidCreditAmountMC = true;
          this.selectedBusinessUnit = 4
          break;
        default: break;
      }
    });
    this.orgSelectedMassRows = JSON.parse(JSON.stringify(this.selectedMassRows));
    if (this.selectedBusinessUnit === 2 || this.selectedBusinessUnit === 3) {
      if (this.selectedRows.length === 0) {
        this.toastr.error('Select any request from grid', 'Error!');
      } else {
        this.massChangeDialog = true;
      }
    } else {
      this.displayMassChangeOpt = true;
      this.fileType = 'Mass Change';
    }
  }

  generateExcelForEM() {
    if (this.selectedRows.length === 0) {
      this.toastr.error('Select any request from grid', 'Error!');
    } else {
      this.exportEMTemplate();
      this.displayMassChangeOpt = false;
    }
  }

  quickMassChange() {
    if (this.selectedRows.length === 0) {
      this.toastr.error('Select any request from grid', 'Error!');
    } else {
      this.displayMassChangeOpt = false;
      this.massChangeDialog = true;
    }
  }

  closeMassChangeUpload() {
    this.massChangeDialog = false;
    this.displayMassChangeOpt = false;
    this.displayMassCancellationOpt = false;
  }



  getProductPricingDetails(rowData: any) {
    if (rowData.cups_intostock && rowData.frieght_terms_to_distributor && rowData.article_number && rowData.date) {
      this.spinner.show();
      if (rowData.cups_intostock === '3rd Party' && !rowData.ship_to) {
        this.spinner.hide();
        return false;
      }
      let shiptoId = rowData.cups_intostock === '3rd Party' ? rowData.ship_to : 0;
      this.productPricingApiCall(rowData, shiptoId);
    } else {
      this.spinner.hide();
    }

  }

  productPricingApiCall(rowData, shiptoId) {
    this._cupsService.getProductPricingRecord(this.selectedBusinessUnit, rowData.article_number,
      rowData.distributor_sold_to_id, shiptoId, rowData.frieght_terms_to_distributor_id,
      rowData.date, 'P', rowData.cups_intostock_id).subscribe(response => {
        if (!response['isError']) {
          this.spinner.hide();
          this.apiResp = response;
          this.selectedMassRows.forEach(sm => {
            if (sm.request_reference_number === rowData.request_reference_number) {
              sm.distributor_price_from_basf = this.apiResp.response.list_Price;
              this.calculateEMEVEDPrices(rowData);
            }
          });
        }
        else {
          this.spinner.hide();
          this.selectedMassRows.forEach(sm => {
            if (sm.request_reference_number === rowData.request_reference_number) {
              sm.distributor_price_from_basf = rowData.distributor_price_from_basf;
            }
          });
          this._cupsService.displayToastr(response);
        }
      });
  }

  calculateEMEVEDPrices(rowData) {
    const sp = Number(rowData['support_price_to_end_customer_MC']);
    const discount = Number(rowData['discount_percent'].replace('%', ''));
    const fa = rowData['frieght_allowance'] ? Number(rowData['frieght_allowance']) : 0;
    const oa = rowData['other_allowance'] ? Number(rowData['other_allowance']) : 0;
    const dp = Number(rowData['distributor_price_from_basf']);
    const dp_mc = Number(rowData['distributor_price_from_basf_MC']);
    let updatedRowData: any;
    if (rowData.typeOfChange != '' && rowData.directionOfChange != '') {
      updatedRowData = this.configRowDataForBothChanges(rowData, sp, discount, fa, oa, dp_mc, dp);
    }
    else {
      updatedRowData = this.configRowDataForSingleChange(rowData, dp);
    }
    this.updateAmountsAndValidate(updatedRowData);
  }

  configRowDataForBothChanges(rowData: any, sp: any, discount: any, fa: any, oa: any, dp_mc: any, dp: any) {
    if ((this.selectedBusinessUnit === 1 ) && sp && discount && fa >= 0 && oa >= 0 && dp_mc) {
      rowData['net_support_price_MC'] = (sp - (sp * (discount) / 100) - fa - oa).toFixed(2).toString();
      rowData['credit_back_amount_MC'] = (dp_mc - Number(rowData['net_support_price_MC'])).toFixed(2).toString();
    }
    if ((this.selectedBusinessUnit === 4) && sp && discount && fa >= 0 && oa >= 0 && dp_mc) {
      rowData['net_support_price_MC'] = (sp - fa - oa).toFixed(2).toString();
      rowData['credit_back_amount_MC'] = (dp_mc - sp).toFixed(2).toString();
      //rowData['credit_back_amount_MC'] = (dp_mc - Number(rowData['net_support_price_MC'])).toFixed(2).toString();
    }
    const nsp = Number(rowData['net_support_price_MC']);
    if (this.selectedBusinessUnit === 2 && nsp && dp && (rowData.cups_intostock === '3rd Party' || rowData.cups_intostock === 'IntoStock')) {
      rowData['credit_back_amount_MC'] = 0;
    } else if (this.selectedBusinessUnit === 2 && nsp && dp && rowData.cups_intostock === 'CUP') {
      rowData['credit_back_amount_MC'] = (dp - Number(rowData['net_support_price_MC'])).toFixed(2).toString();
    }
    return rowData;
  }

  configRowDataForSingleChange(rowData: any, dp: any) {
    const nsp = Number(rowData['net_support_price_MC']);
    if (this.selectedBusinessUnit === 2 && nsp && dp && (rowData.cups_intostock === '3rd Party' || rowData.cups_intostock === 'IntoStock')) {
      rowData['credit_back_amount_MC'] = 0;
    } else if (this.selectedBusinessUnit === 2 && nsp && dp && rowData.cups_intostock === 'CUP') {
      rowData['credit_back_amount_MC'] = (dp - Number(rowData['net_support_price_MC'])).toFixed(2).toString();
    }
    return rowData;
  }

  updateAmountsAndValidate(rowData: any) {
    this.selectedMassRows.forEach(sm => {
      if (sm.request_reference_number === rowData.request_reference_number) {
        sm.net_support_price_MC = rowData['net_support_price_MC'];
        sm.credit_back_amount_MC = rowData['credit_back_amount_MC'];
        sm.isValidCreditAmountMC = this.validateNumber(sm.credit_back_amount_MC, sm);
        if ((this.bu == 'EM' || this.bu == 'ED') && sm.cups_intostock != 'CUP') {
          sm.isValidCreditAmountMC = true;
        }
        if (!sm.isValidCreditAmountMC) {
          this.toastr.error('Values cannot be negative', 'Error!');
        }
      }
    });
  }

  doPMCalculationsForPM(rowData) {
    if (rowData.typeOfChange != '' && rowData.directionOfChange != '' && rowData.discount) {
      const SP = Number(rowData['suggested_selling_price_MC']);
      const SM = Number(rowData['selling_margin']);
      if (SP && SM && rowData.sell_price_UOM) {
        rowData['supported_buy_price_MC'] = (SP - (SP * SM / 100)).toFixed(2).toString();
        if (rowData.sell_price_UOM === 'LB') {
          rowData['rebate_MC'] = (Number(rowData['list_price_lb']) - Number(rowData['supported_buy_price_MC'])).toFixed(2).toString();
        } else {
          rowData['rebate_MC'] = (Number(rowData['list_price_kg']) - Number(rowData['supported_buy_price_MC'])).toFixed(2).toString();
        }
      }
      this.checkForPMMassChangeValidations(rowData);
    }
  }

  checkForPMMassChangeValidations(rowData) {
    this.selectedMassRows.forEach(sm => {
      if (sm.request_reference_number === rowData.request_reference_number) {
        sm.supported_buy_price_MC = rowData['supported_buy_price_MC'];
        sm.rebate_MC = rowData['rebate_MC'];
        sm.isValidRebateAmount = this.validateNumber(sm.rebate_MC, sm);
        if (!sm.isValidRebateAmount) {
          this.toastr.error('Values cannot be negative', 'Error!');
        }
      }
    });
  }

  validateNumber(value, rowData) {
    this.selectedMassRows.forEach(sm => {
      if (sm.request_reference_number === rowData.request_reference_number) {
        sm.issueInMassChange = Number(value) > 0;
      }
    });
    return Number(value) > 0;
  }

  submitMassChange() {
    let massChangeRows = [];
    let fIssues = this.selectedMassRows.find(smr => !smr.issueInMassChange);
    if (fIssues) {
      this.toastr.error('Negative values not allowed', 'Error!');
      return false;
    }
    this.selectedMassRows.forEach(sm => {
      massChangeRows.push(this.constructApiRequest(sm));
    });
    this.spinner.show();
    this._cupsService.submitMassChange(massChangeRows, sessionStorage.getItem('BU')).subscribe(response => {
      if (!response['isError']) {
        this._cupsService.displayToastr(response);
        this.spinner.hide();
        this.massChangeDialog = false;
        this.selectedMassRows = [];
        this.getGridData(this.APPROVED, this.selectedBusinessUnit);
      } else {
        this.spinner.hide();
        this._cupsService.displayToastr(response);
      }
    })
  }

  constructApiRequest(sm: any) {
    switch (this.selectedBusinessUnit) {
      case 1: return {
        "cupReqId": sm.cupReqId,
        "date": moment(new Date()).format('L'),
        "distributor_price_from_basf": sm.distributor_price_from_basf_MC,
        "support_price_to_end_customer": sm.support_price_to_end_customer_MC,
        "suggested_selling_price": 0,
        "net_support_price": 0,
        "typeOfChange": Number(sm.typeOfChange),
        "discount": Number(sm.discount),
        "discountdistributorprice": (sm.dpbValue !== '') ? Number(sm.dpbValue) : 0,
        "discountnetsupportprice": (sm.specValue !== '') ? Number(sm.specValue) : 0,
        "directionOfChange": Number(sm.directionOfChange),
        "updatedBy": this.userId,
        "comments": sm.massChangeComment,
        "scopeOfChange": 0,
        "EffectiveDate": sm.date
      };
      case 2: return {
        "cupReqId": sm.cupReqId,
        "date": moment(new Date()).format('L'),
        "distributor_price_from_basf": sm.distributor_price_from_basf,
        "support_price_to_end_customer": sm.support_price_to_end_customer_MC,
        "suggested_selling_price": 0,
        "net_support_price": sm.net_support_price_MC,
        "typeOfChange": Number(sm.typeOfChange),
        "discount": Number(sm.discount),
        "discountdistributorprice": 0,
        "discountnetsupportprice": 0,
        "directionOfChange": Number(sm.directionOfChange),
        "updatedBy": this.userId,
        "comments": sm.massChangeComment,
        "scopeOfChange": Number(sm.scopeOfChange),
        "EffectiveDate": sm.date,
        "isInternalUser" : !this.isDistributor
      };
      case 3: return {
        "cupReqId": sm.cupReqId,
        "date": moment(new Date()).format('L'),
        "distributor_price_from_basf": 0,
        "support_price_to_end_customer": 0,
        "suggested_selling_price": sm.suggested_selling_price_MC,
        "net_support_price": 0,
        "typeOfChange": Number(sm.typeOfChange),
        "discount": Number(sm.discount),
        "discountdistributorprice": 0,
        "discountnetsupportprice": 0,
        "directionOfChange": Number(sm.directionOfChange),
        "updatedBy": this.userId,
        "comments": sm.massChangeComment,
        "scopeOfChange": 0,
        "EffectiveDate": sm.date,
        "rebate": sm.rebate_MC,
      };
      case 4: this.selectedBusinessUnit = 4;
        return {
          "cupReqId": sm.cupReqId,
          "date": moment(new Date()).format('L'),
          "distributor_price_from_basf": sm.distributor_price_from_basf_MC,
          "support_price_to_end_customer": sm.support_price_to_end_customer_MC,
          "suggested_selling_price": 0,
          "net_support_price": 0,
          "typeOfChange": Number(sm.typeOfChange),
          "discount": Number(sm.discount),
          "discountdistributorprice": (sm.dpbValue !== '') ? Number(sm.dpbValue) : 0,
          "discountnetsupportprice": (sm.specValue !== '') ? Number(sm.specValue) : 0,
          "directionOfChange": Number(sm.directionOfChange),
          "updatedBy": this.userId,
          "comments": sm.massChangeComment,
          "scopeOfChange": 0,
          "EffectiveDate": sm.date
        };
      default: return 0;
    }
  }

  massCancellation() {
    this.displayMassCancellationOpt = true
  }

  massCancellationUpload() {
    this.displayMassCancellationOpt = false;
    this.massCancellationDialog = true;
    this.displayUpload = true;
    this.fileUploadType = "Mass Cancellation";
  }

  massChangeUpload() {
    this.massCancellationDialog = true;
    this.displayUpload = true;
    this.displayMassChangeOpt = false;
    this.fileUploadType = "Mass Change";
  }

  myUploader(event) {
    this.spinner.show();
    let fileData = event[0];
    const element = event[0];
    this.filesize = element.size;
    this.filesize = this.filesize.toString();
    if (element.size > 3145728) {
      this.spinner.hide();
      this.toastr.warning("File Limit Exceeded, Allows only below 3MB data");
      return false;
    } else {
      this.fileName = fileData.name;
      this.fileType = fileData.type;
      this.fileBuffer = "";
      let fileReader = new FileReader();
      fileReader.onload = (_e) => {
        this.fileBuffer = fileReader.result;
        this.fileBuffer = this.fileBuffer.replace(`data:${this.fileType};base64,`, '');
        this.fileUpload = true;
        this.fileObject = {
          "businessUnitId": this.selectedBusinessUnit,
          "fileName": this.fileName,
          "fileType": this.fileType,
          "fileSize": this.filesize,
          "fileAsBase64": this.fileBuffer,
          "created_by": Number(sessionStorage.getItem('userid')),
          "salesSupportDistributor": Number(sessionStorage.getItem('SalesSupportDistributor'))
        };
      }
      fileReader.readAsDataURL(fileData);
    }
    this.uploadedFiles.push(event[0]);
    this.spinner.hide();
  }

  uploadSubmit(_fileUpload) {
    this.spinner.show();
    if (this.fileUploadType === 'Mass Cancellation') {
      this.massCancellationUploadAPICall();
    } else {
      this.displayMassChangeOpt = false;
      this.massChangeUploadAPICall();
    }
  }

  massCancellationUploadAPICall() {
    this._cupsService.UploadExcelTemplate(this.fileObject).subscribe(response => {
      if (!response['isError']) {
        let apiResponse = JSON.stringify(response);
        if (apiResponse.includes("Invalid template")) { this.toastr.error(apiResponse, 'Error!') }
        else {
          this.massCancellationDialog = false;
          this.displayUpload = false;
          this._cupsService.displayToastr(response);
          this.getGridData(this.APPROVED, this.selectedBusinessUnit);
        }
        this.displayUpload = false;
        this.spinner.hide();
      } else {
        this._cupsService.displayToastr(response);
      }
    });
  }

  massChangeUploadAPICall() {
    this._cupsService.UploadEMMassChangeExcelTemplate(this.fileObject).subscribe(response => {
      if (!response['isError']) {
        let apiResponse = JSON.stringify(response);
        if (apiResponse.includes("Invalid template")) { this.toastr.error(apiResponse, 'Error!') }
        else {
          this.massCancellationDialog = false;
          this.displayUpload = false;
          this._cupsService.displayToastr(response);
        }
        this.displayUpload = false;
        this.spinner.hide();
      } else {
        this._cupsService.displayToastr(response);
      }
    });
  }

  uploadRebates() {
    this.displayUpload = true;
  }

  calculatePrice(_event, rowData) {
    switch (this.selectedBusinessUnit) {
      case 1: this.calculateForEM(rowData); break;
      case 2: this.calculateForEV(rowData); break;
      case 3: this.calculateForPM(rowData); break;
      case 4: this.calculateForED(rowData); break;
      default: break;
    }
  }

  calculateForEM(rowData) {
    this.selectedMassRows.forEach(mr => {
      if (mr.cupReqId === rowData.cupReqId) {
        if (rowData.typeOfChange === '1') {
          this.emChangeInPercentage(rowData);
        } else {
          this.emChangeInPrice(rowData);
        }
        this.calculateEMEVEDPrices(mr);
      }
    });
  }

  calculateForED(rowData) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.typeOfChange === '1') {
          this.edChangeInPercentage(rowData);
        } else {
          this.edChangeInPrice(rowData);
        }
        this.calculateEMEVEDPrices(smr);
      }
    });
  }

  emChangeInPercentage(rowData: any) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.directionOfChange === '1') {
          this.emChangeInPercentageIncrease(rowData);
        } else {
          this.emChangeInPercentageDecrease(rowData);
        }
      }
    });
  }
  edChangeInPercentage(rowData: any) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.directionOfChange === '1') {
          this.edChangeInPercentageIncrease(rowData);
        } else {
          this.edChangeInPercentageDecrease(rowData);
        }
      }
    });
  }
  edChangeInPercentageIncrease(rowData: any) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.dpbValue !== '') {
          smr.distributor_price_from_basf_MC = Number(smr.distributor_price_from_basf) + Number((Number(smr.dpbValue) / 100) * Number(smr.distributor_price_from_basf));
        }
        if (rowData.specValue !== '') {
          smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) + Number((Number(smr.specValue) / 100) * Number(smr.support_price_to_end_customer));
        }
      }
    });
  }
  edChangeInPercentageDecrease(rowData: any) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.dpbValue !== '') {
          smr.distributor_price_from_basf_MC = Number(smr.distributor_price_from_basf) - Number((Number(smr.dpbValue) / 100) * Number(smr.distributor_price_from_basf));
        }
        if (rowData.specValue !== '') {
          smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) - Number((Number(smr.specValue) / 100) * Number(smr.support_price_to_end_customer));
        }
      }
    });
  }

  emChangeInPercentageIncrease(rowData: any) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.dpbValue !== '') {
          smr.distributor_price_from_basf_MC = Number(smr.distributor_price_from_basf) + Number((Number(smr.dpbValue) / 100) * Number(smr.distributor_price_from_basf));
        }
        if (rowData.specValue !== '') {
          smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) + Number((Number(smr.specValue) / 100) * Number(smr.support_price_to_end_customer));
        }
      }
    });
  }

  emChangeInPercentageDecrease(rowData: any) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.dpbValue !== '') {
          smr.distributor_price_from_basf_MC = Number(smr.distributor_price_from_basf) - Number((Number(smr.dpbValue) / 100) * Number(smr.distributor_price_from_basf));
        }
        if (rowData.specValue !== '') {
          smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) - Number((Number(smr.specValue) / 100) * Number(smr.support_price_to_end_customer));
        }
      }
    });
  }

  emChangeInPrice(rowData: any) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.directionOfChange === '1') {
          this.emChangeInPriceIncrease(rowData);
        } else {
          this.emChangeInPriceDecrease(rowData);
        }
      }
    });
  }

  emChangeInPriceIncrease(rowData: any) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.dpbValue !== '') {
          smr.distributor_price_from_basf_MC = Number(smr.distributor_price_from_basf) + Number(smr.dpbValue);
        }
        if (rowData.specValue !== '') {
          smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) + Number(smr.specValue);
        }
      }
    });
  }

  emChangeInPriceDecrease(rowData: any) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.dpbValue !== '') {
          smr.distributor_price_from_basf_MC = Number(smr.distributor_price_from_basf) - Number(smr.dpbValue);
        }
        if (rowData.specValue !== '') {
          smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) - Number(smr.specValue);
        }
      }
    });
  }
  edChangeInPrice(rowData: any) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.directionOfChange === '1') {
          this.edChangeInPriceIncrease(rowData);
        } else {
          this.edChangeInPriceDecrease(rowData);
        }
      }
    });
  }

  edChangeInPriceIncrease(rowData: any) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.dpbValue !== '') {
          smr.distributor_price_from_basf_MC = Number(smr.distributor_price_from_basf) + Number(smr.dpbValue);
        }
        if (rowData.specValue !== '') {
          smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) + Number(smr.specValue);
        }
      }
    });
  }

  edChangeInPriceDecrease(rowData: any) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.dpbValue !== '') {
          smr.distributor_price_from_basf_MC = Number(smr.distributor_price_from_basf) - Number(smr.dpbValue);
        }
        if (rowData.specValue !== '') {
          smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) - Number(smr.specValue);
        }
      }
    });
  }

  calculateForEV(rowData) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.typeOfChange === '1') {
          this.changeInPercentage(rowData);
        } else {
          this.changeInPrice(rowData);
        }
      }
    });
  }

  changeInPercentage(rowData: any) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.directionOfChange === '1') {
          switch (smr.scopeOfChange) {
            case "1": smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) + Number((Number(smr.discount) / 100) * Number(smr.support_price_to_end_customer)); smr.net_support_price_MC = Number(smr.net_support_price); break;
            case "2": smr.net_support_price_MC = Number(smr.net_support_price) + Number((Number(smr.discount) / 100) * Number(smr.net_support_price)); smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer); break;
            case "3": smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) + Number((Number(smr.discount) / 100) * Number(smr.support_price_to_end_customer));
              smr.net_support_price_MC = Number(smr.net_support_price) + Number((Number(smr.discount) / 100) * Number(smr.net_support_price));
              break;
            default: break;
          }
        } else {
          switch (smr.scopeOfChange) {
            case "1": smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) - Number((Number(smr.discount) / 100) * Number(smr.support_price_to_end_customer)); smr.net_support_price_MC = Number(smr.net_support_price); break;
            case "2": smr.net_support_price_MC = Number(smr.net_support_price) - Number((Number(smr.discount) / 100) * Number(smr.net_support_price)); smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer); break;
            case "3": smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) - Number((Number(smr.discount) / 100) * Number(smr.support_price_to_end_customer));
              smr.net_support_price_MC = Number(smr.net_support_price) - Number((Number(smr.discount) / 100) * Number(smr.net_support_price));
              break;
            default: break;
          }
        }
        this.calculateEMEVEDPrices(smr);
      }
    });
  }

  changeInPrice(rowData: any) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.directionOfChange === '1') {
          switch (smr.scopeOfChange) {
            case "1": smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) + Number(smr.discount); smr.net_support_price_MC = Number(smr.net_support_price); break;
            case "2": smr.net_support_price_MC = Number(smr.net_support_price) + Number(smr.discount); smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer); break;
            case "3": smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) + Number(smr.discount);
              smr.net_support_price_MC = Number(smr.net_support_price) + Number(smr.discount); break;
            default: break;
          }
        } else {
          switch (smr.scopeOfChange) {
            case "1": smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) - Number(smr.discount); smr.net_support_price_MC = Number(smr.net_support_price); break;
            case "2": smr.net_support_price_MC = Number(smr.net_support_price) - Number(smr.discount); smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer); break;
            case "3": smr.support_price_to_end_customer_MC = Number(smr.support_price_to_end_customer) - Number(smr.discount);
              smr.net_support_price_MC = Number(smr.net_support_price) - Number(smr.discount); break;
            default: break;
          }
        }
        this.calculateEMEVEDPrices(smr);
      }
    });
  }

  calculateForPM(rowData) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        if (rowData.typeOfChange === '1') {
          if (rowData.directionOfChange === '1') {
            smr.suggested_selling_price_MC = Number(smr.suggested_selling_price) + Number((Number(smr.discount) / 100) * Number(smr.suggested_selling_price));
          } else {
            smr.suggested_selling_price_MC = Number(smr.suggested_selling_price) - Number((Number(smr.discount) / 100) * Number(smr.suggested_selling_price));
          }
        } else {
          if (rowData.directionOfChange === '1') {
            smr.suggested_selling_price_MC = Number(smr.suggested_selling_price) + Number(smr.discount);
          } else {
            smr.suggested_selling_price_MC = Number(smr.suggested_selling_price) - Number(smr.discount);
          }
        }
        this.doPMCalculationsForPM(smr);
      }
    });
  }

  callProductPricingService(rowData) {
    this.spinner.show();
    this.prodData = [];
    let effective_date = moment(rowData['date']).format('yyyy-MM-DD');
    this._cupsService.getProductPricingRecord(3, rowData.article_number, rowData.distributor_sold_to_id, 0, 0, effective_date, rowData.sales_org_name, 0).subscribe(response => {
      if (!response['isError']) {
        this.apiResp = response;
        let receivedProduct = JSON.parse(JSON.stringify(this.apiResp.response));
        let currencyUnit = rowData.currency_unit;
        this.checkScale1Values(receivedProduct, currencyUnit);
        this.checkScale2Values(receivedProduct, currencyUnit);
        this.checkScale3Values(receivedProduct, currencyUnit);
        this.checkScale4Values(receivedProduct, currencyUnit);
        this.checkScale5Values(receivedProduct, currencyUnit);
        this.checkScale6Values(receivedProduct, currencyUnit);
        this.checkScale7Values(receivedProduct, currencyUnit);
        let rowObj = {
          data: this.prodData.find(p => p.prodQuantity === rowData.minimum_qty)
        }
        if (rowObj.data) {
          this.onProductSelect(rowObj, rowData);
        }
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this._cupsService.displayToastr(response);
      }
    })
  }

  getEMPMHistory(rowData) {
    let requestId = this.selectedBusinessUnit === 3 ? rowData['pm_request_id'] : rowData['request_id'];
    this._cupsService.getEMPPMHistory(requestId, this.bu, this.isDistributor).subscribe(response => {
      this.spinner.hide();
      this.apiResp = response;
      if (!this.apiResp['isError']) {
        this.apiResp['historyData'].forEach(hd => {
          hd.type_of_change = (hd.type_of_change === 1) ? '%' : '$';
          hd.direction_of_change = (hd.direction_of_change === 1) ? 'Increase' : 'Decrease';
          hd.effective_date_of_change = moment(hd.effective_date_of_change).format('MM/DD/YYYY');
          hd.expiration_date = moment(hd.expiration_date).format('MM/DD/YYYY');
        })
        this.priceData = JSON.parse(JSON.stringify(this.apiResp['historyData']));
        this.priceData.forEach(pd => {
          pd.effectiveDate = moment(pd['effectiveDate']).format('MMMM Do YYYY');
        });
        this.getDistributorPrice(rowData['date'], rowData);
      }
    });
  }

  getDistributorPrice(date: any, rowData) {
    let foundPrices = [];
    if (this.priceData.length) {
      foundPrices = this.priceData.filter(pd => new Date(pd.effective_date_of_change).getTime() < new Date(date).getTime());
      foundPrices = foundPrices.filter(fp => moment(date).format("MM/DD/yyyy") !== moment(fp['effective_date_of_change']).format("MM/DD/yyyy"))
      this.massChangeExisting = false;
      if (this.selectedBusinessUnit === 3) {
        this.assignPM_MC_values(foundPrices, rowData);
      }
      if (this.selectedBusinessUnit === 1 || this.selectedBusinessUnit === 4) {
        this.assignEM_MC_values(foundPrices, rowData);
      }
    }
  }

  assignPM_MC_values(foundPrices, rowData) {
    if (foundPrices.length) {
      let minDate = foundPrices.reduce((a, b) => new Date(a.effective_date_of_change) > new Date(b.effective_date_of_change) ? a : b)
      if (new Date(rowData.date) === new Date(minDate['effective_date_of_change'])) {
        this.massChangeExisting = true;
      }
      this.selectedMassRows.forEach(smr => {
        if (smr.cupReqId === rowData.cupReqId) {
          smr['suggested_selling_price'] = minDate['suggested_selling_price'];
          smr['suggested_selling_price_MC'] = minDate['suggested_selling_price'];
          smr['supported_buy_price'] = minDate['supported_buy_price'];
          smr['supported_buy_price_MC'] = minDate['supported_buy_price'];
          smr['rebate'] = minDate['rebate']
          smr['rebate_MC'] = minDate['rebate'];
          this.calculateForPM(smr);
        }
      });
    } else {
      this.selectedMassRows.forEach(smr => {
        if (smr.cupReqId === rowData.cupReqId) {
          let fRequest = this.orgSelectedMassRows.find(orm => orm.cupReqId === rowData.cupReqId);
          smr['suggested_selling_price'] = fRequest['suggested_selling_price'];
          smr['suggested_selling_price_MC'] = fRequest['suggested_selling_price'];
          smr['supported_buy_price'] = fRequest['supported_buy_price'];
          smr['supported_buy_price_MC'] = fRequest['supported_buy_price'];
          smr['rebate'] = fRequest['rebate']
          smr['rebate_MC'] = fRequest['rebate'];
          this.calculateForPM(smr);
        }
      });
    }
  }

  assignEM_MC_values(foundPrices, rowData) {
    if (foundPrices.length) {
      let minDate = foundPrices.reduce((a, b) => new Date(a.created_date) > new Date(b.created_date) ? a : b)
      if (moment(rowData.date).format("MM/DD/yyyy") === moment(minDate['effective_date_of_change']).format("MM/DD/yyyy")) {
        this.massChangeExisting = true;
      }
      this.selectedMassRows.forEach(smr => {
        if (smr.cupReqId === rowData.cupReqId) {
          smr['distributor_price_from_basf'] = minDate['distributor_price_from_basf'];
          smr['distributor_price_from_basf_MC'] = minDate['distributor_price_from_basf'];
          smr['support_price_to_end_customer'] = minDate['support_price_to_end_customer'];
          smr['support_price_to_end_customer_MC'] = minDate['support_price_to_end_customer'];
          smr['net_support_price'] = minDate['net_support_price']
          smr['net_support_price_MC'] = minDate['net_support_price'];
          smr['credit_back_amount'] = minDate['credit_back_amount']
          smr['credit_back_amount_MC'] = minDate['credit_back_amount'];
          this.calculateEMEVEDPrices(smr);
        }
      });
    }
    else {
      this.assignEM_MC_INIT_Values(rowData);
    }
  }

  assignEM_MC_INIT_Values(rowData) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        let fRequest = this.orgSelectedMassRows.find(orm => orm.cupReqId === rowData.cupReqId);
        smr['distributor_price_from_basf'] = fRequest['distributor_price_from_basf'];
        smr['distributor_price_from_basf_MC'] = fRequest['distributor_price_from_basf'];
        smr['support_price_to_end_customer'] = fRequest['support_price_to_end_customer'];
        smr['support_price_to_end_customer_MC'] = fRequest['support_price_to_end_customer'];
        smr['net_support_price'] = fRequest['net_support_price']
        smr['net_support_price_MC'] = fRequest['net_support_price'];
        smr['credit_back_amount'] = fRequest['credit_back_amount']
        smr['credit_back_amount_MC'] = fRequest['credit_back_amount'];
        this.calculateEMEVEDPrices(smr);
      }
    });
  }

  checkScale1Values(receivedProduct: any, currencyUnit) {
    if (receivedProduct['scale_rate1'] && receivedProduct['scale_quantity1']) {
      let prodObj = {
        prodId: 1,
        prodQuantity: receivedProduct['scale_quantity1'],
        prodLBPrice: receivedProduct['scale_rate1'],
        prodKGPrice: (2.2046 * Number(receivedProduct['scale_rate1'])).toFixed(2),
        prodCurrency: currencyUnit
      }
      this.isListPricebind = true;
      this.prodData.push(prodObj);
    }
  }

  checkScale2Values(receivedProduct: any, currencyUnit) {
    if (receivedProduct['scale_rate2'] && receivedProduct['scale_quantity2']) {
      let prodObj1 = {
        prodId: 2,
        prodQuantity: receivedProduct['scale_quantity2'],
        prodLBPrice: receivedProduct['scale_rate2'],
        prodKGPrice: (2.2046 * Number(receivedProduct['scale_rate2'])).toFixed(2),
        prodCurrency: currencyUnit
      }
      this.isListPricebind = true;
      this.prodData.push(prodObj1);
    }
  }

  checkScale3Values(receivedProduct: any, currencyUnit) {
    if (receivedProduct['scale_rate3'] && receivedProduct['scale_quantity3']) {
      let prodObj3 = {
        prodId: 3,
        prodQuantity: receivedProduct['scale_quantity3'],
        prodLBPrice: receivedProduct['scale_rate3'],
        prodKGPrice: (2.2046 * Number(receivedProduct['scale_rate3'])).toFixed(2),
        prodCurrency: currencyUnit
      }
      this.isListPricebind = true;
      this.prodData.push(prodObj3);
    }
  }

  checkScale4Values(receivedProduct: any, currencyUnit) {
    if (receivedProduct['scale_rate4'] && receivedProduct['scale_quantity4']) {
      let prodObj4 = {
        prodId: 4,
        prodQuantity: receivedProduct['scale_quantity4'],
        prodLBPrice: receivedProduct['scale_rate4'],
        prodKGPrice: (2.2046 * Number(receivedProduct['scale_rate4'])).toFixed(2),
        prodCurrency: currencyUnit
      }
      this.isListPricebind = true;
      this.prodData.push(prodObj4);
    }
  }

  checkScale5Values(receivedProduct: any, currencyUnit) {
    if (receivedProduct['scale_rate5'] && receivedProduct['scale_quantity5']) {
      let prodObj5 = {
        prodId: 5,
        prodQuantity: receivedProduct['scale_quantity5'],
        prodLBPrice: receivedProduct['scale_rate5'],
        prodKGPrice: (2.2046 * Number(receivedProduct['scale_rate5'])).toFixed(2),
        prodCurrency: currencyUnit
      }
      this.isListPricebind = true;
      this.prodData.push(prodObj5);
    }
  }

  checkScale6Values(receivedProduct: any, currencyUnit) {
    if (receivedProduct['scale_rate6'] && receivedProduct['scale_quantity6']) {
      let prodObj6 = {
        prodId: 6,
        prodQuantity: receivedProduct['scale_quantity6'],
        prodLBPrice: receivedProduct['scale_rate6'],
        prodKGPrice: (2.2046 * Number(receivedProduct['scale_rate6'])).toFixed(2),
        prodCurrency: currencyUnit
      }
      this.isListPricebind = true;
      this.prodData.push(prodObj6);
    }
  }

  checkScale7Values(receivedProduct: any, currencyUnit) {
    let prodObj7 = {
      prodId: 7,
      prodQuantity: 0,
      prodLBPrice: receivedProduct.list_Price,
      prodKGPrice: (2.2046 * Number(receivedProduct.list_Price)).toFixed(2),
      prodCurrency: currencyUnit
    }
    this.prodData.push(prodObj7);
  }

  onProductSelect(rowObj, rowData) {
    this.selectedMassRows.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        smr['minimum_qty'] = rowObj.data['prodQuantity'];
        smr['list_price_lb'] = rowObj.data['prodLBPrice'];
        smr['list_price_kg'] = rowObj.data['prodKGPrice'];
        smr['currency_unit'] = rowObj.data['prodCurrency'];
      }
    });
    this.doPMCalculationsForPM(rowData);
  }

  getDaysDiff(start_date, end_date, _date_format = 'YYYY-MM-DD') {
    let sDate = moment(start_date);
    let eDate = moment(end_date);
    return sDate.diff(eDate, 'days') + 1;
  }

  removeCup(rowData) {
    this.selectedMassRows = this.selectedMassRows.filter(smr => smr.cupReqId !== rowData.cupReqId);
  }

  getFilterObject(fullObj, key) {
    const uniqChk = [];
    let blanks = false;
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        if (obj[key] !== null && obj[key] !== '')
          uniqChk.push(obj[key]);
        else if (!uniqChk.includes('Blanks')) {
          blanks = true;
        }
      }
      return obj;
    });
    const numericColumns = ['competitive_price', 'mileage_outbound', 'mileage_inbound', 'distributor_price_from_basf', 'support_price_to_end_customer', 'discount_percent', 'frieght_allowance', 'other_allowance', 'net_support_price', 'credit_back_amount'];
    const dateColumns = ['submission_date', 'expiration_date', 'effective_date'];
    if (dateColumns.includes(key)) {
      uniqChk.sort(function (a, b) {
        const aComps = a.split("/");
        const bComps = b.split("/");
        const aDate = new Date(aComps[2], aComps[0], aComps[1]);
        const bDate = new Date(bComps[2], bComps[0], bComps[1]);
        return aDate.getTime() - bDate.getTime();
      });
    }
    else if (numericColumns.includes(key)) {
      uniqChk.sort((a, b) => {
        return ((Number(a) < Number(b)) ? -1 : 1)
      });
    }
    else {
      uniqChk.sort();
    }
    if (blanks) uniqChk.unshift('Blanks');
    return uniqChk;
  }
}
