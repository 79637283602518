import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CupsService } from 'src/services/cups.service';
@Component({
  selector: 'app-approval-config',
  templateUrl: './approval-config.component.html',
  styleUrls: ['./approval-config.component.css']
})
export class ApprovalConfigComponent implements OnInit {
  AdminForm: FormGroup;
  BusinessUnit: any[];
  Products: any[];
  CountryData: any[];
  RegionData: any[];
  ProductsbasedonBusiness: any[];
  selectedBusiness: any;
  selectedSBusiness: any;
  selectedtypeofConfig: any;
  submitted = false;
  SBusinessUnit: any[];
  BusinessUnitData: any;
  approvalConfigData: any = [];
  UOM: any;
  operatorData: any;
  TypeofConfiguration: { id: number; type: string; }[];
  fb: any;
  isType1: boolean = false;
  isType2: boolean = false;
  arraylength: number = 0;
  selectedTypevalue: any;
  
  constructor(private formBuilder: FormBuilder, private _service: CupsService, private spinner: NgxSpinnerService, private toastr: ToastrService) {
    this.AdminForm = this.formBuilder.group({
      BusinessUnitId: ['', [Validators.required, Validators.min(0)]],
      SBUId: ['', [Validators.required, Validators.min(0)]],
      CountryId: ['', [Validators.required]],
      RegionId: ['', [Validators.required]],
      TypeId: ['', [Validators.required]],
      ApprovalConfigTypeone: this.formBuilder.array([]),
      ApprovalConfigTypetwo: this.formBuilder.array([]),
      CreatedBy: [''],
      CreatedDate: [new Date()],
    });
  }
  ngOnInit() {
    this.AdminForm.reset();
    this.getAdminConfigMaster();
  }
  onSubmit() {
    if (this.AdminForm.valid) {
      sessionStorage.setItem("UserDetails", "samba");
      if (this.AdminForm.value.TypeId == 1) {
        this.AdminForm.value.ApprovalConfigTypeone[this.AdminForm.value.ApprovalConfigTypeone.length - 1].min_level = this.AdminForm.value.ApprovalConfigTypeone[this.AdminForm.value.ApprovalConfigTypeone.length - 2].max_level;
      }
      this.AdminForm.controls.CreatedBy.setValue(sessionStorage.getItem("UserDetails"));
      this.AdminForm.controls.CreatedDate.setValue(new Date());
      this._service.saveAdminConfig(this.AdminForm.value).subscribe((resp: any) => {
        this.toastr.success('Contacts configured successfully');
      });
    }
  }
  ApprovalConfigTypeone(): FormArray {
    return this.AdminForm.get('ApprovalConfigTypeone') as FormArray;
  }
  ApprovalConfigTypetwo(): FormArray {
    return this.AdminForm.get('ApprovalConfigTypetwo') as FormArray;
  }
  addApprovalConfigTypeone(data: any) {
    this.ApprovalConfigTypeone().push(this.newApprovalConfigTypeone(data));
  }
  newApprovalConfigTypeone(data: any): FormGroup {
    if (!data) {
      data = { Id: 0, variant_id: '', role: '', min_level: '', max_level: '', created_by: '' };
    }
    return this.formBuilder.group({
      Id: [data.apc1id],
      variant_id: [data.id],
      role: [data.role],
      min_level: [data.min_level, [Validators.min(0)]],
      max_level: [data.max_level],
      created_by: [data.created_by]
    });
  }
  addApprovalConfigTypetwo(data: any) {
    this.ApprovalConfigTypetwo().push(this.newApprovalConfigTypetwo(data));
  }
  newApprovalConfigTypetwo(data: any): FormGroup {
    if (!data) {
      data = { Id: 0, variant_id: '', role: '', variant1: '', variant2: '', comparision_operator_id: '', value: '', uom_id: '', created_by: '' };
    }
    return this.formBuilder.group({
      Id: [data.apc2id],
      variant_id: [data.id],
      role: [data.role],
      variant1: [data.variant1],
      variant2: [data.variant2],
      comparision_operator_id: [data.comparision_operator_id],
      value: [data.value],
      uom_id: [data.uom_id],
      created_by: [data.created_by]
    });
  }
  Cancel() {
    this.AdminForm.reset();
  }
  getAdminConfigMaster() {
    this._service.getAdminConfigMaster().subscribe((response: any) => {
      this.BusinessUnitData = response.buData;
      this.CountryData = response.countryData;
      this.RegionData = response.regionData;
      this.UOM = response.uomData;
      this.operatorData = response.operatorData;
      this.TypeofConfiguration = [{ id: 1, type: "Based on one price" }, { id: 2, type: "Based on two price" }]
      let key = 'businessunit_id';
      this.BusinessUnit = [...new Map(this.BusinessUnitData.map(item =>
        [item[key], item])).values()];
    });
  }
  onBusinessChange(event) {
    if (this.BusinessUnitData != undefined) {
      this.SBusinessUnit = this.BusinessUnitData.filter(i => i.businessunit_id == event);
    }
  }
  onSBusinessChange(newValue) {
    if (this.selectedTypevalue == "1" || this.selectedTypevalue == "2") {
      this.ontypeofConfigChange(this.selectedTypevalue)
    }
  }
  ontypeofConfigChange(newValue) {
    this.selectedTypevalue = newValue;
    if (newValue == "1") {
      this.isType1 = true;
      this.isType2 = false;
    }
    else if (newValue == "2") {
      this.isType2 = true;
      this.isType1 = false;
    }
    else {
      this.isType2 = false;
      this.isType1 = false;
    }
    this.servicecall();
  }
  servicecall() {
    this._service.getApprovalConfig(this.AdminForm.value.RegionId, this.AdminForm.value.CountryId, this.AdminForm.value.BusinessUnitId, this.AdminForm.value.SBUId, Number(this.AdminForm.value.TypeId)).subscribe((response: any) => {
      if (this.AdminForm.controls.TypeId.value == '1') {
        const array = this.AdminForm.get('ApprovalConfigTypeone') as FormArray;
        array.clear();
        response.approvalConfigData.forEach((value, index) => {
          if (index != response.approvalConfigData.length - 1) {
            array.push(this.newApprovalConfigTypeone(value));
          }
          else {
            const data = { apc1id: value.apc1id, id: value.id, role: value.role, min_level: response.approvalConfigData[index - 1].min_level, max_level: value.max_level, created_by: value.created_by };
            array.push(this.newApprovalConfigTypeone(data));
          }
        });
        this.arraylength = array.length;
      }
      else if (this.AdminForm.controls.TypeId.value == '2') {
        const array = this.AdminForm.get('ApprovalConfigTypetwo') as FormArray;
        response.approvalConfigData.forEach((value, index) => {
          array.push(this.newApprovalConfigTypetwo(value));
        });
        this.arraylength = array.length;
      }
    });
  }
}
