import { Component, HostBinding, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  name = 'NavigationBarProject';

  @HostBinding("class.drawer-open")
  isDrawerOpen: boolean = false;
  
  toggleDrawer(isDrawerOpen: boolean) {
    this.isDrawerOpen = isDrawerOpen;
  }
  enableBg = true;
  userAccess = false;
  userAdmin = false;

  unAuthorised = false;
  title = 'app';
  loginuser: boolean=false;
  constructor(
    public router: Router) {
      router.events.pipe(
        filter(event => event instanceof NavigationEnd)  
      ).subscribe((event: NavigationEnd) => {
        this.unAuthorised =false;
        let expectedurl = event.url;
        this.userAccess= false;
        if(event.url == '/' ||event.url == '/login-test' || event.url == '/login' || event.url == '/logout'|| event.url=='/registration' || event.url=='/saml' || event.url =='/error') {
          this.userAccess = false;
          
        } else {
          this.userAccess = true;
        }
        if(expectedurl =='admin' || expectedurl == 'addadminprice' || expectedurl == 'addadminuser'|| expectedurl =='contactdetails'
        || expectedurl =='userdetails'|| expectedurl =='usercompany' ){
       
        if(JSON.parse(sessionStorage.getItem('IsAdmin')) && !!sessionStorage.getItem('UserToken')){
          this.unAuthorised =false;
          this.userAccess =true;
        }
        else{
          this.unAuthorised =true;
          this.userAccess =false;
        }
      }
      if(expectedurl == 'home' || expectedurl =='contact'){
        if(!!sessionStorage.getItem('UserToken')){
        this.unAuthorised =false;
        this.userAccess =true;
        }
        else
        {
          this.unAuthorised =true;
        this.userAccess =false;
        }
      }
    });
        
  }

  ngOnInit() {
    this.title = 'app';
   }
}
