import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  selectedBU = "";
  selectedTab: any;
  supportManagerText = 'Sales Support Manager';

  isSalesSupportDistributor: number = 0;
  ngOnInit() {
    this.selectedBU = sessionStorage.getItem('BU');
    if(this.selectedBU === 'EM' || this.selectedBU==='ED') {
      this.supportManagerText = 'Pricing Admin Config';
    } else {
      this.supportManagerText = 'Sales Support Manager';
    }
    this.isSalesSupportDistributor = Number(sessionStorage.getItem('SalesSupportDistributor'));
  }

  onTabChange(event) {
    this.selectedTab = event.originalEvent.target.innerText;
  }

}
