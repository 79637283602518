import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { CupsService } from 'src/services/cups.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-cup-history',
  templateUrl: './cup-history.component.html',
  styleUrls: ['./cup-history.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CupHistoryComponent implements OnInit {
  @Input() cupReqId: any;
  @Output() sendPriceData = new EventEmitter();
  selectedBusinessUnit: any;
  bu: any;
  EVID = 2;
  EMID = 1;
  PMID = 3;
  EDID = 4;
  cols = [];
  priceData: any;
  apiResp: any;
  emEVCols: any;
  pmCols: any;
  isDistributor: any;
  filteredFields = [];
  filteredData: any;
  tempFilteredData: any;
  constructor(private _service: CupsService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.bu = sessionStorage.getItem('BU');
    this.isDistributor = ( sessionStorage.getItem('isDistributor') === "1" || sessionStorage.getItem('isDistributor') === "true") ? true : false;
    this.emEVCols = [
      {
        "header_id": 1,
        "field": "cupReqId",
        "header": "Ref #",
        "is_show": true,
        "is_editable": false,
        "serial_no": 1
      },
      {
        "header_id": 1,
        "field": "effectiveDate",
        "header": "Date",
        "is_show": true,
        "is_editable": false,
        "serial_no": 1
      },
      {
        "header_id": 2,
        "field": "distributor_price_from_basf",
        "header": "Distributor Price",
        "is_show": true,
        "is_editable": false,
        "serial_no": 2
      },
      {
        "header_id": 3,
        "field": "support_price_to_end_customer",
        "header": "Support Price",
        "is_show": true,
        "is_editable": false,
        "serial_no": 3
      },
      {
        "header_id": 4,
        "field": "net_support_price",
        "header": "Net Support Price",
        "is_show": true,
        "is_editable": false,
        "serial_no": 3
      },
      {
        "header_id": 5,
        "field": "credit_bank_amount",
        "header": "Credit Back Amount",
        "is_show": true,
        "is_editable": false,
        "serial_no": 3
      }
    ];
    this.pmCols = [
      {
        "header_id": 1,
        "field": "cupReqId",
        "header": "Ref #",
        "is_show": true,
        "is_editable": false,
        "serial_no": 1
      },
      {
        "header_id": 1,
        "field": "effectiveDate",
        "header": "Date",
        "is_show": true,
        "is_editable": false,
        "serial_no": 1
      },
      {
        "header_id": 2,
        "field": "selling_margin",
        "header": "Selling Margin",
        "is_show": true,
        "is_editable": false,
        "serial_no": 2
      },
      {
        "header_id": 3,
        "field": "suggested_selling_price",
        "header": "Suggested Selling Price",
        "is_show": true,
        "is_editable": false,
        "serial_no": 3
      },
      {
        "header_id": 4,
        "field": "supported_buy_price",
        "header": "Supported Buy Price",
        "is_show": true,
        "is_editable": false,
        "serial_no": 3
      },
      {
        "header_id": 5,
        "field": "rebate",
        "header": "Rebate",
        "is_show": true,
        "is_editable": false,
        "serial_no": 3
      }
    ]
    this.updateSelectedBU();
    this.getEMPPMHistory();
  }

  updateSelectedBU() {
    switch (this.bu) {
      case 'EM': this.selectedBusinessUnit = this.EMID; this.cols = this.emEVCols; break;
      case 'EV': this.selectedBusinessUnit = this.EVID; this.cols = this.emEVCols; break;
      case 'PM': this.selectedBusinessUnit = this.PMID; this.cols = this.pmCols;break;
      case 'ED': this.selectedBusinessUnit = this.EDID; this.cols = this.emEVCols;break;
      default: this.selectedBusinessUnit = this.EMID; this.cols = this.emEVCols; break;
    }
  }

  getEMPPMHistory() {
    this.spinner.show();
    this._service.getEMPPMHistory(this.cupReqId, this.bu, this.isDistributor).subscribe(response => {
      this.spinner.hide();
      this.apiResp = response;
      if(!this.apiResp['isError']) {
        this.cols = JSON.parse(JSON.stringify(this.apiResp['headerColumns']));
        if(this.isDistributor || Number(sessionStorage.getItem('SalesSupportDistributor'))>0){
          this.cols=this.cols.filter(col=>col.field!=='comments');
        }
        this.apiResp['historyData'].forEach(hd => {
          if (!hd.isInitialCupData) {
            hd.type_of_change = (hd.type_of_change === 1) ? '%' : '$';
            hd.direction_of_change = (hd.direction_of_change === 1) ? 'Increase' : 'Decrease';
          } else {
            hd.type_of_change = 'NA';
            hd.direction_of_change = 'NA';
            hd.change_value = 'NA';
          }
          hd.effective_date_of_change = moment(hd.effective_date_of_change).format('MM/DD/YYYY');
          hd.expiration_date = moment(hd.expiration_date).format('MM/DD/YYYY');
        })
        this.priceData = JSON.parse(JSON.stringify(this.apiResp['historyData']));
        this.filteredData = JSON.parse(JSON.stringify(this.priceData));
        this.sendPriceData.emit(this.apiResp['historyData']);
        this.priceData.forEach(pd => {
          pd.effectiveDate = moment(pd['effectiveDate']).format('MMMM Do YYYY');
        });
      }
    });
  }

  filterData(filterObj) {
    let {value, filter } = filterObj;
    if (value == '') {
      if (this.filteredFields.length) {
        this.filteredFields = this.filteredFields.filter(ff => ff.fieldName !== filter);
      } else {
        this.filteredFields = [];
      }
    } else {
      let foundField = this.filteredFields.filter(ff => ff.fieldName == filter);
      if (foundField.length) {
        this.filteredFields.forEach(ff => {
          if (ff.fieldName == filter) {
            ff.value = value;
          }
        })
      } else {
        let fieldObj = {
          fieldName: filter,
          value: value
        };
        this.filteredFields.push(fieldObj);
      }
    }
    if (this.filteredFields.length) {
      let temp = this.priceData;
      this.filteredFields.forEach(ff => {
        temp = temp.filter(fd => fd[ff.fieldName].toUpperCase().includes(ff.value.toUpperCase()));
      })
      this.filteredData = temp;
    } else {
      this.filteredData = JSON.parse(JSON.stringify(this.priceData));
    }
    this.tempFilteredData = JSON.parse(JSON.stringify(this.filteredData));
  }

  exportExcel() {
    let currentDate = moment().format('MM_DD_YYYY_hh_mm_ss_a');
    this.exportAsExcelFile(this.filteredData, `Price_Revision_History_${currentDate}`, 'sheet1', this.cols)
  }

  exportAsExcelFile(json: any[], excelFileName: string, sheetName: string, columnValues: any[]): void {
    let fieldValues: any[] = columnValues.map(field => field.field);
    let headerValues: string[] = columnValues.map(header => header.header);
    let displayedJson: any[] = [];
    if (json != null && json.length > 0) {
      for (let originalValue of json) {
        let jsonObject: any = {};
        for (let fieldValue of fieldValues) {
          jsonObject[fieldValue] = originalValue[fieldValue];
        }
        let jsonString = JSON.stringify(jsonObject);
        displayedJson.push(JSON.parse(jsonString));
      }
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(displayedJson);
      let range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        let address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
        if (!worksheet[address]) continue;
        worksheet[address] = {
          v: headerValues[C],
          t: 's',
          s: {
            alignment: { textRotation: 90 },
            font: { bold: true }
          }
        };
      }
      XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: -1 });
      const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }

  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

}
