import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


import { CupsService } from 'src/services/cups.service';



@Component({
  selector: 'app-saml',
  templateUrl: './saml.component.html',
  styleUrls: []
})
export class SamlComponent implements OnInit {
  userdataDB: any[];
  returnUrl: string;
  returnUrlpath: string;

  constructor(private route: ActivatedRoute, private router: Router, private _service: CupsService) { }
  usertoken: any;
  notAuthorized = false;
  isloading = true;
  userData: any = {
    email: "",
    id: 0,
    region: "",
    regionNumber: 0,
    role: "",
    userId: "",
    userName: "",
    notAuthorized: false
  };
  usersData: any;
  usersData1: any;
  buUsersData: any;


  ngOnInit() {

    this
      .route
      .queryParams
      .subscribe(params => {
        if (params['returnUrl'] != undefined) {
          sessionStorage.setItem('returnUrl', params['returnUrl']);
        }
        this.usertoken = params.Username;
        sessionStorage.setItem('loginuseremail', params.Email);
        sessionStorage.setItem('jwtToken', params.Email);
        sessionStorage.setItem('UserToken', JSON.stringify(params));
        sessionStorage.setItem('IsAdmin', params.IsAdmin);
        sessionStorage.setItem('userid', params.userid);
        if (this.usertoken == undefined || this.usertoken == '') {
          this.SAMLService();
        }
        else {
          if (params.userid > 0) {
            this.binduserdetails(params.Email);
          }
          else {
            this.router.navigate(['/error']);
          }
        }
      });
  }
  binduserdetails(params) {
    this._service.getUserDetailsById(atob(params)).subscribe(response => {
      if (!response['isError']) {
      this.usersData = response['lstUsers'][0];
      if (this.usersData) {
        this.setSessionStorageValues();
        this.returnUrl = sessionStorage.getItem('returnUrl');
        if (this.returnUrl == undefined || this.returnUrl == '') {
          this.router.navigate(['/home']);
        }
        else {
          this.returnUrl = decodeURIComponent(this.returnUrl);
          this.returnUrlpath = sessionStorage.getItem('returnUrlpath');
          this.router.navigateByUrl(this.returnUrl);
        }
      } else {
        this.router.navigate(['error'])
      }
    } else {
      this._service.displayToastr(response);
    }
    })
  }

  setSessionStorageValues() {
    sessionStorage.setItem('loginuser', this.usersData.first_name);
    sessionStorage.setItem('loginuseremail', this.usersData.email);
    sessionStorage.setItem('jwtToken', btoa(this.usersData.email));
    sessionStorage.setItem('UserToken', this.usersData);
    //if (this.usersData.businessunit_id == 1 || this.usersData.businessunit_id == 4) {
      sessionStorage.setItem('SalesSupportDistributor', '0');
    //}
    this._service.sendAdminValue(this.usersData.is_admin);
    sessionStorage.setItem('userid', this.usersData.user_id);
    sessionStorage.setItem('isDistributor', this.usersData.is_distributor_user);
    sessionStorage.setItem('isSalesSupport', this.usersData.is_sales_support);
    if (this.usersData.is_distributor_user) {
      sessionStorage.setItem('IsAdmin', 'false');
    }
    else {
      sessionStorage.setItem('IsAdmin', this.usersData.is_admin);
    }
    sessionStorage.setItem('isSalesRep', this.usersData.is_sales_rep);
    this._service.sendDistributorValue(this.usersData.is_distributor_user);
    let sessionObject = {
      isAdmin: this.usersData.is_admin,
      isDistributor: this.usersData.is_distributor_user,
      loginUser: sessionStorage.getItem('loginuser')
    };
    this._service.sendSessionObject(sessionObject);

    switch (this.usersData.businessunit_id) {
      case 1: sessionStorage.setItem('BU', 'EM'); break;
      case 2: sessionStorage.setItem('BU', 'EV'); break;
      case 3: sessionStorage.setItem('BU', 'PM'); break;
      case 4: sessionStorage.setItem('BU', 'ED'); break;
      default: sessionStorage.setItem('BU', 'EM'); break;
    }
    this._service.sendSalesSupport(this.usersData.is_sales_support);
    this._service.sendSalesRep(this.usersData.is_sales_rep);
    this._service.sendBusinessUnit(sessionStorage.getItem('BU'));
    this._service.sendUserValue(sessionStorage.getItem('userid'));
  }

  SAMLService() {
    this._service.getSAMLService().subscribe(result => {
      if(!result['isError']){ 
      window.location.href = result.strResponse.toString();
      } else {
        this._service.displayToastr(result);
      }
    },
      (error) => {
        console.log('error ', error);
      });
  }

}
