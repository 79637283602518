import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CupsService } from 'src/services/cups.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cups-request',
  templateUrl: './cups-request.component.html',
  styleUrls: ['./cups-request.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CupsRequestComponent implements OnInit {
  formData = {};
  //  = {
  //   //"submissionDate": "",
  //   //"effectiveDate": "" ,
  //   //"expirationDate": "",
  //   "distributorName": "",
  //   "distributorCity": "",
  //   "productName": "",
  //   "article": "",
  //   "estimatedAnnualVolume": "",
  //   "estimatedAnnualVolume_uom": "",
  //   "bulk_pkg_type": "",
  //   "cup_intostock_3rdparty": "" ,
  //   "freight_terms_distributor": "",
  //   "end_user_name": "",
  //   "city": "",
  //   "state": "",
  //   "zip": "",
  //   "distributor_price_from_basf": "",
  //   "support_price_to_end_customer": "",
  //   "discount_percent": "" ,
  //   "freight_allowance": "" ,
  //   "other_allowances": "" ,
  //   "net_support_price": "" ,
  //   "credit_back_amount": "" ,
  //   "mileageInbound": "",
  //   "mileageOutbound": "",
  //   "distributor": "",
  //   "competitor": "",
  //   "competitive_material": "",
  //   "competitive_application": "",
  //   "competitive_price": "",
  //   "industry_or_market": "",
  //   "comments": "",
  //   "product_id":"",
  //   "reference_id":"",
  //   "request_id":0
  // };
  requestAPI = {
    request_id: 0,
    submission_date: '2021-11-22T01:45:14.661Z',
    effective_date: '2021-11-22T01:45:14.661Z',
    expiration_date: '2021-11-22T01:45:14.661Z',
    distributor_id: 0,
    product_id: 0,
    article_number: '',
    estimated_annual_volume: 0,
    estimated_annual_volume_UOM_id: 0,
    bulk_package_type: '',
    cups_intostock_id: 0,
    frieght_terms_to_distributor_id: 0,
    customer_id: 0,
    customer_state: '',
    customer_city: '',
    distributor_price_from_basf: 0,
    support_price_to_end_customer: 0,
    prior_support_price: 0,
    delta_price: 0,
    discount_percentage: 0,
    frieght_allowance: 0,
    other_allowance: 0,
    net_support_price: 0,
    credit_back_amount: 0,
    mileage_inbound: '',
    mileage_outbound: '',
    distributor: '',
    competitor: 0,
    competitive_material: '',
    competitive_application: '',
    competitive_price: 0,
    status_id: 0,
    industry_market_id: 0,
    comments: '',
    created_by: '',
    discount_percent_id: 0,
    ship_to: 0,
    businessunit_id: 0,
    external_comments: '',
    freight_terms_to_end_customer_id: 0,
    is_internal_user: false,
    distributor_sales_rep_id: 0,
    product_name: '',
    product_manager: 0,
    sbuId: 0,
    distributor_region_id: 0,
    saleSupportUserUpdate: false
  };
  newCustomer = {
    customer_id: 0,
    customer_name: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    created_by: '',
    customer_region: '',
    businessunit_id: 0,
    distributor_sold_to_id: 0
  };
  newCompetitor = {
    request_master_id: 0,
    master_name: 'Competitors',
    //master_key: '',
    value: '',
    created_by: '',
    businessunit_id: 0
  };
  orgRowData: any;
  rowData: any;
  masterApiResp: any;
  distributorsData: any;
  distributorData: any;
  selectedDistributor: any;
  productsData: any;
  selectedProduct: any;
  annualUOMData: any;
  selectedAnnualUOM: any;
  priceUOMTypeDD: any;
  selectedUOMType: any;
  bulkPkgType: any;
  selectedBulkPkgType: any;
  cupsIntoStockDD: any;
  distributorRegionDD: any;
  selectedCupsIntoStock: any;
  selectedDistributorRegion: any;
  freightTermstoDistributorDD: any;
  freightTermstoEndCustomerDD: any;
  customersDD: any;
  selectedCustomer: any;
  selectedFreightTermstoDistributor: any;
  selectedFreightTermstoEndCustomer: any;
  industryOrMarketDD: any;
  selectedIndustryOrMarket: any;
  requestMasterApiResp: any;
  requestMasterCompetitorsApiResp: any;
  productDetails: any;
  EVID = 2;
  EMID = 1;
  EDID = 4;
  businessName: any;
  soldToDD: any;
  shipToDD: any;
  selectedSoldTo: any;
  selectedShipTo: any;
  displayShipTo = false;
  cupRequestDetails: any;
  apiResp: any;
  formMode = 'New';
  selectedBU: any;
  competitorsDD: any;
  selectedCompetitor: any;
  userId: any;
  type: any;
  customerInfoRequired = true;
  displayCustomerPopUp = false;
  displayCompetitorPopUp = false;
  regionData = [
    {
      region_id: 1,
      region_name: 'North America'
    }
  ];
  selectedRegion: any;
  isDistributionActionRequired: boolean = false;
  displayCreditAmount: boolean = false;
  disableFreightTermsToDistributor: boolean = false;
  userAdmin: boolean = false;
  displayDistributorSalesRep: boolean = false;
  distSalesRepDD: any;
  selectedDistSalesRep: any;
  displayNewProduct: boolean = false;
  sbuDD: any;
  packageTypeDD: any;
  pmUsersDD: any;
  selectedPackageType: any;
  selectedProductManager: any;
  selectedSBU: any;
  isUpdateCustomer: boolean = false;
  isUpdateCompetitor: boolean = false;
  cityDD: any;
  stateDD: any;
  selectedCity: any;
  selectedState: any;
  isDistributor: boolean = false;
  minEffectDate: any;
  maxEffectDate: any;
  minExpireDate: any;
  isSalesSupportDistributor: number = 0;
  isSaleSupportUserEdit = false;
  constructor(private router: Router, private _service: CupsService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit() {
    switch (sessionStorage.getItem('BU')) {
      case 'EM': this.selectedBU = 1; break;
      case 'EV': this.selectedBU = 2; break;
      case 'PM': this.selectedBU = 3; break;
      case 'ED': this.selectedBU = 4; break;
      default: this.selectedBU = 1; break;
    }
    this.isSalesSupportDistributor = Number(sessionStorage.getItem('SalesSupportDistributor'));
    this.userId = sessionStorage.getItem('userid');
    this.userAdmin = (sessionStorage.getItem('IsAdmin') === "1" || sessionStorage.getItem('IsAdmin') === "true") ? true : false;
    this.isDistributor = (sessionStorage.getItem('isDistributor') === "1" || sessionStorage.getItem('isDistributor') === "true") ? true : false;
    this.displayDistributorSalesRep = (sessionStorage.getItem('isSalesSupport') === "1" || sessionStorage.getItem('isSalesSupport') === "true") ? true : false;
    if (!history.state.data) {
      this.router.navigate(['/home']);
    } else {
      this.getData();
    }
  }
  getData() {
    this.businessName = history.state.data.businessName;
    this.formData['submissionDate'] = moment(new Date()).format('L');
    this.minEffectDate = this.getFormattedData(this.formData['submissionDate']);
    this.maxEffectDate = this.getFormattedEndData(this.formData['submissionDate']);
    this.type = history.state.data.type;
    if (history.state.data.type !== 'New') {
      this.orgRowData = history.state.data.rowData;
      if (this.orgRowData.businessunit_id === 1) {
        this.businessName = 'EM';
      }
      else if (this.orgRowData.businessunit_id === 4) {
        this.businessName = 'ED';
      }
      else { this.businessName = 'EV'; }
      this.getCUPRequestDetails(this.orgRowData.request_id, this.orgRowData.businessunit_id);
    } else {
      this.getSoldToData();
    }
  }

  getCUPRequestDetails(requestId, businessUnitId) {
    this.spinner.show();
    this._service.getCUPRequestDetails(requestId, businessUnitId).subscribe(response => {
      this.apiResp = response;
      this.formMode = 'Edit';
      this.cupRequestDetails = JSON.parse(JSON.stringify(this.apiResp.requestSubmissionData[0]));
      this.getSoldToData();
      this.generateFormData();
      if (this.cupRequestDetails['sbu_name'] == '') {
        this.displayNewProduct = true;
        this.formData['newProductName'] = this.cupRequestDetails['product_name'];
      }
      let saleSupportUserEdit = history.state.data.saleSupportUserEdit;
      if (saleSupportUserEdit) {
        this.isSaleSupportUserEdit = true;
      } else {
        this.isSaleSupportUserEdit = false;
      }
      this.spinner.hide();
    });
  }

  getSoldToData() {
    this.spinner.show();
    this._service.getSoldTo(this.userId, this.selectedBU, this.userAdmin).subscribe(response => {
      if (!response['isError']) {
        this.soldToDD = response['response'];
        this.soldToDD.forEach(sd => {
          sd.soldToFullName = `${sd.sold_to_sap_id} - ${sd.distributor_sold_to_name}`;
        });
        console.log(this.formMode);
        if (this.formMode === 'Edit') {
          console.log(this.cupRequestDetails);
          this.selectedSoldTo = this.soldToDD.find(ss => ss.distributor_id === this.cupRequestDetails.distributor_id
            && ss.distributor_sold_to === this.cupRequestDetails.distributor_sold_to);
          console.log(this.selectedSoldTo);
          this.getDistributorDetails();
        }
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this._service.displayToastr(response);
      }
    });
  }

  getDistributorDetails() {
    this._service.getMasterData(this.userId, this.selectedSoldTo.distributor_sold_to_id, this.selectedBU, this.selectedSoldTo.distributor_id, this.userAdmin).subscribe(response => {
      this.spinner.show();
      this.masterApiResp = response;
      this.getDistributorData(this.masterApiResp);
      this.getDistributorShipToData(this.masterApiResp);
      this.getCustomersData(this.masterApiResp);
      this.getRequestMasterData(this.masterApiResp);
      this.getCompetitorsData(this.masterApiResp);
      this.getProductsData(this.masterApiResp);
      this.getDistributorUsers(this.masterApiResp);
      this.spinner.hide();
      console.log(this.selectedSoldTo);
    });
  }

  getDistributorUsers(masterJson) {
    this.distSalesRepDD = masterJson.distributorUsers;
  }

  getDistributorData(masterJson) {
    this.distributorData = masterJson.requestDistributorDetails;
    if (this.distributorData.length === 1) {
      this.selectedDistributor = this.distributorData[0];
      this.formData['distributorCity'] = this.selectedDistributor.distributor_city;
      this.formData['distributorName'] = this.selectedDistributor.distributor_name;
    }
    if (this.formMode === 'Edit') {
      if (this.selectedDistributor) {
        this.formData['distributorCity'] = this.selectedDistributor.distributor_city;
        this.formData['distributorName'] = this.selectedDistributor.distributor_name;
      }
    }
  }

  getProductsData(masterJson) {
    this.productsData = masterJson.productsData;
    let otherProduct = {
      product_id: 0,
      product_name: 'Others'
    };
    if (this.selectedBU === 1 || this.selectedBU === 4) {
      this.productsData = JSON.parse(JSON.stringify([...this.productsData, otherProduct]));
      this.productsData.sort(function (a, b) {
        if (a.product_name.toLowerCase() < b.product_name.toLowerCase())
          return -1;
        if (a.product_name.toLowerCase() > b.product_name.toLowerCase())
          return 1;
        return 0;
      });
    }
    if (this.formMode === 'Edit') {
      this.selectedProduct = this.productsData.find(p => p.product_id === this.cupRequestDetails.product_id);
      if (this.selectedProduct) {
        this.getProductDetails();
      } else {
        if (this.cupRequestDetails['sbu_name'] === '') {
          this.selectedProduct = this.productsData.find(pd => pd.product_id === 0);
          this.selectedProduct['product_id'] = this.cupRequestDetails['product_id'];
          this.getProductDetails();
        }
      }
    }
  }

  getCustomersData(masterJson) {
    this.customersDD = this.getUniqueListBy(masterJson.customerData, 'customer_name');
    const otherCustomer = {
      address: '',
      city: '',
      customer_id: 0,
      customer_name: 'Other',
      customer_region: '',
      s1_code: null,
      state: 'MO',
      zip_code: '0',
      customer_address_id: 0
    };
    this.customersDD.push(otherCustomer);
    this.customersDD.sort(function (a, b) {
      if (a.customer_name.toLowerCase() < b.customer_name.toLowerCase())
        return -1;
      if (a.customer_name.toLowerCase() > b.customer_name.toLowerCase())
        return 1;
      return 0;
    });
    if (this.formMode === 'Edit') {
      this.selectedCustomer = this.masterApiResp.customerData.find(c => c.customer_id === this.cupRequestDetails.customer_id);
      if (this.selectedCustomer) {
        this.constructStateDropDown();
        this.selectedState = this.stateDD.find(sd => sd.state === this.selectedCustomer.state);
        this.constructCityDropDown();
        if (this.cityDD) {
          this.selectedCity = this.cityDD.find(cd => cd.city === this.selectedCustomer.city);
          this.formData['zip'] = this.selectedCity.zip_code;
        }
      }
      this.selectedCustomer = this.customersDD.find(cd => cd.customer_name === this.cupRequestDetails.customer_name);
    }
  }
  getCompetitorsData(masterJson) {
    this.requestMasterCompetitorsApiResp = masterJson.requestMaster;
    this.competitorsDD = this.requestMasterCompetitorsApiResp.filter(cd => cd.master_name === 'Competitors');
    const otherCustomer = {
      request_master_id: 0,
      master_name: '',
      mater_key: '0',
      value: 'Other'
    };
    this.competitorsDD.push(otherCustomer);
    this.competitorsDD.sort(function (a, b) {
      if (a.value.toLowerCase() < b.value.toLowerCase())
        return -1;
      if (a.value.toLowerCase() > b.value.toLowerCase())
        return 1;
      return 0;
    });
    if (this.type === 'edit') {
      this.selectedCompetitor = this.competitorsDD.find(cd => cd.request_master_id === this.cupRequestDetails['competitor_id']);
    }
  }
  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  onSelectCustomer() {
    if (this.selectedCustomer.customer_id === 0) {
      this.newCustomer.customer_name = '';
      this.newCustomer.address = '';
      this.newCustomer.city = '';
      this.newCustomer.state = '';
      this.newCustomer.zip_code = '';
      this.newCustomer.customer_region = undefined;
      this.selectedRegion = undefined;
      this.displayCustomerPopUp = true;
    } else {
      this.constructStateDropDown();
      this.constructCityDropDown();
      this.formData['zip'] = '';
      this.selectedState = undefined;
      this.selectedCity = undefined;
    }
  }
  onSelectCompetitor() {
    if (this.selectedCompetitor.request_master_id === 0) {
      this.displayCompetitorPopUp = true;
    }
    // else {
    //   this.constructStateDropDown();
    // }
  }
  constructStateDropDown() {
    this.stateDD = this.masterApiResp.customerData.filter(cd => cd.customer_name === this.selectedCustomer.customer_name);
    this.stateDD = this.getUniqueListBy(this.stateDD, 'state');
  }

  constructCityDropDown() {
    if (this.selectedState && this.selectedState.state) {
      this.cityDD = this.masterApiResp.customerData.filter(sd => sd.customer_name === this.selectedCustomer.customer_name && sd.state === this.selectedState.state);
    }
  }

  addNewLocation() {
    this.newCustomer.customer_name = this.selectedCustomer.customer_name;
    this.isUpdateCustomer = true;
    this.displayCustomerPopUp = true;
  }

  getDistributorShipToData(masterJson) {
    this.shipToDD = masterJson.distributorShipToData;
    this.shipToDD.forEach(element => {
      if (!element.distributor_ship_to_Full_name || element.distributor_ship_to_Full_name.length < 1) {
        element.distributor_ship_to_Full_name = element.distributor_ship_to_name;
      }
    });
  }

  getProductDetails() {
    if (this.selectedProduct.product_id != 0 && this.type !== 'edit' && this.selectedProduct.product_name !== 'Others') {
      this.displayNewProduct = false;
      this.formData['newProductName'] = '';

      this.productDetails = this.productsData.filter(p => p.product_id === this.selectedProduct.product_id);
      if (this.productDetails[0].product_manager == 0 && this.selectedBU != 3) {
        this.toastr.error("Product Manager not available for this product, so Price Support cannot be created.Please contact BASF Sales Support");
      }
      this.formData['article'] = this.productDetails[0].article_number;
      this.formData['bulk_pkg_type'] = this.productDetails[0].package_type;
      this.formData['sbuName'] = this.productDetails[0].sbu_name;
      if (this.businessName === 'ED') {
        this.selectedAnnualUOM = this.annualUOMData.find(uom => uom.value === this.productDetails[0].list_price_UOM);
        this.formData['estimatedAnnualVolumeUOM'] = this.selectedAnnualUOM.value;
      }
      this.getProductPricingDetails();
    } else {
      if (this.type !== 'edit') {
        this.displayNewProduct = true;
      }
      if (this.type === 'New') {
        this.formData['distributor_price_from_basf'] = 0;
        this.formData['article'] = '';
        this.formData['credit_back_amount'] = 0;
        this.formData['net_support_price'] = 0;
      }
      else if (this.type === 'clone') {
        this.formData['newProductName'] = this.cupRequestDetails['product_name'];
        this.formData['distributor_price_from_basf'] = this.cupRequestDetails['distributor_price_from_basf'];
        this.formData['article'] = this.cupRequestDetails['article_number'];
        this.formData['credit_back_amount'] = this.cupRequestDetails['credit_back_amount'];
        this.formData['net_support_price'] = this.cupRequestDetails['net_support_price'];
        this.formData['sbuName'] = this.cupRequestDetails['sbu_name'];
        this.calculatePrices();
      } else if (this.type === 'edit') {
        this.formData['newProductName'] = this.cupRequestDetails['product_name'];
        this.formData['sbuName'] = this.cupRequestDetails['sbu_name'];
        this.formData['distributor_price_from_basf'] = this.cupRequestDetails['distributor_price_from_basf'];
        this.formData['credit_back_amount'] = this.cupRequestDetails['credit_back_amount'];
      } else {
        this.formData['newProductName'] = this.cupRequestDetails['product_name'];
      }
      this.getProductConfig();
    }
  }

  onChangeSBU() {
    this.formData['sbuName'] = this.selectedSBU.value;
    this.requestAPI.sbuId = this.selectedSBU.request_master_id;
  }

  onSelectPackageType(_event) {
    this.formData['bulk_pkg_type'] = this.selectedPackageType.value;
  }

  getProductConfig() {
    this._service.getAdminConfigMaster().subscribe((res: any) => {
      if (!res['isError']) {
        this.apiResp = res;
        this.sbuDD = this.apiResp.buData.filter(bu => bu.businessunit_name === sessionStorage.getItem('BU'));
        this.packageTypeDD = this.apiResp.packageType.filter(bu => bu.businessunit_name === sessionStorage.getItem('BU'));
        if (this.userAdmin) {
          this.getBUUsers();
        }
        if (this.formMode === 'Edit') {
          this.selectedPackageType = this.packageTypeDD.find(pt => pt.value === this.cupRequestDetails['bulk_package_type']);
          this.onSelectPackageType('event');
        }
      } else {
        this._service.displayToastr(res);
      }
    });
  }

  getBUUsers() {
    this._service.getBUUsers(this.selectedBU).subscribe((res) => {
      if (!res['isError']) {
        this.pmUsersDD = res['lstUsers']
      } else {
        this._service.displayToastr(res);
      }
    })
  }

  getProductPricingDetails() {
    if (this.selectedCupsIntoStock && this.selectedFreightTermstoDistributor && this.productDetails && this.formData['effectiveDate']) {
      this.spinner.show();
      if (this.selectedCupsIntoStock.value === '3rd Party' && !this.selectedShipTo) {
        this.spinner.hide();
        return false;
      }
      let ship_to_name = this.distributorData[0].sales_org_name;
      let shiptoId = this.selectedCupsIntoStock.value === '3rd Party' ? this.selectedShipTo.distributor_ship_to_id : 0;
      this._service.getProductPricingRecord(this.selectedBU, this.productDetails[0].article_number,
        this.selectedSoldTo.distributor_sold_to_id, shiptoId, this.selectedFreightTermstoDistributor.master_key,
        this.formData['effectiveDate'], ship_to_name, this.selectedCupsIntoStock.request_master_id).subscribe(response => {
          if (!response['isError']) {
            this.spinner.hide();
            this.apiResp = response;
            this.formData['distributor_price_from_basf'] = this.apiResp.response.list_Price;
            this.calculatePrices();
          }
          else {
            this.spinner.hide();
            this.formData['distributor_price_from_basf'] = '';
            this._service.displayToastr(response);
          }
        });
    } else {
      this.spinner.hide();
    }
  }

  getRequestMasterData(masterJson) {
    this.spinner.show();
    this.requestMasterApiResp = masterJson.requestMaster;
    this.annualUOMData = this.requestMasterApiResp.filter(rm => rm.master_name === 'VolumeUOM');
    if (this.businessName === 'EV') {
      this.selectedAnnualUOM = this.annualUOMData.find(uom => uom.value === 'LB');
      this.formData['estimatedAnnualVolumeUOM'] = this.selectedAnnualUOM.value;
    }
    if (this.businessName === 'EM') {
      this.selectedAnnualUOM = this.annualUOMData.find(uom => uom.value === 'KG');
      this.formData['estimatedAnnualVolumeUOM'] = this.selectedAnnualUOM.value;
    }
    this.industryOrMarketDD = this.requestMasterApiResp.filter(rm => rm.master_name === 'Industry');
    this.bulkPkgType = this.requestMasterApiResp.filter(rm => rm.master_name === 'BulkPkgType');
    this.cupsIntoStockDD = this.requestMasterApiResp.filter(rm => rm.master_name === 'CupintoStock');
    this.distributorRegionDD = this.requestMasterApiResp.filter(rm => rm.master_name === 'DistributorRegion');
    this.freightTermstoDistributorDD = this.requestMasterApiResp.filter(rm => rm.master_name === 'FreightTermstoDistributor');
    this.freightTermstoEndCustomerDD = this.requestMasterApiResp.filter(rm => rm.master_name === 'FreightTermsToEndCustomer');
    this.priceUOMTypeDD = this.requestMasterApiResp.filter(rm => rm.master_name === 'PricingUOM');

    if (this.formMode === 'Edit') {
      this.selectedFreightTermstoDistributor = this.freightTermstoDistributorDD.find(f => f.master_key === this.cupRequestDetails.frieght_terms_to_distributor_id);
      this.selectedFreightTermstoEndCustomer = this.freightTermstoEndCustomerDD.find(f => f.request_master_id === this.cupRequestDetails.freight_terms_to_end_customer_id);
      this.selectedShipTo = this.shipToDD.find(ss => ss.distributor_ship_to_id === this.cupRequestDetails.ship_to);
      this.selectedCupsIntoStock = this.cupsIntoStockDD.find(c => c.request_master_id === this.cupRequestDetails.cups_intostock_id);

      if (this.type === 'edit') {
        if (this.businessName == 'ED') {
          this.selectedDistributorRegion = this.distributorRegionDD.find(im => im.request_master_id === this.cupRequestDetails['distributor_region_id']);
        }
        this.selectedIndustryOrMarket = this.industryOrMarketDD.find(im => im.request_master_id === this.cupRequestDetails['industry_market_id']);
      }
      this.onChangeCupsIntoStock();
    }
    this.spinner.hide();
  }

  getFormattedData(date) {
    return moment(date).format("yyyy-MM-DD");
  }

  getFormattedEndData(date) {
    return moment(date).add(730, 'days').format("yyyy-MM-DD");
  }

  onChangeCupsIntoStock() {
    this.displayCreditAmount = true;
    this.customerInfoRequired = true;
    this.disableFreightTermsToDistributor = false;
    if (this.selectedCupsIntoStock.value === '3rd Party') {
      this.displayShipTo = true;
      this.displayCreditAmount = false;
    } else {
      this.displayShipTo = false;
    }
    if (this.selectedCupsIntoStock.value === 'IntoStock') {
      this.customerInfoRequired = false;
      this.displayCreditAmount = false;
    }
    if (this.selectedCupsIntoStock.value === 'CUP') {
      this.selectedFreightTermstoDistributor = this.freightTermstoDistributorDD.find(f => f.value === 'Collect');
      if (this.businessName === 'EV') {
        this.disableFreightTermsToDistributor = true;
      }
    }
    this.calculatePrices();
    this.getProductPricingDetails();
  }

  calculateEDPrice() {
    const dp = this.formData['distributor_price_from_basf'] ? Number(this.formData['distributor_price_from_basf']) : 0;
    const sp = this.formData['support_price_to_end_customer'] ? Number(this.formData['support_price_to_end_customer']) : 0;
    const psp = this.formData['prior_support_price'] ? Number(this.formData['prior_support_price']) : 0;
    if (psp && sp) {
      this.formData['delta_price'] = (psp - sp).toFixed(2);
    }
    if (dp && sp) {
      this.formData['discount_percent'] = (((dp - sp) / dp) * 100).toFixed(2);
      this.formData['credit_back_amount'] = (dp - sp).toFixed(2);
    }
    else if (dp <= 0 && this.formData['cups_intostock'] === '3rd Party' || this.formData['cups_intostock'] === 'IntoStock') {
      this.formData['discount_percent'] = 0;
      this.formData['credit_back_amount'] = 0;
    }
  }

  calculatePrices() {
    if (this.businessName == 'ED') {
      this.calculateEDPrice();
    } else {
      const sp = Number(this.formData['support_price_to_end_customer']);
      const discount = Number(this.formData['discount_percent']);
      const fa = this.formData['freight_allowance'] ? Number(this.formData['freight_allowance']) : 0;
      const oa = this.formData['other_allowances'] ? Number(this.formData['other_allowances']) : 0;
      const dp = this.formData['distributor_price_from_basf'] ? Number(this.formData['distributor_price_from_basf']) : 0;
      const nspev = this.formData['net_support_price'] ? Number(this.formData['net_support_price']) : 0;
      if (!this.displayNewProduct) {
        this.calculateForExistingProduct(sp, discount, fa, oa, dp, nspev);
      } else {
        this.calculateForNewProduct(sp, discount, fa, oa, dp);
      }
    }
  }

  calculateForNewProduct(sp, discount, fa, oa, dp) {
    if ((this.businessName === 'EM') && sp >= 0 && discount >= 0 && fa >= 0 && oa >= 0) {
      this.formData['net_support_price'] = (sp - (sp * (discount) / 100) - fa - oa).toFixed(2).toString();
      if (Number(this.formData['net_support_price']) < 0) {
        this.toastr.error("Net Support Price cannot be Negative");
      }
    }
    if ((this.businessName === 'ED') && sp >= 0 && discount >= 0 && fa >= 0 && oa >= 0) {
      ///this.formData['net_support_price'] = (sp - fa - oa).toFixed(2).toString();
      this.formData['net_support_price'] = (sp).toFixed(2).toString();
      if (Number(this.formData['net_support_price']) < 0) {
        this.toastr.error("Net Support Price cannot be Negative");
      }
    }
    const nsp = Number(this.formData['net_support_price']);
    if ((this.businessName === 'EM') && sp >= 0 && discount >= 0 && fa >= 0 && oa >= 0 && dp >= 0) {
      if (nsp && dp && (this.selectedCupsIntoStock.value === '3rd Party' || this.selectedCupsIntoStock.value === 'IntoStock')) {
        this.formData['credit_back_amount'] = 0;
      } else if (nsp && dp && this.selectedCupsIntoStock.value === 'CUP') {
        this.formData['credit_back_amount'] = (dp - nsp).toFixed(2).toString();
        if (Number(this.formData['credit_back_amount']) < 0) {
          this.toastr.error("Credit Back Amount cannot be Negative");
        }
      }
    }
    if ((this.businessName === 'ED') && sp >= 0 && discount >= 0 && fa >= 0 && oa >= 0 && dp >= 0) {
      if (nsp && dp && (this.selectedCupsIntoStock.value === '3rd Party' || this.selectedCupsIntoStock.value === 'IntoStock')) {
        this.formData['credit_back_amount'] = 0;
      } else if (nsp && dp && this.selectedCupsIntoStock.value === 'CUP') {
        this.formData['credit_back_amount'] = (dp - sp).toFixed(2).toString();
        if (Number(this.formData['credit_back_amount']) < 0) {
          this.toastr.error("Credit Back Amount cannot be Negative");
        }
      }
    }
  }

  calculateForExistingProduct(sp, discount, fa, oa, dp, nspev) {
    if (this.businessName === 'EM') {
      if (sp >= 0 && discount >= 0 && fa >= 0 && oa >= 0 && dp >= 0) {
        this.formData['net_support_price'] = (sp - (sp * (discount) / 100) - fa - oa).toFixed(2).toString();
        if (Number(this.formData['net_support_price']) < 0) {
          this.toastr.error("Net Support Price cannot be Negative");
        }
      }
      const nsp = Number(this.formData['net_support_price']);
      if (nsp && dp && (this.selectedCupsIntoStock.value === '3rd Party' || this.selectedCupsIntoStock.value === 'IntoStock')) {
        this.formData['credit_back_amount'] = 0;
      } else if (nsp && dp && this.selectedCupsIntoStock.value === 'CUP') {
        this.formData['credit_back_amount'] = (dp - nsp).toFixed(2).toString();
        if (Number(this.formData['credit_back_amount']) < 0) {
          this.toastr.error("Credit Back Amount cannot be Negative");
        }
      }
    }
    if (this.businessName === 'ED') {
      if (sp >= 0 && discount >= 0 && fa >= 0 && oa >= 0 && dp >= 0) {
        ///this.formData['net_support_price'] = (sp - fa - oa).toFixed(2).toString();
        this.formData['net_support_price'] = (sp).toFixed(2).toString();
        if (Number(this.formData['net_support_price']) < 0) {
          this.toastr.error("Net Support Price cannot be Negative");
        }
      }
      const nsped = Number(this.formData['net_support_price']);
      if (nsped && dp && (this.selectedCupsIntoStock.value === '3rd Party' || this.selectedCupsIntoStock.value === 'IntoStock')) {
        this.formData['credit_back_amount'] = 0;
      } else if (nsped && dp && this.selectedCupsIntoStock.value === 'CUP') {
        this.formData['credit_back_amount'] = (dp - sp).toFixed(2).toString();
        if (Number(this.formData['credit_back_amount']) < 0) {
          this.toastr.error("Credit Back Amount cannot be Negative");
        }
      }
    }
    if (this.businessName === 'EV') {
      if (sp >= 0 && discount >= 0 && fa >= 0 && oa >= 0 && dp >= 0) {
        ///this.formData['net_support_price'] = (sp - fa - oa).toFixed(2).toString();
        this.formData['net_support_price'] = (sp).toFixed(2).toString();
        if (nspev < 0) {
          this.toastr.error("Net Support Price cannot be Negative");
        }
      }

      if (nspev && dp && (this.selectedCupsIntoStock.value === '3rd Party' || this.selectedCupsIntoStock.value === 'IntoStock')) {
        this.formData['credit_back_amount'] = 0;
      } else if (nspev && dp && this.selectedCupsIntoStock.value === 'CUP') {
        this.formData['credit_back_amount'] = (dp - nspev).toFixed(2).toString();
        if (Number(this.formData['credit_back_amount']) < 0) {
          this.toastr.error("Credit Back Amount cannot be Negative");
        }
      }
    }
  }

  generateFormData() {
    this.spinner.show();
    this.formData['reference_id'] = this.type === 'edit' ? this.orgRowData['request_reference_number'] : "";
    this.requestAPI.request_id = this.type === 'edit' ? this.orgRowData['request_id'] : 0;
    this.formData['submissionDate'] = this.type === 'edit' ? moment(this.cupRequestDetails['submission_date']).format('MM/DD/yyyy') : moment(new Date()).format('L');
    this.formData['effectiveDate'] = this.type === 'edit' ? moment(this.cupRequestDetails['effective_date']).format('yyyy-MM-DD') : "";
    this.formData['expirationDate'] = this.type === 'edit' ? moment(this.cupRequestDetails['expiration_date']).format('yyyy-MM-DD') : "";
    this.formData['support_price_to_end_customer'] = this.cupRequestDetails['support_price_to_end_customer'];
    this.formData['prior_support_price'] = this.cupRequestDetails['prior_support_price'];
    this.formData['mileageInbound'] = this.cupRequestDetails['mileage_inbound'];
    this.formData['mileageOutbound'] = this.cupRequestDetails['mileage_outbound'];
    this.formData['discount_percent'] = this.cupRequestDetails['discount_percent'].replace('%', '');
    this.formData['freight_allowance'] = this.cupRequestDetails['frieght_allowance'];
    this.formData['other_allowances'] = this.cupRequestDetails['other_allowance'];
    this.formData['net_support_price'] = this.cupRequestDetails['net_support_price'];
    this.formData['credit_back_amount'] = this.cupRequestDetails['credit_back_amount'];
    this.formData['comments'] = this.type === 'edit' ? this.cupRequestDetails['comments'] : '';
    this.formData['externalComments'] = this.type === 'edit' ? this.cupRequestDetails['external_comments'] : '';
    this.formData['status_id'] = this.cupRequestDetails['status_id'];
    this.formData['article'] = this.cupRequestDetails['article_number'];
    if (this.formData['status_id'] === 11) {
      this.isDistributionActionRequired = true;
    }
    if (this.type === 'edit') {
      this.formData['competitive_material'] = this.cupRequestDetails['competitive_material'];
      this.formData['competitive_price'] = this.cupRequestDetails['competitive_price'];
      this.formData['competitive_application'] = this.cupRequestDetails['competitive_application'];
      this.formData['distributor'] = this.cupRequestDetails['distributor'];
      this.formData['externalComments'] = this.cupRequestDetails['external_comments'];
      this.formData['estimatedAnnualVolume'] = this.cupRequestDetails['estimated_annual_volume'];
    }
    this.spinner.hide();
  }

  bindCustomerDetails() {
    if (this.selectedCustomer != undefined) {
      let customer = this.masterApiResp.customerData.find(cd => cd.customer_name === this.selectedCustomer.customer_name);
      this.requestAPI.customer_id = customer ? customer.customer_id : 0;
      this.requestAPI.customer_state = (customer && this.selectedState) ? this.selectedState.state : '';
      this.requestAPI.customer_city = (customer && this.selectedCity) ? this.selectedCity.city : '';

    } else {
      this.requestAPI.customer_id = 0;
      this.requestAPI.customer_city = '';
      this.requestAPI.customer_state = ''
    }
  }

  submitRequest() {
    this.spinner.show();
    if (this.checkValidations()) {
      if (this.businessName === 'EM') {
        this.generateEMRequest();
      }
      else if (this.businessName === 'ED') {
        this.generateEDRequest();
      }
      else {
        this.generateEVRequest();
      }
      if (this.productDetails && this.productDetails[0].product_manager == 0 && this.selectedBU != 3) {
        this.toastr.error("Product Manager not available for this product, so Price Support cannot be created.Please contact BASF Sales Support");
        return;
      }
      this.requestAPI['submission_date'] = moment(this.formData['submissionDate']).toDate().toDateString();
      this.requestAPI['effective_date'] = moment(this.formData['effectiveDate']).toDate().toDateString();
      this.requestAPI['expiration_date'] = moment(this.formData['expirationDate']).toDate().toDateString();
      this.requestAPI.distributor_id = this.selectedDistributor.distributor_id;
      this.requestAPI.product_id = this.selectedProduct.product_id;
      this.requestAPI.article_number = this.formData['article'];
      if (this.type == 'edit') {
        if (this.formData['estimatedAnnualVolume'].toString().indexOf(' ') >= 0) {
          this.formData['estimatedAnnualVolume'] = this.formData['estimatedAnnualVolume'].split(' ')[0].replace(',', '');
        }
      }
      this.requestAPI.estimated_annual_volume = Number(this.formData['estimatedAnnualVolume']);
      this.requestAPI.estimated_annual_volume_UOM_id = this.selectedAnnualUOM.request_master_id;
      this.requestAPI.bulk_package_type = this.formData['bulk_pkg_type'];
      this.requestAPI.cups_intostock_id = this.selectedCupsIntoStock.request_master_id;
      this.requestAPI.frieght_terms_to_distributor_id = this.selectedFreightTermstoDistributor.master_key;
      this.bindCustomerDetails();

      this.requestAPI.distributor_price_from_basf = this.formData['distributor_price_from_basf'] ? Number(this.formData['distributor_price_from_basf']) : 0;
      this.requestAPI.support_price_to_end_customer = Number(this.formData['support_price_to_end_customer']);
      this.requestAPI.net_support_price = this.formData['net_support_price'] ? Number(this.formData['net_support_price']) : 0;
      this.requestAPI.credit_back_amount = this.formData['credit_back_amount'] ? Number(this.formData['credit_back_amount']) : 0;
      this.requestAPI.distributor = this.formData['distributor'];
      this.requestAPI.competitor = this.selectedCompetitor.request_master_id;
      this.requestAPI.competitive_material = this.formData['competitive_material'];
      this.requestAPI.competitive_price = this.formData['competitive_price'] ? Number(this.formData['competitive_price']) : 0;
      this.requestAPI.industry_market_id = this.selectedIndustryOrMarket.request_master_id;
      this.requestAPI.status_id = 2;
      this.requestAPI.created_by = sessionStorage.getItem('userid');
      this.requestAPI.discount_percent_id = 4;
      this.requestAPI.ship_to = this.selectedCupsIntoStock.value === '3rd Party' ? this.selectedShipTo.distributor_ship_to_id : 0;
      this.requestAPI.distributor_sales_rep_id = this.selectedDistSalesRep ? this.selectedDistSalesRep.distributor_user_id : 0;
      this.constructComments();
      this.requestAPI.is_internal_user = !this.isDistributor;
      if (this.isSaleSupportUserEdit) {
        this.saleSupportUserApproval();
        this.requestAPI.saleSupportUserUpdate = true;
        this.requestAPI.created_by = history.state.data.rowData.created_by;
        this.requestAPI.is_internal_user = history.state.data.rowData.is_internal_user;
      }
      this.callSubmitAPI();
    } else {
      this.spinner.hide();
    }
  }

  saleSupportUserApproval() {
    this.spinner.show();
    let workFlowResp = history.state.data.workFlowResp;
    this._service.updateWorkFLowStatus(workFlowResp).subscribe((response) => {
      this.spinner.hide();
    })
  }

  constructComments() {
    let currentDate = moment().format('MM/DD/yyyy');
    if (this.formData['comments'] != '') {
      this.requestAPI.comments = this.formData['comments'];
    } else {
      this.requestAPI.comments = '';
    }
    if (this.formData['externalComments'] != '') {
      this.requestAPI.external_comments = `${currentDate}: ${this.formData['externalComments']} - by ${sessionStorage.getItem('loginuser')} ||`;
    } else {
      this.requestAPI.external_comments = '';
    }
  }

  callSubmitAPI() {
    this._service.submitRequest(this.requestAPI).subscribe((_response: any) => {
      if (!_response.isError) {
        this.spinner.hide();
        this._service.displayToastr(_response);
        //this.toastr.success('Request submitted successfully', 'Success!')
        sessionStorage.setItem('paramsExist', 'false');
        this.router.navigate(['/home']);
      }
      else {
        this.spinner.hide();
        this._service.displayToastr(_response);
      }
    });
  }

  generateEMRequest() {
    this.requestAPI.discount_percentage = Number(this.formData['discount_percent']);
    this.requestAPI.frieght_allowance = this.formData['freight_allowance'] ? Number(this.formData['freight_allowance']) : 0;
    this.requestAPI.other_allowance = this.formData['other_allowances'] ? Number(this.formData['other_allowances']) : 0;
    this.requestAPI.mileage_inbound = this.formData['mileageInbound'];
    this.requestAPI.mileage_outbound = this.formData['mileageOutbound'];
    this.requestAPI.freight_terms_to_end_customer_id = this.selectedFreightTermstoEndCustomer.request_master_id;
    this.requestAPI.competitive_application = this.formData['competitive_application'];
    this.requestAPI.businessunit_id = this.EMID;
    if (this.displayNewProduct && !this.selectedDistSalesRep) {
      this.requestAPI.product_name = this.formData['newProductName'];
      this.requestAPI.product_manager = 0;
    }
    if (this.displayNewProduct && (this.type === 'edit' || this.type === 'New')) {
      this.requestAPI.product_name = this.formData['newProductName'];
      this.requestAPI.product_manager = this.selectedProductManager ? this.selectedProductManager.user_id : 0;
      this.requestAPI.article_number = this.formData['article'];
      this.requestAPI.sbuId = this.selectedSBU ? this.selectedSBU.request_master_id : 0;
    }
  }

  generateEVRequest() {
    this.requestAPI.discount_percentage = 0;
    this.requestAPI.frieght_allowance = 0;
    this.requestAPI.other_allowance = 0;
    this.requestAPI.freight_terms_to_end_customer_id = 0;
    this.requestAPI.mileage_inbound = "";
    this.requestAPI.mileage_outbound = "";
    this.requestAPI.businessunit_id = this.EVID;
    this.requestAPI.competitive_application = '';
  }

  generateEDRequest() {
    this.requestAPI.distributor_region_id = this.selectedDistributorRegion.request_master_id;
    this.requestAPI.discount_percentage = Number(this.formData['discount_percent']);
    this.requestAPI.frieght_allowance = this.formData['freight_allowance'] ? Number(this.formData['freight_allowance']) : 0;
    this.requestAPI.prior_support_price = this.formData['prior_support_price'] ? Number(this.formData['prior_support_price']) : 0;
    this.requestAPI.delta_price = this.formData['delta_price'] ? Number(this.formData['delta_price']) : 0;
    this.requestAPI.other_allowance = this.formData['other_allowances'] ? Number(this.formData['other_allowances']) : 0;
    this.requestAPI.mileage_inbound = this.formData['mileageInbound'];
    this.requestAPI.mileage_outbound = this.formData['mileageOutbound'];
    this.requestAPI.freight_terms_to_end_customer_id = 0;
    this.requestAPI.competitive_application = '';
    this.requestAPI.businessunit_id = this.EDID;
    if (this.displayNewProduct && !this.selectedDistSalesRep) {
      this.requestAPI.product_name = this.formData['newProductName'];
      this.requestAPI.product_manager = 0;
    }
    if (this.displayNewProduct && (this.type === 'edit' || this.type === 'New')) {
      this.requestAPI.product_name = this.formData['newProductName'];
      this.requestAPI.product_manager = this.selectedProductManager ? this.selectedProductManager.user_id : 0;
      this.requestAPI.article_number = this.formData['article'];
      this.requestAPI.sbuId = this.selectedSBU ? this.selectedSBU.request_master_id : 0;
    }
  }

  cancel() {
    this.router.navigate(['/home']);
  }

  openCustomerPopUp() {
    this.displayCustomerPopUp = true;
  }

  cancelCustomerPopUp() {
    this.displayCustomerPopUp = false;
    this.isUpdateCustomer = false;

  }
  cancelCompetitorPopUp() {
    this.displayCompetitorPopUp = false;
    this.isUpdateCompetitor = false;

  }

  addCustomer() {
    this.spinner.show();
    this.newCustomer.created_by = sessionStorage.getItem('userid');
    this.newCustomer.businessunit_id = this.selectedBU;
    if (this.selectedRegion) {
      this.newCustomer.customer_region = this.selectedRegion.region_id;
    }
    this.newCustomer.distributor_sold_to_id = this.selectedSoldTo.distributor_sold_to_id;
    if (this.checkCustomerValidation()) {
      this._service.createCustomer(this.newCustomer).subscribe(_response => {
        if (!_response['isError']) {
          this._service.getMasterData(this.userId, this.selectedSoldTo.distributor_sold_to_id, this.selectedBU, this.selectedSoldTo.distributor_id, this.userAdmin).subscribe(response1 => {
            this.masterApiResp = response1;
            this.getCustomersData(response1);
            this.selectedCustomer = this.masterApiResp.customerData.find(c => c.customer_name === this.newCustomer.customer_name && c.state === this.newCustomer.state && c.city === this.newCustomer.city);
            this.constructStateDropDown();
            this.selectedState = this.stateDD.find(sd => sd.state === this.selectedCustomer.state);
            this.constructCityDropDown();
            this.selectedCity = this.cityDD.find(cd => cd.city === this.selectedCustomer.city);
            this.formData['zip'] = this.selectedCity.zip_code;
            this.displayCustomerPopUp = false;
            this.selectedCustomer = this.customersDD.find(cdd => cdd.customer_name === this.newCustomer.customer_name);
            this.newCustomer.customer_name = '';
            this.newCustomer.address = '';
            this.newCustomer.city = '';
            this.newCustomer.state = '';
            this.newCustomer.zip_code = '';
            this.spinner.hide();
            this.toastr.success("Customer Added and selected", "Success!");
          });
        } else {
          this._service.displayToastr(_response);
        }
      })
    } else {
      this.spinner.hide();
    }
  }
  addCompetitor() {
    this.spinner.show();
    this.newCompetitor.created_by = sessionStorage.getItem('userid');
    this.newCompetitor.businessunit_id = this.selectedBU;
    if (this.checkCompetitorValidation()) {
      this._service.createCompetitor(this.newCompetitor).subscribe(_response => {
        if (!_response['isError']) {
          this._service.getMasterData(this.userId, this.selectedSoldTo.distributor_sold_to_id, this.selectedBU, this.selectedSoldTo.distributor_id, this.userAdmin).subscribe(response1 => {
            this.masterApiResp = response1;
            this.getCompetitorsData(response1);
            this.selectedCompetitor = this.masterApiResp.requestMaster.find(c => c.value === this.newCompetitor.value);
            this.displayCompetitorPopUp = false;
            this.selectedCompetitor = this.competitorsDD.find(cdd => cdd.value === this.newCompetitor.value);
            this.newCompetitor.value = '';
            this.spinner.hide();
            this.toastr.success("Competitor Added and selected", "Success!");
          });
        } else {
          this._service.displayToastr(_response);
        }
      })
    } else {
      this.spinner.hide();
    }
  }

  checkCustomerValidation() {
    let validCustomerName = this.checkValidCustomerName();
    let validCustomerCity = this.checkValidCustomerCity();
    let validCustomerState = this.checkValidCustomerState();
    let validCustomerZipcode = this.checkValidCustomerZipcode();
    let validCustomerRegion = this.checkValidCustomerRegion();
    let validExistingCustomer = this.checkExistingCustomerLocation();
    return validCustomerName && validCustomerCity && validCustomerState && validCustomerZipcode && validCustomerRegion && validExistingCustomer;
  }

  checkCompetitorValidation() {
    return this.checkValidCompetitorValue();
  }

  checkExistingCustomerLocation() {
    if (this.isUpdateCustomer) {
      let fCustomer = this.masterApiResp.customerData.filter(cd => cd.customer_name === this.newCustomer.customer_name && cd.state === this.newCustomer.state && cd.city === this.newCustomer.city);
      if (fCustomer.length > 0) {
        this.toastr.error("Customer location already exists with combination of State and City");
        return false
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  checkNewProductValidation() {
    if (!this.formData['newProductName']) {
      this.toastr.error("New Product Name Required");
      return false;
    }
    if (!this.selectedPackageType) {
      this.toastr.error("Package Type Required");
      return false;
    }
    if (!this.selectedSBU || this.selectedSBU.request_master_id == 0) {
      this.toastr.error("SBU required");
      return false;
    }
    if (!this.selectedProductManager || this.selectedProductManager.user_id == 0) {
      this.toastr.error("Product Manager required");
      return false;
    }
    return true;
  }

  checkAdminProductValidation() {
    if (!this.selectedSBU || this.selectedSBU.request_master_id == 0) {
      this.toastr.error("SBU required");
      return false;
    }
    if (!this.selectedProductManager || this.selectedProductManager.user_id == 0) {
      this.toastr.error("Product Manager required");
      return false;
    }
    if (!this.formData['distributor_price_from_basf'] && this.formData['distributor_price_from_basf'] > 0 && this.type === 'edit') {
      this.toastr.error("Distributor Price required and should be greater than of Support Price to End Customer");
      return false;
    }
    return true;
  }

  checkValidCustomerName() {
    if (this.newCustomer.customer_name != "") {
      if (this.customersDD.filter(customer => customer.customer_name === this.newCustomer.customer_name).length && !this.isUpdateCustomer) {
        this.toastr.error("Customer Name already exists", "Error!");
        return false;
      } else {
        return true;
      }
    } else {
      this.toastr.error("Customer Name required", "Error!");
      return false;
    }
  }

  checkValidCompetitorValue() {
    if (this.newCompetitor.value != "") {
      if (this.competitorsDD.filter(competitor => competitor.value === this.newCompetitor.value).length && !this.isUpdateCompetitor) {
        this.toastr.error("Customer Name already exists", "Error!");
        return false;
      } else {
        return true;
      }
    } else {
      this.toastr.error("Customer Name required", "Error!");
      return false;
    }
  }

  checkValidCustomerAddress() {
    if (this.newCustomer.address != "") {
      return true;
    } else {
      this.toastr.error("Customer Address required", "Error!");
      return false;
    }
  }

  checkValidCustomerCity() {
    if (this.newCustomer.city != "") {
      return true;
    } else {
      this.toastr.error("Customer City required", "Error!");
      return false;
    }
  }

  checkValidCustomerState() {
    if (this.newCustomer.state != "") {
      return true;
    } else {
      this.toastr.error("Customer State required", "Error!");
      return false;
    }
  }

  checkValidCustomerZipcode() {
    if (this.newCustomer.zip_code != "" || this.businessName === 'ED') {
      return true;
    } else {
      this.toastr.error("Customer Zipcode required", "Error!");
      return false;
    }
  }

  checkValidCustomerRegion() {
    if (this.newCustomer.customer_region != "") {
      return true;
    } else {
      this.toastr.error("Customer Region required", "Error!");
      return false;
    }
  }

  checkValidations() {
    if (!this.checkDropDownValidations()) {
      return false
    }
    if (!this.checkInputTextValidations()) {
      return false
    }
    if ((this.businessName !== 'EM' && this.businessName !== 'ED') && !this.formData['net_support_price']) {
      this.toastr.error("Net Support Price($) Required");
      return false;
    }
    if ((this.businessName === 'EM' || this.businessName === 'ED') && !this.checkEMValidations()) {
      return false;
    }
    if ((this.businessName === 'EM' || this.businessName === 'ED') && this.displayNewProduct && !this.checkNewProductValidation()) {
      return false;
    }
    if ((this.businessName === 'EM' || this.businessName === 'ED') && this.displayNewProduct && (this.type === 'edit' || this.type === 'New') && this.formData['article'] === this.formData['reference_id'] && !this.checkAdminProductValidation()) {
      return false;
    }
    this.assignEmptyValues();
    return true;
  }

  checkInputTextValidations() {
    if (!this.formData['effectiveDate']) {
      this.toastr.error("Effective Date Required");
      return false;
    }
    if (!this.formData['expirationDate']) {
      this.toastr.error("Expiration Date Required");
      return false;
    }
    if (this.businessName == 'ED' && !this.selectedDistributorRegion && !this.selectedDistributorRegion.request_master_id) {
      this.toastr.error("Distributor region Required");
      return false;
    }
    if (!this.formData['estimatedAnnualVolume']) {
      this.toastr.error("Est. Annual Volume value Required");
      return false;
    }
    if (!this.formData['support_price_to_end_customer']) {
      this.toastr.error(this.businessName != 'ED' ? "Support Price to End Customer Required" : "Support Price Required");
      return false;
    }
    if (!this.formData['competitive_material']) {
      this.toastr.error("Competitive Material Required");
      return false;
    }
    if (this.businessName != 'ED' && !this.formData['competitive_price']) {
      this.toastr.error("Competitive Price Required");
      return false;
    }
    return this.checkValidPrices();
  }

  checkValidPrices() {
    if (Number(this.formData['support_price_to_end_customer']) < 0) {
      this.toastr.error(this.businessName != 'ED' ? "Support Price to End Customer Required cannot be negative" : "Support Price Required cannot be negative");
      return false;
    }
    if (this.businessName != 'ED' && Number(this.formData['competitive_price']) < 0) {
      this.toastr.error("Competitor Price Required cannot be negative");
      return false;
    }
    if (Number(this.formData['net_support_price']) < 0) {
      this.toastr.error("Net Support Price cannot be Negative");
    }
    if (Number(this.formData['credit_back_amount']) !== 0 && Number(this.formData['credit_back_amount']) < 0) {
      this.toastr.error("Credit Back Amount cannot be Negative");
    }
    return true;
  }

  assignEmptyValues() {
    if (!this.formData['distributor']) {
      this.formData['distributor'] = '';
    }
    if (!this.formData['comments']) {
      this.formData['comments'] = '';
    }
    if (!this.formData['externalComments']) {
      this.formData['externalComments'] = '';
    }
  }

  checkDropDownValidations() {
    if (!this.selectedDistributor) {
      this.toastr.error("Sold To Required");
      return false;
    }
    if (!this.selectedCupsIntoStock) {
      this.toastr.error("CUP / Into Stock / 3rd Party Required");
      return false;
    }
    if (this.selectedCupsIntoStock.value === '3rd Party' && !this.selectedShipTo) {
      this.toastr.error("Ship To Required");
      return false;
    }
    if (this.businessName === 'EV' && this.displayDistributorSalesRep && !this.selectedDistSalesRep && this.type !== 'edit') {
      this.toastr.error("Distributor Sales Rep Required");
      return false;
    }
    if (!this.selectedProduct) {
      this.toastr.error("Product Name Required");
      return false;
    }
    if (this.businessName != 'ED' && !this.selectedFreightTermstoDistributor) {
      this.toastr.error("Freight Terms to Distributor Required");
      return false;
    }
    if (this.selectedCupsIntoStock.value !== 'IntoStock' && !this.selectedCustomer) {
      this.toastr.error("Customer Name Required");
      return false;
    }
    if (this.selectedCustomer) {
      if (this.selectedCustomer['customer_id'] === 0) {
        this.toastr.error("Customer Details not provided while selected other");
        return false;
      }
    }
    if (!this.selectedCompetitor) {
      this.toastr.error("Competitor Name Required");
      return false;
    }
    if (!this.selectedIndustryOrMarket) {
      this.toastr.error("Industry or Market Required");
      return false;
    }
    if (!this.selectedDistributorRegion && this.businessName == 'ED') {
      this.toastr.error("Distributor Region Required");
      return false;
    }
    return true;
  }


  checkEMValidations() {
    if (!this.formData['mileageInbound']) {
      this.formData['mileageInbound'] = '';
    }
    if (!this.formData['mileageOutbound']) {
      this.formData['mileageOutbound'] = '';
    }
    if (!this.formData['discount_percent'] && !this.displayNewProduct) {
      this.toastr.error("Discount % Required");
      return false;
    } else if (!this.formData['discount_percent']) {
      this.formData['discount_percent'] = 0;
    }
    if (this.formData['freight_allowance'] === undefined) {
      this.formData['freight_allowance'] = 0.00;
    }
    if (this.formData['other_allowances'] === undefined) {
      this.formData['other_allowances'] = 0.00;
    }
    if (this.businessName != 'ED' && !this.selectedFreightTermstoEndCustomer) {
      this.toastr.error("Freight Terms to End Customer Required");
      return false;
    }
    if (!this.formData['competitive_application']) {
      this.toastr.error("Competitive Application Required");
      return false;
    }
    return true;
  }

  populateExpiryDate(date) {
    this.minExpireDate = this.getFormattedData(this.formData['effectiveDate']);
    if (this.businessName == 'EM' || this.businessName == 'ED') {
      let datevar = moment(date).add(365, 'days').format('L');
      this.formData['expirationDate'] = moment(datevar).clone().endOf('month').format('yyyy-MM-DD');
    }
    else {
      let datevar = moment(date).add(365, 'days').format('L');
      this.formData['expirationDate'] = moment(datevar).format('yyyy-MM-DD');
    }
  }
}
