import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CupsService } from 'src/services/cups.service';

@Component({
  selector: 'app-rebate-info',
  templateUrl: './rebate-info.component.html',
  styleUrls: ['./rebate-info.component.css']
})
export class RebateInfoComponent implements OnInit {
  labelData: any;
  orgRowData: any;
  formData: any;
  labelCount = 0;
  leftColumns: any;
  rightColumns: any;
  halfWayIndex: any;
  bu: any;

  constructor(private router: Router, private spinner: NgxSpinnerService, private _service: CupsService) { }

  ngOnInit() {
    if (!history.state.data) {
      this.router.navigate(['/']);
    } else {
      this.bu = sessionStorage.getItem('BU');
      this.labelData = history.state.data.labelData;
      if(this.bu === 'EM' || this.bu === 'ED') {
        this.labelData.forEach(ld => {
          if(ld.field === 'value' && history.state.data.rowData['status']!== 1) {
            ld.is_grid_display = true;
          }
          if(ld.field === 'admincomments' && history.state.data.rowData['status']=== 1) {
            ld.is_grid_display = false;
          }
        })
      }

      this.labelData = this.labelData.filter(col => col.is_grid_display);
      this.labelData = this.labelData.sort((a, b) => {
        return a.serial_no - b.serial_no;
      });
      this.orgRowData = history.state.data.rowData;
      this.labelCount = this.labelData.length;
      this.halfWayIndex = Math.ceil(this.labelData.length / 2);
      this.leftColumns = this.labelData.slice(0, this.halfWayIndex)
      this.rightColumns = this.labelData.slice(this.halfWayIndex);
      this.formData = JSON.parse(JSON.stringify(this.orgRowData));
    }
  }

  goBack() {
    this.router.navigate(['/rebates']);
  }

}
