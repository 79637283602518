import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CupsService } from 'src/services/cups.service';
@Component({
  selector: 'app-endmarket-sales-report',
  templateUrl: './endmarket-sales-report.component.html',
  styleUrls: ['./endmarket-sales-report.component.css']
})
export class EndmarketSalesReportComponent implements OnInit {
  fileName = "";
  fileType = "";
  filesize = "";
  fileBuffer: any;
  fileObject: any;
  fileUpload = false;
  uploadedFiles: any[] = [];
  displayUpload = false;
  cols = [];
  response: any;
  reportData = [];
  constructor(private _service: CupsService, private spinner: NgxSpinnerService, private toastr: ToastrService) {}
  ngOnInit() {
    this.cols = [
      {
        "header_id": 1,
        "field": "customer",
        "header": "Customer",
        "is_show": true,
        "is_editable": false,
        "serial_no": 1,
        "isHyperlink": false
      },
      {
        "header_id": 2,
        "field": "product",
        "header": "Product",
        "is_show": true,
        "is_editable": false,
        "serial_no": 2,
        "isHyperlink": false,
      },
      {
        "header_id": 3,
        "field": "distributor",
        "header": "Distributor",
        "is_show": true,
        "is_editable": false,
        "serial_no": 3,
        "isHyperlink": false,
      },
      {
        "header_id": 4,
        "field": "division",
        "header": "Division",
        "is_show": true,
        "is_editable": false,
        "serial_no": 4,
        "isHyperlink": false
      },
      {
        "header_id": 5,
        "field": "ship_to",
        "header": "Ship To",
        "is_show": true,
        "is_editable": false,
        "serial_no": 5,
        "isHyperlink": false
      },
      {
        "header_id": 6,
        "field": "sbu",
        "header": "SBU",
        "is_show": true,
        "is_editable": false,
        "serial_no": 6,
        "isHyperlink": false
      },
      {
        "header_id": 7,
        "field": "industry",
        "header": "Industry",
        "is_show": true,
        "is_editable": false,
        "serial_no": 7,
        "isHyperlink": false
      },
      {
        "header_id": 8,
        "field": "volume_cy_lbs",
        "header": "Volume CY (lbs)",
        "is_show": true,
        "is_editable": false,
        "serial_no": 8,
        "isHyperlink": false
      },
      {
        "header_id": 9,
        "field": "volume_py_lbs",
        "header": "Volume PY (lbs)",
        "is_show": true,
        "is_editable": false,
        "serial_no": 9,
        "isHyperlink": false
      },
      {
        "header_id": 10,
        "field": "volume_cy_mts",
        "header": "Volume CY (mts)",
        "is_show": true,
        "is_editable": false,
        "serial_no": 10,
        "isHyperlink": false
      },
      {
        "header_id": 11,
        "field": "volume_py_mts",
        "header": "Volume PY (mts)",
        "is_show": true,
        "is_editable": false,
        "serial_no": 11,
        "isHyperlink": false
      },
      {
        "header_id": 12,
        "field": "volume_cy_kgs",
        "header": "Volume CY (kgs)",
        "is_show": true,
        "is_editable": false,
        "serial_no": 12,
        "isHyperlink": false
      },
      {
        "header_id": 13,
        "field": "volume_py_kgs",
        "header": "Volume PY (kgs)",
        "is_show": true,
        "is_editable": false,
        "serial_no": 13,
        "isHyperlink": false
      },
    ];
    this.getEndMarketSaleReport();
  }

  getEndMarketSaleReport() {
    this.spinner.show();
    this._service.getEndMarketSaleReport().subscribe(res => {
      this.spinner.hide();
      if (!res['isError']) {
        this.response = res;
        this.reportData = this.response.reportData;
      } else {
        this._service.displayToastr(res);
      }
    })
  }

  uploadReport() {
    this.displayUpload = true;
  }

  myUploader(event) {
    this.spinner.show();
    let fileData = event[0];
    const element = event[0];
    this.filesize = "0";
    this.filesize = element.size;
    this.filesize = this.filesize.toString();
    if (element.size > 3145728) {
      this.spinner.hide();
      this.toastr.warning("File Limit Exceeded, Allows only below 3MB data");
      return false;
    } else {
      this.fileName = fileData.name;
      this.fileType = fileData.type;
      this.fileBuffer = "";
      let fileReader = new FileReader();
      fileReader.onload = (_e) => {
        this.fileBuffer = fileReader.result;
        this.fileBuffer = this.fileBuffer.replace(`data:${this.fileType};base64,`, '');
        this.fileUpload = true;
        this.fileObject = {
          "businessUnitId": 1,
          "fileName": this.fileName,
          "fileType": this.fileType,
          "fileSize": this.filesize,
          "fileAsBase64": this.fileBuffer,
          "created_by": Number(sessionStorage.getItem('userid')),
          "salesSupportDistributor": Number(sessionStorage.getItem('SalesSupportDistributor'))
        };
      }
      fileReader.readAsDataURL(fileData);
    }
    this.uploadedFiles.push(event[0]);
    this.spinner.hide();
  }

  uploadSubmit(_fileUpload) {
    this.spinner.show();
    this._service.UploadEndMarketSaleReport(this.fileObject).subscribe(response => {
      this.afterAPIResponse(response);
    });
  }

  afterAPIResponse(response) {
    if (!response['isError']) {
      if (response.ErrorMessage && response.ErrorMessage.includes("Please Upload")) { this.toastr.error(response.ErrorMessage, 'Error!') }
      else {
        this.toastr.success(response.responseMessage, 'Success!');
      }
      this.removeFile();
      this.displayUpload = false;
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this._service.displayToastr(response);
    }
  }

  removeFile() {
    this.fileName = "";
    this.fileType = "";
    this.filesize = "";
    this.fileBuffer = ""
    this.fileObject = null;
  }
}
