import { Component, EventEmitter, Input, OnInit, Output,ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CupsService } from 'src/services/cups.service';

@Component({
  selector: 'app-rebate-shipped-form',
  templateUrl: './rebate-shipped-form.component.html',
  styleUrls: ['./rebate-shipped-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RebateShippedFormComponent implements OnInit {
  @Input() rebatesData: any;
  @Input() rebatesCols: any;
  @Input() funcType: any;
  @Output() sendUpdatesRebates = new EventEmitter();
  selectedRow: any;
  filteredCols = [];
  priceData: any;
  distributor_supported_price = 0;
  basf_invoice_price = 0;
  price_to_end_user = 0;
  price_diff = 0;
  bu: any;
  isDistributor: any;
  apiResp: any;
  cols: any;
  orgSelectedMassRows: any;
  constructor(private spinner:NgxSpinnerService, private toastr: ToastrService, private service: CupsService) { }

  ngOnInit() {
    this.orgSelectedMassRows = JSON.parse(JSON.stringify(this.rebatesData));
    this.bu = sessionStorage.getItem('BU');
    this.isDistributor = ( sessionStorage.getItem('isDistributor') === "1" || sessionStorage.getItem('isDistributor') === "true") ? true : false;
  }
  generatePricingData(date) {
    let foundPrices: any[]=[];
    this.priceData.forEach(pd => {
      let currentDate = new Date(pd.effective_date_of_change);
      let shippedDate = new Date(date);
      if (shippedDate.getTime() > currentDate.getTime()) {
        foundPrices.push(pd);
      }
    });
    return foundPrices;
  }
  genrebateData(foundPrices:any[]) {
    if (foundPrices.length) {
      this.assignEMPrices(foundPrices);
    }
    else {
      this.distributor_supported_price = this.selectedRow['distributor_supported_price'];
      this.price_to_end_user = this.selectedRow['price_to_end_user'];
      this.basf_invoice_price = this.selectedRow['basf_invoice_price'];
      this.price_diff = Number(this.selectedRow['basf_invoice_price']) - Number(this.selectedRow['distributor_supported_price']);
    }
    this.rebatesData.forEach(rd => {
      if (rd.request_id === this.selectedRow.request_id) {
        rd.distributor_supported_price = this.distributor_supported_price;
        rd.basf_invoice_price = this.basf_invoice_price;
        rd.price_to_end_user = this.price_to_end_user;
        rd.price_diff = (rd.basf_invoice_price - this.distributor_supported_price).toFixed(2);
      }
    })
    this.sendUpdatesRebates.emit(this.rebatesData);
  }
  getDistributorPrice(date: any) {
    let foundPrices:any[]=[];
    if (this.priceData.length) {
      foundPrices = this.generatePricingData(date);
      if (this.funcType === 'Rebate Template') {
        this.genrebateData(foundPrices);
      } else {
        foundPrices = foundPrices.filter(fp => moment(date).format("MM/DD/yyyy") !== moment(fp['effective_date_of_change']).format("MM/DD/yyyy"));
        this.assignEM_MC_values(foundPrices, this.selectedRow);
        this.sendUpdatesRebates.emit(this.rebatesData);
      }
    } else {
      if (this.funcType === 'Rebate Template') {
        this.distributor_supported_price = this.selectedRow['distributor_supported_price'];
        this.price_to_end_user = this.selectedRow['price_to_end_user'];
        this.rebatesData.forEach(rd => {
          if (rd.request_id === this.selectedRow.request_id) {
            rd.distributor_supported_price = this.distributor_supported_price;
            rd.basf_invoice_price = this.selectedRow['basf_invoice_price'];
            rd.price_to_end_user = this.price_to_end_user;
            rd.price_diff = (rd.basf_invoice_price - this.distributor_supported_price).toFixed(2);
          }
        })
      } else {
        this.assignEM_MC_INIT_Values(this.selectedRow);
      }
      this.sendUpdatesRebates.emit(this.rebatesData);
    }
  }

  getFormattedDate(date) {
    return moment(date).format("yyyy-MM-DD");
  }

  assignEMPrices(foundPrices: any[]) {
    let minDate = foundPrices.reduce((a, b) => new Date(a.effective_date_of_change) > new Date(b.effective_date_of_change) ? a : b)
    this.basf_invoice_price = minDate['distributor_price_from_basf'];
    this.distributor_supported_price = minDate['net_support_price'];
    this.price_to_end_user = minDate['support_price_to_end_customer'];
  }

  assignEM_MC_values(foundPrices, rowData) {
    if (foundPrices.length) {
      let minDate = foundPrices.reduce((a, b) => new Date(a.created_date) > new Date(b.created_date) ? a : b)
      this.rebatesData.forEach(smr => {
        if (smr.cupReqId === rowData.cupReqId) {
          smr['distributor_price_from_basf'] = minDate['distributor_price_from_basf'];
          smr['distributor_price_from_basf_MC'] = minDate['distributor_price_from_basf'];
          smr['support_price_to_end_customer'] = minDate['support_price_to_end_customer'];
          smr['support_price_to_end_customer_MC'] = minDate['support_price_to_end_customer'];
          smr['net_support_price'] = minDate['net_support_price']
          smr['net_support_price_MC'] = minDate['net_support_price'];
          smr['credit_back_amount'] = minDate['credit_back_amount']
          smr['credit_back_amount_MC'] = minDate['credit_back_amount'];
          this.calculateEMEVPrices(smr);
        }
      });
    } 
    else {
      this.assignEM_MC_INIT_Values(rowData);
    }
  }

  assignEM_MC_INIT_Values(rowData) {
    this.rebatesData.forEach(smr => {
      if (smr.cupReqId === rowData.cupReqId) {
        let fRequest = this.orgSelectedMassRows.find(orm => orm.cupReqId === rowData.cupReqId);
        smr['distributor_price_from_basf'] = fRequest['distributor_price_from_basf'];
        smr['distributor_price_from_basf_MC'] = fRequest['distributor_price_from_basf'];
        smr['support_price_to_end_customer'] = fRequest['support_price_to_end_customer'];
        smr['support_price_to_end_customer_MC'] = fRequest['support_price_to_end_customer'];
        smr['net_support_price'] = fRequest['net_support_price']
        smr['net_support_price_MC'] = fRequest['net_support_price'];
        smr['credit_back_amount'] = fRequest['credit_back_amount']
        smr['credit_back_amount_MC'] = fRequest['credit_back_amount'];
        this.calculateEMEVPrices(smr);
      }
    });
  }

  calculateEMEVPrices(rowData) {
    
      const sp = Number(rowData['support_price_to_end_customer_MC']);
      const discount = Number(rowData['discount_percent'].replace('%', ''));
      const fa = rowData['frieght_allowance'] ? Number(rowData['frieght_allowance']) : 0;
      const oa = rowData['other_allowance'] ? Number(rowData['other_allowance']) : 0;
      
      const dp_mc = Number(rowData['distributor_price_from_basf_MC']);
      
        rowData['net_support_price_MC'] = (sp - (sp * (discount) / 100) - fa - oa).toFixed(2).toString();
        rowData['credit_back_amount_MC'] = (dp_mc - Number(rowData['net_support_price_MC'])).toFixed(2).toString();
     
     
     
      this.rebatesData.forEach(sm => {
        if (sm.request_reference_number === rowData.request_reference_number) {
          sm.net_support_price_MC = rowData['net_support_price_MC'];
          sm.credit_back_amount_MC = rowData['credit_back_amount_MC'];
          sm.isValidCreditAmountMC = this.validateNumber(sm.credit_back_amount_MC, sm);
          if (!sm.isValidCreditAmountMC) {
            this.toastr.error('Values cannot be negative', 'Error!');
          }
        }
      });
  }

  validateNumber(value, rowData) {
    this.rebatesData.forEach(sm => {
      if(sm.request_reference_number === rowData.request_reference_number) {
        sm.issueInMassChange = Number(value) > 0;
      }
    });
    return Number(value) > 0;
  }

  receivePriceData(priceData: any) {
    this.priceData = priceData;
  }

  buildPrice(event, rowData) {
    this.selectedRow = rowData;
    this.getEMPriceHistory(event.target.value);
  }

  getEMPriceHistory(date) {
    this.spinner.show();
    this.service.getEMPPMHistory(this.selectedRow['request_id'], this.bu, this.isDistributor).subscribe(response => {
      this.spinner.hide();
      this.apiResp = response;
      if(!this.apiResp['isError']) {
        this.cols = JSON.parse(JSON.stringify(this.apiResp['headerColumns']));
        this.apiResp['historyData'].forEach(hd => {
          hd.type_of_change = (hd.type_of_change === 1) ? '%' : '$';
          hd.direction_of_change = (hd.direction_of_change === 1) ? 'Increase' : 'Decrease';
          hd.effective_date_of_change = moment(hd.effective_date_of_change).format('MM/DD/YYYY');
          hd.expiration_date = moment(hd.expiration_date).format('MM/DD/YYYY');
        })
        this.priceData = JSON.parse(JSON.stringify(this.apiResp['historyData']));
        this.priceData.forEach(pd => {
          pd.effectiveDate = moment(pd['effectiveDate']).format('MMMM Do YYYY');
        });
        this.getDistributorPrice(date);
      }
    });
  }
}
