import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CupsService } from 'src/services/cups.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-rebates',
  templateUrl: './rebates.component.html',
  styleUrls: ['./rebates.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RebatesComponent implements OnInit {

  gridAPIResp: any;
  rebateresp: any;
  selectedCols: any;
  rebatesData: any;
  downloadRebate:any;
  EMID = 1;
  EVID = 2;
  PMID = 3;
  EDID = 4;
  uploadedFiles: any[] = [];
  fileName = "";
  fileType = "";
  fileUpload = false;
  filesize ="";
  fileBuffer: any;
  fileObject: any;
  displayUpload = false;
  selectedBusinessUnit: any;
  bu = "EM";
  isDistributor = false;
  selectedRebates: any;
  APPROVED = 3;
  PENDING = 1;
  REJECTED = 2;
  ALL = 0;
  rebateStatus = 3;
  filteredData: any;
  filteredFields = [];
  tempFilteredData: any;
  isSalesSupportUser: any;
  isSalesSupportDistributor = 0;
  constructor(private _service: CupsService, private spinner: NgxSpinnerService, private toastr: ToastrService, private router: Router) { }

  ngOnInit() {
    this.bu = sessionStorage.getItem('BU');
    this.isDistributor = (sessionStorage.getItem('isDistributor') === "1" || sessionStorage.getItem('isDistributor') === "true") ? true : false;
    this.isSalesSupportUser = (sessionStorage.getItem('isSalesSupport') === "1" || sessionStorage.getItem('isSalesSupport') === "true") ? true : false;
    switch (this.bu) {
      case 'EM': this.selectedBusinessUnit = this.EMID; break;
      case 'EV': this.selectedBusinessUnit = this.EVID; break;
      case 'PM': this.selectedBusinessUnit = this.PMID; break;
      case 'ED': this.selectedBusinessUnit = this.EDID; break;
      default: this.selectedBusinessUnit = this.EMID; break;
    }

    this.isSalesSupportDistributor = Number(sessionStorage.getItem('SalesSupportDistributor'));
    this.getRebatesGrid(this.selectedBusinessUnit, this.rebateStatus);
  }

  getRebatesGrid(businessUnit: any, rebateStatus) {
    this.selectedBusinessUnit = businessUnit;
    this.spinner.show();
    this._service.getRebateGridData(businessUnit, rebateStatus, this.isSalesSupportDistributor).subscribe(response => {
      this.spinner.hide();
      if (!response['isError']) {
        this.gridAPIResp = response;
        this.selectedCols = this.gridAPIResp.headerColumns;
        this.selectedCols = this.selectedCols.filter(col => col.is_grid_display);
        if(this.isDistributor || Number(sessionStorage.getItem('SalesSupportDistributor'))>0){
          this.selectedCols=this.selectedCols.filter(col=>col.field!== 'admincomments');
        }
        if((businessUnit === 1 || businessUnit === 4) && rebateStatus === 1) {
          this.selectedCols = this.selectedCols.filter(ld => ld.field !== 'admincomments' );
        }
        if (businessUnit === 1 || businessUnit === 2 || businessUnit === 4) {
          this.rebatesData = this.gridAPIResp.rebateEvemGridData;
        }
        if (businessUnit === 3) {
          this.rebatesData = this.gridAPIResp.rebatePMGridData;
        }
        this.filteredData = JSON.parse(JSON.stringify(this.rebatesData));
        this.tempFilteredData = JSON.parse(JSON.stringify(this.filteredData));
      } else {
        this._service.displayToastr(response);
      }
    })
  }

  onTabChange(event) {
    switch(event.index) {
      case 0: this.rebateStatus = this.APPROVED; break;
      case 1: this.rebateStatus = this.PENDING; break;
      case 2: this.rebateStatus = this.REJECTED; break;
      case 3: this.rebateStatus = this.ALL; break;
      default: this.rebateStatus = this.APPROVED; break;
    }
    this.getRebatesGrid(this.selectedBusinessUnit, this.rebateStatus);
  }

  onSelectRow(rowObject) {
    let { rowData, type } = rowObject; 
    if(type == 'edit') {
      let path = this.selectedBusinessUnit === 3 ? 'rebates/pm-rebate-form' : 'rebates/evem-rebate-form';
      this.router.navigate([path], { state: { data: {cols: this.selectedCols, rowData: rowData, type} } });
    } else {
      this.router.navigate(['rebates/rebate-info'], { state: { data: {labelData: this.gridAPIResp.headerColumns, rowData: rowData, type} } });
    }
  }

  myUploader(event) {
    this.spinner.show();
    let fileData = event[0];
    const element = event[0];
    this.filesize = "0";
    this.filesize = element.size;
    this.filesize = this.filesize.toString();
    if (element.size > 3145728) {
      this.spinner.hide();
      this.toastr.warning("File Limit Exceeded, Allows only below 3MB data");
      return false;
    } else {
      this.fileName = fileData.name;
      this.fileType = fileData.type;
      this.fileBuffer = "";
      let fileReader = new FileReader();
      fileReader.onload = (_e) => {
        this.fileBuffer = fileReader.result;
        this.fileBuffer = this.fileBuffer.replace(`data:${this.fileType};base64,`, '');
        this.fileUpload = true;
        this.fileObject = {
          "businessUnitId": this.selectedBusinessUnit,
          "fileName": this.fileName,
          "fileType": this.fileType,
          "fileSize": this.filesize,
          "fileAsBase64": this.fileBuffer,
          "created_by": Number(sessionStorage.getItem('userid')),
          "salesSupportDistributor": Number(sessionStorage.getItem('SalesSupportDistributor'))
        };
      }
      fileReader.readAsDataURL(fileData);
    }
    this.uploadedFiles.push(event[0]);
    this.spinner.hide();
  }

  uploadSubmit(_fileUpload) {
    this.spinner.show();
    this._service.uploadRebateTemplate(this.fileObject).subscribe(response => {
      this.afterAPIResponse(response);
    });
  }

  afterAPIResponse(response) {
    if (!response['isError']) {
      let apiResponse = JSON.stringify(response);
      if (apiResponse.includes("Invalid template")) { this.toastr.error(apiResponse, 'Error!') }
      else {
        this.toastr.success(apiResponse, 'Success!');
      }
      this.removeFile();
      this.getRebatesGrid(this.selectedBusinessUnit, this.rebateStatus);
      this.displayUpload = false;
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this._service.displayToastr(response);
    }
  }

  uploadFinalisedRebates() {
    this._service.uploadRebateExport(this.fileObject).subscribe(response => {
      this.afterAPIResponse(response);
    });
  }

  uploadRebates() {
    this.displayUpload = true;
  }

  removeFile() {
    this.fileName = "";
    this.fileType = "";
    this.filesize = "";
    this.fileBuffer = ""
    this.fileObject = null;
  }

  receiveSelectedRows(event) {
    this.selectedRebates = event;
  }

  filterData(filterObj) {
    let {value, filter } = filterObj;
    if (value == '') {
      if (this.filteredFields.length) {
        this.filteredFields = this.filteredFields.filter(ff => ff.fieldName !== filter);
      } else {
        this.filteredFields = [];
      }
    } else {
      let foundField = this.filteredFields.filter(ff => ff.fieldName == filter);
      if (foundField.length) {
        this.filteredFields.forEach(ff => {
          if (ff.fieldName == filter) {
            ff.value = value;
          }
        })
      } else {
        let fieldObj = {
          fieldName: filter,
          value: value
        };
        this.filteredFields.push(fieldObj);
      }
    }
    if (this.filteredFields.length) {
      let temp = this.rebatesData;
      this.filteredFields.forEach(ff => {
        temp = temp.filter(fd => fd[ff.fieldName].toUpperCase().includes(ff.value.toUpperCase()));
      })
      this.filteredData = temp;
    } else {
      this.filteredData = JSON.parse(JSON.stringify(this.rebatesData));
    }
    this.tempFilteredData = JSON.parse(JSON.stringify(this.filteredData));
  }

  exportExcel() {
    if (this.selectedBusinessUnit === 3) {
      if (this.selectedRebates !== undefined && this.selectedRebates.length > 0) {
        this.exportAsExcelFile(this.selectedRebates, 'RebatesRequests', 'sheet1', this.selectedCols);
      }
      else {
        this.exportAsExcelFile(this.tempFilteredData, 'RebatesRequests', 'sheet1', this.selectedCols);
      }
    } else {
      let userId = sessionStorage.getItem('userid');
      let selectedRebatedIds = '';
      if ((this.selectedBusinessUnit === 1 || this.selectedBusinessUnit === 4) && this.selectedRebates) {
        this.selectedRebates.forEach(sr => {
          selectedRebatedIds = selectedRebatedIds + ',' + sr.rebate_reference_number 
        });
        selectedRebatedIds = selectedRebatedIds.slice(1);
      }
      else {
        selectedRebatedIds = "''";
      }
      this._service.downloadRebatesEMEV(this.selectedBusinessUnit, userId, selectedRebatedIds).subscribe((response: any) => {
        if (!response['isError']) {
          this.rebateresp = response;
          this.selectedCols = this.rebateresp.headerColumns;
          this.selectedCols = this.selectedCols.filter(col => col.is_grid_display);
          this.downloadRebate = this.rebateresp.downloadRebate;
          this.exportAsExcelFile(this.downloadRebate, 'RebatesRequests', 'Rebates', this.selectedCols);
        } else {
          this._service.displayToastr(response);
        }
        this.getRebatesGrid(this.selectedBusinessUnit, this.rebateStatus);
      })
    }
  }

  exportAsExcelFile(json: any[], excelFileName: string, sheetName: string, columnValues: any[]): void {
    let fieldValues: any[] = columnValues.map(field => field.field);
    let headerValues: string[] = columnValues.map(header => header.header);
    let displayedJson: any[] = [];
    if (json != null && json.length > 0) {
      for (let originalValue of json) {
        let jsonObject: any = {};
        for (let fieldValue of fieldValues) {
          jsonObject[fieldValue] = originalValue[fieldValue];
        }
        let jsonString = JSON.stringify(jsonObject);
        displayedJson.push(JSON.parse(jsonString));
      }
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(displayedJson);
      let range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        let address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
        if (!worksheet[address]) continue;
        worksheet[address] = {
          v: headerValues[C],
          t: 's',
          s: {
            alignment: { textRotation: 90 },
            font: { bold: true }
          }
        };
      }
      XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: -1 });
      const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }

  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }


  

}
