import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CupsService } from 'src/services/cups.service';
import * as moment from 'moment'

@Component({
  selector: 'app-pm-cups-request',
  templateUrl: './pm-cups-request.component.html',
  styleUrls: ['./pm-cups-request.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PmCupsRequestComponent implements OnInit {
  formData = {};
  workFlowResp: any = {};
  requestAPI = {
    "pm_request_id": 0,
    "submission_date": "2021-11-25T02:08:07.639Z",
    "status_id": 0,
    "sales_org": 0,
    "effective_date": "2021-11-25T02:08:07.639Z",
    "expiration_date": "2021-11-25T02:08:07.639Z",
    "author_comments": "",
    "distributor_id": 0,
    "product_id": 0,
    "article_number": "",
    "sbu": 0,
    "minimum_qty": 0,
    "list_price_lb": 0,
    "list_price_kg": 0,
    "currency_unit": "",
    "minimum_order_qty": 0,
    "estimated_annual_volume": 0,
    "selling_margin": 0,
    "suggested_selling_price": 0,
    "supported_buy_price": 0,
    "sell_price_UOM_id": 0,
    "rebate": 0,
    "freight_added": "",
    "account": 0,
    "customer_state": "",
    "customer_city": "",
    "oem": "",
    "tier": "",
    "end_use": "",
    "industry": 0,
    "segment": 0,
    "application": "",
    "application_comment": "",
    "s1_code": "",
    "competitive_grade": "",
    "competitive_supplier": "",
    "created_by": "",
    "sales_rep_email_id": 0,
    "is_account_manager": false,
    "internal_comments": '',
    "external_comments": '',
    'is_internal_user': false,
    'distributor_sales_rep_id': 0
  }
  newCustomer = {
    customer_id: 0,
    customer_name: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    created_by: '',
    customer_region: '',
    businessunit_id: 0,
    distributor_sold_to_id: 0
  };
  orgRowData: any;
  rowData: any;
  businessChannelDD: any;
  selectedBusinessChannel: any
  distributorsDD: any;
  distributorData: any;
  selectedDistributor: any;
  productsDD: any;
  productsData: any;
  selectedProduct: any;
  productDetails: any;
  salesOrg: any;
  masterApiResp;
  requestMasterApiResp: any;
  sellPriceUOMType: any;
  selectedSellPriceUOM: any;
  selectedCustomer: any;
  customersDD: any;
  industryDD: any;
  selectedIndustry: any;
  segmentDD: any;
  selectedSegment: any;
  endUseDD: any;
  selectedEndUse: any;
  applicationDD: any;
  selectedApplication: any;
  formMode = "New";
  basfSalesRepDD: any;
  selectedSalesRep: any;
  cupRequestDetails: any;
  apiResp: any;
  soldToDD: any;
  selectedSoldTo: any;
  businessName: any;
  type: any;
  isAccountManager: boolean = false;
  userId: any;
  displayRefNumber: boolean = false;
  selectedMinimumOrderQty: any;
  minimumOrderQuantityDD: any;
  s1Code = "";
  prodCols = [];
  prodData = [];
  selectedProdItem: any;
  isListPricebind: boolean = false;
  s1CodeDD: any;
  selectedS1Code: any;
  displayDistributorSalesRep: boolean = false;
  distSalesRepDD: any;
  selectedDistSalesRep: any;
  isUpdateCustomer: boolean = false;
  cityDD: any;
  stateDD: any;
  selectedCity: any;
  selectedState: any;
  displayCustomerPopUp: boolean = false;
  selectedRegion: any;
  userAdmin: any;
  newComments: string;
  showCommentsPopup: boolean = false;
  isDistributor: boolean = false;

  constructor(private router: Router, private _service: CupsService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userid');
    this.isDistributor = (sessionStorage.getItem('isDistributor') === "1" || sessionStorage.getItem('isDistributor') === "true") ? true : false;
    this.displayDistributorSalesRep = (sessionStorage.getItem('isSalesRep') === "1" || sessionStorage.getItem('isSalesRep') === "true") ? true : false;
    if (!history.state.data) {
      this.router.navigate(['/home']);
    }
    else {
      this.type = history.state.data.type;
      if (history.state.data.type !== 'New') {
        this.callCupsRequestService();
      } else {
        this.getSoldToData();
      }
      this.formData['createdDate'] = moment(new Date()).format('L');
      this.formData['expirationDate'] = "";
      this.formData['status_id'] = 0;
    }
  }

  getWorkFlowUpdates(statusId) {
    if (this.isAccountManager) {
      this.spinner.show();
      this._service.getWorkFlowUpdateDetails(this.userId, this.formData['pm_request_id'], 3, false).subscribe(response => {
        this.apiResp = response;
        if (this.apiResp['authorized'] && !this.apiResp['isError'] && this.apiResp['response'].length > 0) {
          this.workFlowResp = this.apiResp.response[0];
          this.workFlowResp["work_flow_status_id"] = statusId;
          this.workFlowResp['cup_request_no'] = this.cupRequestDetails['request_reference_number'];
          this.workFlowResp['approvar_comments'] = '';
          this.workFlowResp['businesunit_id'] = 3;
          this.workFlowResp['request_id'] = this.formData['pm_request_id'];
          this.workFlowResp['modified_by'] = Number(sessionStorage.getItem('userid'));
          this.updateWorkFlowStatus();
        } else if (this.apiResp['authorized'] && this.apiResp['isError']) {
          this._service.displayToastr(response);
        } else if (!this.apiResp['authorized'] && this.apiResp['isError']) {
          this._service.displayToastr(response);
        } else {
          console.log("No workflow updates found");
        }
        this.spinner.hide();
      })
    }
  }

  generateRequest(statusId) {
    if (statusId == 3) {
      this.showCommentsPopup = true;
      //Calling Get work flow updates in the submitComments after the successful API call back
    }
    else {

      this.getWorkFlowUpdates(statusId);

    }
  }

  updateWorkFlowStatus() {
    this.spinner.show();
    this._service.updateWorkFLowStatus(this.workFlowResp).subscribe((response) => {
      this.apiResp = response;
      if (!response['isError']) {
        this._service.displayToastr(response);
        this.router.navigate(['/home']);
      } else {
        this._service.displayToastr(response);
      }
      this.spinner.hide();
    })
  }

  submitComments(commentsType) {
    this.spinner.show();
    let currentDate = moment().format('MM/DD/yyyy');
    let commentsData = '';
    this.newComments = `${currentDate}: ${this.newComments} - by ${sessionStorage.getItem('loginuser')} ||`;
    if (commentsType === 'external') {
      const isEndWithSlash = this.formData['external_comments'].endsWith('||');
      const newC = isEndWithSlash ? this.newComments : ` || ${this.newComments}`
      commentsData = this.formData['external_comments'] != '' ? `${this.formData['external_comments']} ${newC}` : `${this.newComments}`;
    }
    let reqData = {
      "request_id": this.formData['pm_request_id'],
      "businessunit_id": 3,
      "internal_comments": commentsData,
      "type_of_comment": commentsType,
      "updated_by": Number(this.userId)
    }
    this._service.updateComments(reqData).subscribe(response => {

      this.close();
      this._service.displayToastr(response);
      this.getWorkFlowUpdates(3);
      //passing statusID as we come here at reject = 3 
    })
  }

  close() {
    this.newComments = ''
    this.showCommentsPopup = false;
  }

  callCupsRequestService() {
    this.orgRowData = history.state.data.rowData;
    this.spinner.show();
    this._service.getCUPRequestDetails(this.orgRowData.pm_request_id, 3).subscribe(response => {
      if (!response['isError']) {
        this.apiResp = response;
        this.cupRequestDetails = JSON.parse(JSON.stringify(this.apiResp.pmRequestSubmissionData[0]));
        this.formMode = 'Edit';
        if (this.type == 'edit') {
          this.displayRefNumber = true;
          if (sessionStorage.getItem('loginuseremail') == this.cupRequestDetails.sales_rep_email) {
            this.isAccountManager = true;
          }
        } else {
          this.displayRefNumber = false;
          this.formData['status_id'] = 0;
          this.formData['internal_comments'] = "";
          this.formData['external_comments'] = "";
        }
        this.generateFormData();
        this.userId = sessionStorage.getItem('userid');
        this.getSoldToData();
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this._service.displayToastr(response);
      }
    })
  }

  getSoldToData() {
    this.spinner.show();
    let userId = (this.formMode === 'Edit') ? this.cupRequestDetails.created_by : this.userId;
    this.userAdmin = (sessionStorage.getItem('IsAdmin') === "1" || sessionStorage.getItem('IsAdmin') === "true") ? true : false;
    this.requestAPI.is_internal_user = !this.isDistributor;
    this._service.getSoldTo(userId, 3, this.userAdmin).subscribe((res: any) => {
      if (!res['isError']) {
        this.soldToDD = res.response;
        this.soldToDD.forEach(sd => {
          sd.soldToFullName = `${sd.sold_to_sap_id} - ${sd.distributor_sold_to_name}`;
        })
        if (this.formMode === 'Edit') {
          this.selectedSoldTo = this.soldToDD.find(ss => ss.distributor_sold_to_id === this.cupRequestDetails.distributor_sold_to_id);
          this.getDistributorDetails();
        }
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this._service.displayToastr(res);
      }
    })
  }

  getDistributorDetails() {
    this.spinner.show();
    let userId = (this.formMode === 'Edit') ? this.cupRequestDetails.created_by : this.userId;
    this._service.getMasterData(userId, this.selectedSoldTo.distributor_sold_to_id, '3', this.selectedSoldTo.distributor_id, this.requestAPI.is_internal_user).subscribe(response => {
      if (!response['isError']) {
        this.masterApiResp = response;
        this.getDistributorData(this.masterApiResp);
        this.getProductsData(this.masterApiResp);
        this.getRequestMasterData(this.masterApiResp);
        this.getDistributorUsers(this.masterApiResp);
        this.getSalesRepDD();
        this.getS1CodeDD();
        this.generateCustomersData();
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this._service.displayToastr(response);
      }
    },
      (error) => {
        console.log("error", error.message);
      })
  }

  getDistributorUsers(masterJson) {
    this.distSalesRepDD = masterJson.distributorUsers;
    if (this.type === 'edit') {
      this.selectedDistSalesRep = this.distSalesRepDD.find(c => c.distributor_user_id === this.cupRequestDetails.distributor_sales_rep_id);
    } else {
      this.selectedDistSalesRep = this.distSalesRepDD.find(c => c.email === sessionStorage.getItem('loginuseremail'));
    }
  }

  generateCustomersData() {
    this.customersDD = this.getUniqueListBy(this.masterApiResp.customerData, 'customer_name');
    if (this.formMode === 'Edit') {
      this.selectedCustomer = this.masterApiResp.customerData.find(c => c.customer_id === this.cupRequestDetails.customer_id);
      if (this.selectedCustomer) {
        this.generateCustomerDetails();
        this.constructStateDropDown();
        this.selectedState = this.stateDD.find(sd => sd.customer_region === this.selectedCustomer.customer_region);
        this.constructCityDropDown();
        this.selectedCity = this.cityDD.find(cd => cd.city === this.selectedCustomer.city);
        this.formData['zip'] = this.selectedCity.zip_code;
        if (this.selectedCustomer['s1_code'] != '') {
          let selectedS1Code = this.s1CodeDD.find(b => b.s1_code === this.selectedCustomer['s1_code']);
          if (selectedS1Code) {
            this.selectedS1Code = selectedS1Code;
          }
        }
      }
      this.selectedCustomer = this.customersDD.find(c => c.customer_name === this.cupRequestDetails.account);
    }
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  getDistributorData(masterJson) {
    this.distributorData = masterJson.requestDistributorDetails;
    this.selectedDistributor = this.distributorData[0];
    this.generateDistributorDetails();
  }

  getProductsData(masterJson) {
    this.productsData = masterJson.productsData;
    if (this.formMode === 'Edit') {
      this.selectedProduct = this.productsData.find(p => p.product_id === this.cupRequestDetails.product_id);
      if (this.selectedProduct) {
        this.getProductDetailsById();
      }
    }
  }

  getS1CodeDD() {
    this.s1CodeDD = this.masterApiResp.s1Code;
  }

  getSalesRepDD() {
    this.basfSalesRepDD = this.masterApiResp.salesRepEmailData;
    if (this.formMode === 'Edit') {
      this.selectedSalesRep = this.basfSalesRepDD.find(b => b.sales_rep_email_id === this.cupRequestDetails.sales_rep_email_id);
      if (this.selectedSalesRep) {
        this.generateSalesRepDetails();
      }
    }
  }

  generateSalesRepDetails() {
    this.formData['salesRep'] = this.selectedSalesRep.sales_rep_name;
    this.formData['salesRepEmail'] = this.selectedSalesRep.sales_rep_email;
  }

  getFormattedData(date) {
    return moment(date).format("yyyy-MM-DD");
  }

  populateExpiryDate(date) {
    this.formData['expirationDate'] = moment(date).add(35, 'days').format('L');
  }

  getProductDetailsById() {
    this.productDetails = this.productsData.filter(p => p.product_id === this.selectedProduct.product_id);
    this.prodData = [];
    this.isListPricebind = false;
    this.constructProductCalls();
    if (!this.formData['effectiveDate'] && (this.type === 'edit' || this.type === 'New')) {
      this.toastr.error("Effective Date Required");
      return false;
    } else {
      this.callProductPricingService();
    }
  }

  constructProductCalls() {
    this.prodCols = [
      {
        "header_id": 1,
        "field": "prodQuantity",
        "header": "Minimum Quantity",
        "is_show": true,
        "is_editable": false,
        "serial_no": 1
      },
      {
        "header_id": 2,
        "field": "prodLBPrice",
        "header": "List Price, LB",
        "is_show": true,
        "is_editable": false,
        "serial_no": 2
      },
      {
        "header_id": 3,
        "field": "prodKGPrice",
        "header": "List Price, KG",
        "is_show": true,
        "is_editable": false,
        "serial_no": 3
      },
      {
        "header_id": 4,
        "field": "prodCurrency",
        "header": "Currency Unit",
        "is_show": true,
        "is_editable": false,
        "serial_no": 4
      }
    ];
  }

  callProductPricingService() {
    this.spinner.show();
    this.prodData = [];
    this.salesOrg = this.formData['salesOrg'];
    let effective_date = this.type === 'edit' ? moment(this.cupRequestDetails['effective_date']).format('yyyy-MM-DD') : moment(this.formData['effectiveDate']).format('yyyy-MM-DD');
    if (effective_date && this.productDetails) {
      this._service.getProductPricingRecord(3, this.productDetails[0].article_number, this.selectedSoldTo.distributor_sold_to_id, 0, 0, effective_date, this.salesOrg.toString(), 0).subscribe(response => {
        if (!response['isError']) {
          this.apiResp = response;
          let receivedProduct = JSON.parse(JSON.stringify(this.apiResp.response));
          let currencyUnit = this.productDetails[0].currency_unit;
          this.checkScale1Values(receivedProduct, currencyUnit);
          this.checkScale2Values(receivedProduct, currencyUnit);
          this.checkScale3Values(receivedProduct, currencyUnit);
          this.checkScale4Values(receivedProduct, currencyUnit);
          this.checkScale5Values(receivedProduct, currencyUnit);
          this.checkScale6Values(receivedProduct, currencyUnit);
          this.checkScale7Values(receivedProduct, currencyUnit);
          this.getProductDetails();
          this.getSelectedProdItem(receivedProduct);
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this._service.displayToastr(response);
        }
      })
    }
    else {
      this.spinner.hide();
    }
  }

  getSelectedProdItem(receivedProduct: any) {
    if (this.formMode === 'Edit' && (receivedProduct['articleNumber'] === this.cupRequestDetails.article_number)) {
      let rowObj = {
        data: this.prodData.find(p => p.prodQuantity === this.cupRequestDetails.minimum_qty && p.prodLBPrice === this.cupRequestDetails.list_price_lb)
      }
      if (rowObj.data != undefined) {
        this.onProductSelect(rowObj);
        this.selectedProdItem = JSON.parse(JSON.stringify(rowObj.data));
      }
    } else {
      this.selectedProdItem = null;
      this.formData['suggestedSellPrice'] = "";
      this.formData['supportedBuyPrice'] = "";
      this.formData['rebate'] = "";
      this.formData['minimumQuantity'] = "";
      this.formData['lbListPrice'] = "";
      this.formData['kgListPrice'] = "";
      this.formData['currencyUnit'] = "";
    }
  }

  checkScale1Values(receivedProduct: any, currencyUnit) {
    if (receivedProduct['scale_rate1'] && receivedProduct['scale_quantity1']) {
      let prodObj = {
        prodId: 1,
        prodQuantity: receivedProduct['scale_quantity1'],
        prodLBPrice: receivedProduct['scale_rate1'],
        prodKGPrice: (2.2046 * Number(receivedProduct['scale_rate1'])).toFixed(2),
        prodCurrency: currencyUnit
      }
      this.isListPricebind = true;
      this.prodData.push(prodObj);
    }
  }

  checkScale2Values(receivedProduct: any, currencyUnit) {
    if (receivedProduct['scale_rate2'] && receivedProduct['scale_quantity2']) {
      let prodObj1 = {
        prodId: 2,
        prodQuantity: receivedProduct['scale_quantity2'],
        prodLBPrice: receivedProduct['scale_rate2'],
        prodKGPrice: (2.2046 * Number(receivedProduct['scale_rate2'])).toFixed(2),
        prodCurrency: currencyUnit
      }
      this.isListPricebind = true;
      this.prodData.push(prodObj1);
    }
  }

  checkScale3Values(receivedProduct: any, currencyUnit) {
    if (receivedProduct['scale_rate3'] && receivedProduct['scale_quantity3']) {
      let prodObj3 = {
        prodId: 3,
        prodQuantity: receivedProduct['scale_quantity3'],
        prodLBPrice: receivedProduct['scale_rate3'],
        prodKGPrice: (2.2046 * Number(receivedProduct['scale_rate3'])).toFixed(2),
        prodCurrency: currencyUnit
      }
      this.isListPricebind = true;
      this.prodData.push(prodObj3);
    }
  }

  checkScale4Values(receivedProduct: any, currencyUnit) {
    if (receivedProduct['scale_rate4'] && receivedProduct['scale_quantity4']) {
      let prodObj4 = {
        prodId: 4,
        prodQuantity: receivedProduct['scale_quantity4'],
        prodLBPrice: receivedProduct['scale_rate4'],
        prodKGPrice: (2.2046 * Number(receivedProduct['scale_rate4'])).toFixed(2),
        prodCurrency: currencyUnit
      }
      this.isListPricebind = true;
      this.prodData.push(prodObj4);
    }
  }

  checkScale5Values(receivedProduct: any, currencyUnit) {
    if (receivedProduct['scale_rate5'] && receivedProduct['scale_quantity5']) {
      let prodObj5 = {
        prodId: 5,
        prodQuantity: receivedProduct['scale_quantity5'],
        prodLBPrice: receivedProduct['scale_rate5'],
        prodKGPrice: (2.2046 * Number(receivedProduct['scale_rate5'])).toFixed(2),
        prodCurrency: currencyUnit
      }
      this.isListPricebind = true;
      this.prodData.push(prodObj5);
    }
  }

  checkScale6Values(receivedProduct: any, currencyUnit) {
    if (receivedProduct['scale_rate6'] && receivedProduct['scale_quantity6']) {
      let prodObj6 = {
        prodId: 6,
        prodQuantity: receivedProduct['scale_quantity6'],
        prodLBPrice: receivedProduct['scale_rate6'],
        prodKGPrice: (2.2046 * Number(receivedProduct['scale_rate6'])).toFixed(2),
        prodCurrency: currencyUnit
      }
      this.isListPricebind = true;
      this.prodData.push(prodObj6);
    }
  }

  checkScale7Values(receivedProduct: any, currencyUnit) {
    if (!this.isListPricebind) {
      let prodObj7 = {
        prodId: 7,
        prodQuantity: 0,
        prodLBPrice: receivedProduct.list_Price,
        prodKGPrice: (2.2046 * Number(receivedProduct.list_Price)).toFixed(2),
        prodCurrency: currencyUnit
      }
      this.prodData.push(prodObj7);
    }
  }

  onProductSelect(rowData: any) {
    this.formData['minimumQuantity'] = rowData.data['prodQuantity'];
    this.formData['lbListPrice'] = rowData.data['prodLBPrice'];
    this.formData['kgListPrice'] = rowData.data['prodKGPrice'];
    this.formData['currencyUnit'] = rowData.data['prodCurrency'];
    this.doCalculations();
  }

  getProductDetails() {
    this.productDetails = this.productsData.filter(p => p.product_id === this.selectedProduct.product_id);
    this.formData['article'] = this.productDetails[0].article_number;
    this.formData['sellingMargin'] = this.productDetails[0].selling_margin;
    this.formData['sbu_name'] = this.productDetails[0].sbu_name;
  }

  getRequestMasterData(masterJson) {
    this.spinner.show();
    this.requestMasterApiResp = masterJson.requestMaster;
    this.sellPriceUOMType = this.requestMasterApiResp.filter(rm => rm.master_name === 'VolumeUOM');
    this.businessChannelDD = this.requestMasterApiResp.filter(rm => rm.master_name === 'DistributorChannel');
    this.endUseDD = this.requestMasterApiResp.filter(rm => rm.master_name === 'EndUse');
    this.industryDD = this.requestMasterApiResp.filter(rm => rm.master_name === 'Industry');
    this.applicationDD = this.requestMasterApiResp.filter(rm => rm.master_name === 'Application');
    this.segmentDD = this.requestMasterApiResp.filter(rm => rm.master_name === 'Segment');
    this.minimumOrderQuantityDD = this.requestMasterApiResp.filter(rm => rm.master_name === 'MinOrderQty');
    if (this.formMode === 'Edit') {
      this.selectedSellPriceUOM = this.sellPriceUOMType.find(s => s.request_master_id === this.cupRequestDetails.sell_price_UOM_id);
      this.selectedIndustry = this.industryDD.find(i => i.request_master_id === this.cupRequestDetails.industry);
      this.selectedSegment = this.segmentDD.find(s => s.request_master_id === this.cupRequestDetails.segment);
      this.selectedMinimumOrderQty = this.minimumOrderQuantityDD.find(m => m.request_master_id === this.cupRequestDetails.minimum_order_qty);
    }
    this.spinner.hide();
  }

  generateDistributorDetails() {
    this.formData['pricingAdmin'] = this.selectedDistributor.pricing_Admin;
    this.formData['pricingAdminEmail'] = this.selectedDistributor.pricing_Admin_Email;
    this.formData['basfPayer'] = this.selectedDistributor.basf_payer;
    this.formData['salesRep'] = this.selectedDistributor.sales_Rep;
    this.formData['salesRepEmail'] = this.selectedDistributor.sales_Rep_Email;
    this.formData['distributorName'] = this.selectedDistributor.distributor_name;
    this.formData['createdBy'] = sessionStorage.getItem('loginuseremail');
    this.formData['salesOrg'] = this.masterApiResp.requestMaster.filter(rm => rm.master_name === 'SalesOrg' && rm.request_master_id === this.selectedDistributor.sales_org)[0].value;


  }

  generateCustomerDetails() {
    this.formData['city'] = this.selectedCustomer.city;
    this.formData['region'] = this.selectedCustomer.customer_region;
  }

  doCalculations() {
    const SP = Number(this.formData['suggestedSellPrice']);
    const SM = Number(this.formData['sellingMargin']);
    if (SP && SM && this.selectedSellPriceUOM) {
      this.formData['supportedBuyPrice'] = (SP - (SP * SM / 100)).toFixed(2).toString();
      if (this.selectedSellPriceUOM.value === 'LB') {
        this.formData['rebate'] = (Number(this.formData['lbListPrice']) - Number(this.formData['supportedBuyPrice'])).toFixed(2).toString();
      } else {
        this.formData['rebate'] = (Number(this.formData['kgListPrice']) - Number(this.formData['supportedBuyPrice'])).toFixed(2).toString();
      }
    }
  }

  generateFormData() {
    this.spinner.show();
    this.formData['request'] = this.type === 'edit' ? this.cupRequestDetails['request_reference_number'] : "";
    this.formData['pm_request_id'] = this.type === 'edit' ? this.cupRequestDetails['pm_request_id'] : 0;
    this.formData['createdDate'] = this.type === 'edit' ? moment(this.cupRequestDetails['submission_date']).format('MM/DD/yyyy') : moment(new Date()).format('L');
    this.formData['effectiveDate'] = this.type === 'edit' ? moment(this.cupRequestDetails['effective_date']).format('yyyy-MM-DD') : "";
    this.formData['expirationDate'] = this.type === 'edit' ? moment(this.cupRequestDetails['expiration_date']).format('yyyy-MM-DD') : "";
    this.formData['status_id'] = this.type === 'edit' ? this.cupRequestDetails['status_id'] : 0;
    this.formData['estimatedAnnualVolume'] = this.cupRequestDetails['estimated_annual_volume'];
    this.formData['suggestedSellPrice'] = this.cupRequestDetails['suggested_selling_price'];
    this.formData['supportedBuyPrice'] = this.cupRequestDetails['supported_buy_price'];
    this.formData['rebate'] = this.cupRequestDetails['rebate'];
    this.formData['sellingMargin'] = this.cupRequestDetails['selling_margin'];
    this.formData['authorComments'] = this.cupRequestDetails['author_comments'];
    this.formData['oem'] = this.cupRequestDetails['oem'];
    this.formData['tier'] = this.cupRequestDetails['tier'];
    this.formData['applicationComment'] = this.cupRequestDetails['application_comment'];
    this.formData['competitiveGrade'] = this.cupRequestDetails['competitive_grade'];
    this.formData['competitiveSupplier'] = this.cupRequestDetails['competitive_supplier'];
    this.formData['freightAdded'] = this.cupRequestDetails['freight_added'];
    this.formData['end_use'] = this.cupRequestDetails['end_use'];
    this.formData['application'] = this.cupRequestDetails['application'];
    this.formData['internal_comments'] = this.type === 'edit' ? this.cupRequestDetails['internal_comments'] : "";
    this.formData['external_comments'] = this.type === 'edit' ? this.cupRequestDetails['external_comments'] : "";
    this.spinner.hide();
  }

  fillCreatedBy() {
    if (this.formMode === 'Edit') {
      this.requestAPI.pm_request_id = Number(this.formData['pm_request_id']);
      if (this.type == 'edit') {
        this.requestAPI.created_by = this.cupRequestDetails.created_by;
      }
    }
  }

  bindCustomerDetails() {
    if (this.selectedCustomer != undefined) {
      let customer = this.masterApiResp.customerData.find(cd => cd.customer_name === this.selectedCustomer.customer_name && cd.customer_region === this.selectedState.customer_region && cd.city === this.selectedCity.city);
      this.requestAPI.account = customer ? customer.customer_id : 0;
      this.requestAPI.customer_state = this.selectedState.state;
      this.requestAPI.customer_city = this.selectedCity.city;

    } else {
      this.requestAPI.account = 0;
      this.requestAPI.customer_city = '';
      this.requestAPI.customer_state = ''
    }
  }

  submitPMRequest() {
    this.spinner.show();
    this.requestAPI.created_by = sessionStorage.getItem('userid');
    this.fillCreatedBy();
    if (this.checkValidations()) {
      this.requestAPI.effective_date = moment(this.formData['effectiveDate']).toDate().toDateString();
      this.requestAPI.expiration_date = moment(this.formData['expirationDate']).toDate().toDateString();
      this.requestAPI.author_comments = this.formData['authorComments'];
      this.requestAPI.distributor_id = this.selectedDistributor.distributor_id;
      this.requestAPI.product_id = this.selectedProduct.product_id;
      this.requestAPI.article_number = this.formData['article'];
      this.requestAPI.sbu = Number(this.selectedProduct.sbu);//need to rewrite the code
      this.requestAPI.minimum_qty = Number(this.formData['minimumQuantity']);
      this.requestAPI.list_price_lb = Number(this.formData['lbListPrice']);
      this.requestAPI.list_price_kg = Number(this.formData['kgListPrice']);
      this.requestAPI.currency_unit = this.formData['currencyUnit'];
      this.requestAPI.minimum_order_qty = this.selectedMinimumOrderQty.request_master_id;
      this.requestAPI.estimated_annual_volume = Number(this.formData['estimatedAnnualVolume']);
      this.requestAPI.selling_margin = Number(this.formData['sellingMargin']);
      this.requestAPI.suggested_selling_price = Number(this.formData['suggestedSellPrice']);
      this.requestAPI.supported_buy_price = Number(this.formData['supportedBuyPrice']);
      this.requestAPI.sell_price_UOM_id = this.selectedSellPriceUOM.request_master_id;
      this.requestAPI.rebate = Number(this.formData['rebate']);

      this.bindCustomerDetails();
      this.requestAPI.oem = this.formData['oem'];
      this.requestAPI.tier = this.formData['tier'];
      this.requestAPI.competitive_grade = this.formData['competitiveGrade'];
      this.requestAPI.competitive_supplier = this.formData['competitiveSupplier'];
      this.requestAPI.submission_date = moment(this.formData['createdDate']).toDate().toDateString();
      this.requestAPI.sales_org = this.selectedDistributor.sales_org;
      this.requestAPI.sales_rep_email_id = this.selectedSalesRep.sales_rep_email_id;
      this.requestAPI.is_account_manager = this.isAccountManager;
      this.requestAPI.freight_added = this.formData['freightAdded'];
      let currentDate = moment().format('MM/DD/yyyy');
      this.requestAPI.internal_comments = this.formData['internal_comments'] !== '' ? `${currentDate}: ${this.formData['internal_comments']} - by ${sessionStorage.getItem('loginuser')} ||` : '';
      this.requestAPI.external_comments = this.formData['external_comments'] !== '' ? `${currentDate}: ${this.formData['external_comments']} - by ${sessionStorage.getItem('loginuser')} ||` : '';
      this.requestAPI.distributor_sales_rep_id = this.selectedDistSalesRep ? this.selectedDistSalesRep.distributor_user_id : 0;
      this.generateManagerRequest();
      this.createPMRequestAPIcall();
    } else {
      this.spinner.hide();
    }
  }

  generateManagerRequest() {
    if (this.isAccountManager) {
      this.requestAPI.is_internal_user = this.cupRequestDetails['is_internal_user'];
      this.generateAccountManagerRequest();
    } else {
      this.generateNonAccountManagerRequest();
    }
  }


  createPMRequestAPIcall() {
    this._service.submitPMRequest(this.requestAPI).subscribe(response => {
      if (!response['isError']) {
        this.spinner.hide();
        if (this.cupRequestDetails && sessionStorage.getItem('loginuseremail') == this.cupRequestDetails.sales_rep_email) {
          this.generateRequest(1);
        } else {
          sessionStorage.setItem('paramsExist', 'false');
          this.toastr.success('Request submitted successfully', 'Success!');
        }
        this.router.navigate(['/home']);
      } else {
        this._service.displayToastr(response);
      }
    });
  }

  generateAccountManagerRequest() {
    this.requestAPI.status_id = 2; this.requestAPI.end_use = this.formData['end_use'];
    this.requestAPI.industry = this.selectedIndustry.request_master_id;
    this.requestAPI.segment = this.selectedSegment.request_master_id;
    this.requestAPI.application = this.formData['application'];
    this.requestAPI.application_comment = this.formData['applicationComment'];
    this.requestAPI.s1_code = this.selectedS1Code ? this.selectedS1Code['s1_code'] : '';
  }

  generateNonAccountManagerRequest() {
    this.requestAPI.status_id = 6;
    this.requestAPI.end_use = this.formData['end_use'];
    this.requestAPI.application = this.formData['application'];
    this.requestAPI.application_comment = this.formData['applicationComment'];
    this.requestAPI.s1_code = this.selectedS1Code ? this.selectedS1Code['s1_code'] : '';
  }

  cancel() {
    this.router.navigate(['/home']);
  }

  checkValidations() {
    if (!this.checkDrowdownValidations()) {
      return false;
    }
    if (!this.formData['effectiveDate']) {
      this.toastr.error("Effective Date Required");
      return false;
    }
    if (!this.formData['expirationDate']) {
      this.toastr.error("Expiration Date Required");
      return false;
    }
    if (!this.formData['authorComments']) {
      this.toastr.error("Author Comments Required");
      return false;
    }
    if (!this.formData['estimatedAnnualVolume']) {
      this.toastr.error("Estimated Annual Volume Required");
      return false;
    }
    if (!this.formData['suggestedSellPrice']) {
      this.toastr.error("Suggested Sell Price Required");
      return false;
    }
    if (!this.checkOtherValueValidations()) {
      return false;
    }
    if (this.isAccountManager && !this.checkAccountManagerValidations()) {
      return false;
    }
    return true;
  }

  checkOtherValueValidations() {
    if (!this.formData['oem']) {
      this.toastr.error("OEM Required");
      return false;
    }
    if (!this.formData['tier']) {
      this.toastr.error("Tier Required");
      return false;
    }
    if (!this.formData['competitiveGrade']) {
      this.toastr.error("Competitive Grade Required");
      return false;
    }
    if (!this.formData['competitiveSupplier']) {
      this.toastr.error("Competitive Supplier Comment");
      return false;
    }
    if (!this.formData['freightAdded']) {
      this.toastr.error("Freight Added required");
      return false;
    }
    if (!this.formData['end_use']) {
      this.toastr.error("End Use Required");
      return false;
    }
    if (!this.formData['application']) {
      this.toastr.error("Application Required");
      return false;
    }
    if (!this.formData['applicationComment']) {
      this.toastr.error("Application Comment Required");
      return false;
    }
    if (!this.formData['internal_comments']) {
      this.formData['internal_comments'] = '';
    }
    if (!this.formData['external_comments']) {
      this.formData['external_comments'] = '';
    }
    if (Number(this.formData['rebate']) < 0) {
      this.toastr.error("Rebate cannot be Negative");
      return false;
    }
    return true;
  }

  checkDrowdownValidations() {
    if (!this.selectedDistributor) {
      this.toastr.error("Select SoldTo");
      return false;
    }
    if (!this.selectedSalesRep) {
      this.toastr.error("Sales Rep Required");
      return false;
    }
    if (this.displayDistributorSalesRep && !this.selectedDistSalesRep) {
      this.toastr.error("Distributor Sales Rep Required");
      return false;
    }
    if (!this.selectedProduct) {
      this.toastr.error("Product Name Required");
      return false;
    }
    if (!this.selectedMinimumOrderQty) {
      this.toastr.error("Min. Order Qty. Required");
      return false;
    }
    if (!this.selectedSellPriceUOM) {
      this.toastr.error("Sell Price UoM Required");
      return false;
    }
    if (!this.selectedCustomer) {
      this.toastr.error("Account Details Required");
      return false;
    }
    if (!this.selectedState) {
      this.toastr.error("Account Region Required");
      return false;
    }
    if (!this.selectedCity) {
      this.toastr.error("Account City Required");
      return false;
    }
    return true;
  }

  checkAccountManagerValidations() {
    if (!this.selectedIndustry) {
      this.toastr.error("Industry Required");
      return false;
    }
    if (!this.selectedSegment) {
      this.toastr.error("Segment Required");
      return false;
    }
    if (!this.selectedS1Code) {
      this.toastr.error("S1 Code is Required");
      return false;
    }
    return true;
  }

  onSelectCustomer() {
    if (this.selectedCustomer.customer_id === 0) {
      this.displayCustomerPopUp = true;
    } else {
      this.constructStateDropDown();
      this.selectedCity = undefined;
    }
  }

  constructStateDropDown() {
    this.stateDD = this.masterApiResp.customerData.filter(cd => cd.customer_name === this.selectedCustomer.customer_name);
    this.stateDD = this.getUniqueListBy(this.stateDD, 'customer_region');
  }

  constructCityDropDown() {
    this.cityDD = this.masterApiResp.customerData.filter(sd => sd.customer_name === this.selectedCustomer.customer_name && sd.customer_region === this.selectedState.customer_region);
    this.cityDD = this.getUniqueListBy(this.cityDD, 'city');
  }

  addNewLocation() {
    this.newCustomer.customer_name = this.selectedCustomer.customer_name;
    this.isUpdateCustomer = true;
    this.displayCustomerPopUp = true;
  }

  addCustomer() {
    this.spinner.show();
    this.newCustomer.created_by = sessionStorage.getItem('userid');
    this.newCustomer.businessunit_id = 3;
    if (this.selectedRegion) {
      this.newCustomer.customer_region = this.selectedRegion.region_id;
    }
    this.newCustomer.distributor_sold_to_id = this.selectedSoldTo.distributor_sold_to_id;
    if (this.checkCustomerValidation()) {
      this._service.createCustomer(this.newCustomer).subscribe(_response => {
        if (!_response['isError']) {
          this._service.getMasterData(this.userId, this.selectedSoldTo.distributor_sold_to_id, 3, this.selectedSoldTo.distributor_id, this.userAdmin).subscribe(response1 => {
            this.masterApiResp = response1;
            this.generateCustomersData();
            this.selectedCustomer = this.masterApiResp.customerData.find(c => c.customer_name === this.newCustomer.customer_name && c.customer_region === this.newCustomer.customer_region && c.city === this.newCustomer.city);
            this.constructStateDropDown();
            this.selectedState = this.stateDD.find(sd => sd.customer_region === this.selectedCustomer.customer_region);
            this.constructCityDropDown();
            this.selectedCity = this.cityDD.find(cd => cd.city === this.selectedCustomer.city);
            this.formData['zip'] = this.selectedCity.zip_code;
            this.displayCustomerPopUp = false;
            this.selectedCustomer = this.customersDD.find(cdd => cdd.customer_name === this.newCustomer.customer_name);
            this.newCustomer.customer_name = '';
            this.newCustomer.address = '';
            this.newCustomer.city = '';
            this.newCustomer.customer_region = '';
            this.newCustomer.zip_code = '';
            this.spinner.hide();
            this.toastr.success("Customer Added and selected", "Success!");
          });
        } else {
          this._service.displayToastr(_response);
        }
      })
    } else {
      this.spinner.hide();
    }
  }

  checkCustomerValidation() {
    let validCustomerName = this.checkValidCustomerName();
    let validCustomerCity = this.checkValidCustomerCity();
    let validCustomerState = this.checkValidCustomerState();
    let validExistingCustomer = this.checkExistingCustomerLocation();
    return validCustomerName && validCustomerCity && validCustomerState && validExistingCustomer;
  }

  checkExistingCustomerLocation() {
    if (this.isUpdateCustomer) {
      let fCustomer = this.masterApiResp.customerData.filter(cd => cd.customer_name === this.newCustomer.customer_name && cd.customer_region === this.newCustomer.customer_region && cd.city === this.newCustomer.city);
      if (fCustomer.length > 0) {
        this.toastr.error("Customer location already exists with combination of State and City");
        return false
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  checkValidCustomerName() {
    if (this.newCustomer.customer_name != "") {
      if (this.customersDD.filter(customer => customer.customer_name.toLowerCase() === this.newCustomer.customer_name.toLowerCase()).length && !this.isUpdateCustomer) {
        this.toastr.error("Customer Name already exists", "Error!");
        return false;
      } else {
        return true;
      }
    } else {
      this.toastr.error("Customer Name required", "Error!");
      return false;
    }
  }

  checkValidCustomerAddress() {
    if (this.newCustomer.address != "") {
      return true;
    } else {
      this.toastr.error("Customer Address required", "Error!");
      return false;
    }
  }

  checkValidCustomerCity() {
    if (this.newCustomer.city != "") {
      return true;
    } else {
      this.toastr.error("Customer City required", "Error!");
      return false;
    }
  }

  checkValidCustomerState() {
    if (this.newCustomer.state != "") {
      return true;
    } else {
      this.toastr.error("Customer State required", "Error!");
      return false;
    }
  }

  checkValidCustomerZipcode() {
    if (this.newCustomer.zip_code != "") {
      return true;
    } else {
      this.toastr.error("Customer Zipcode required", "Error!");
      return false;
    }
  }

  checkValidCustomerRegion() {
    if (this.newCustomer.customer_region == "") {
      this.toastr.error("Customer Region required", "Error!");
      return false;
    }

    return true;
  }

  cancelCustomerPopUp() {
    this.displayCustomerPopUp = false;
    this.isUpdateCustomer = false;
  }


}
