import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CupsService } from 'src/services/cups.service';
import { FilterUtils } from 'primeng/api';
import * as moment from 'moment';
@Component({
  selector: 'app-dynamic-grid',
  templateUrl: './dynamic-grid.component.html',
  styleUrls: ['./dynamic-grid.component.css'],
  encapsulation:ViewEncapsulation.None
})

export class DynamicGridComponent implements OnInit {
  @Input() gridCols: any;
  @Input() gridData: any;
  @Input() checkboxShow: any;
  @Input() showEdit: any;
  @Input() showDelete: any;
  @Input() showView: any;
  @Input() showClone: any;
  @Input() showFilter: any;
  @Input() showActive: any;
  @Input() showEmail: any;
  @Input() showConEmail: any;
  @Input() showReview: any;
  @Input() dataKey: any;
  @Input() adminAction: any;
  @Input() rows: any;
  
  @Output() filterData = new EventEmitter();
  @Output() selectRow = new EventEmitter();
  @Output() sendSelectedRows = new EventEmitter();
  cupsRequestData: any;
  apiResp: any;
  defaultWidth: '100%';
  userId: any;
  userAdmin: boolean = false;
  filteredData: any;
  filteredFields = [];
  tempFilteredData: any;
  title = "Cups Request";
  selectedRows: any;
  accountManager: any;
  bu:any;
  filters = [];
  orgGridData: any;
  orgCols: any;
  isDistributor: boolean = false;
  
  constructor(private _cupsService: CupsService, private router: Router,private spinner: NgxSpinnerService) { console.log(this.gridCols);}

  ngOnInit() {
    if (this.rows === undefined || this.rows === null) {
      this.rows = 10;
    }
    this.orgGridData =JSON.parse(JSON.stringify(this.gridData));
    this.accountManager = sessionStorage.getItem('loginuseremail');
    this.bu = sessionStorage.getItem('BU');
    this.isDistributor = (sessionStorage.getItem('isDistributor') === "1" || sessionStorage.getItem('isDistributor') === "true") ? true : false;
    FilterUtils['custom'] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return parseInt(filter) > value;
    }
    this.orgCols = this.sortSelectedColumns(this.gridCols);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.gridCols) {
      this.orgCols = this.sortSelectedColumns(changes.gridCols['currentValue']);
    }
  }

  sortSelectedColumns(columsnData) {
    return columsnData.sort((a, b) => {
      return a.serial_no - b.serial_no;
    })
  }

  reOrderColumns(event: any) {
    event.columns.forEach((col: any) => {
      col.serial_no = event.columns.findIndex(x => x.header_id == col.header_id) + 1;
      let colData = event.columns;
      let ColObject = { colData, type: 'ColReorder' };
      this.selectRow.emit(ColObject)
    });
  }

  sendFilterData(value, filter) {
    let filterObj = {
      value, filter
    };
    this.filterData.emit(filterObj);
  }

  onSelectRow(rowData: any, type ) {
    let rowObject = {rowData, type};
    this.selectRow.emit(rowObject);
  }

  onDeleteRow(rowData: any, type ) {
    let rowObject = {rowData, type};
    this.selectRow.emit(rowObject);
  }

  checkSelectedRow(_event) {
    this.sendSelectedRows.emit(this.selectedRows)
  }

  checkDateField(fieldName) {
     if(fieldName.includes('_date')) {
       return true;
     }
     return false;
  }

  buildFilter(event, fieldName) {
    let filterObj = {
      fieldName: fieldName,
      value: event.target.value
    };
    let fFieldName = this.filters.find(f => f.fieldName === fieldName);
    if (!fFieldName) {
      if (filterObj.value != "") {
        this.filters.push(filterObj);
      }
      else {
        this.filters = this.filters.filter(f => f.fieldName === fieldName);
      }
    } else {
      if (filterObj.value != "") {
        this.filters.forEach(f1 => {
          if (f1.fieldName === fieldName) {
            f1.value = event.target.value;
          }
        })
      } else {
        this.filters = this.filters.filter(f => f.fieldName !== fieldName);
      }
    }
    this.sortGridData();
  }

  sortGridData() {
    let tempOrgGridData = JSON.parse(JSON.stringify(this.orgGridData));
    this.filters.forEach(filter => {
      tempOrgGridData = tempOrgGridData.filter(f => moment(f[filter.fieldName]).format('L') === moment(filter.value).format('L'));
    });
    this.gridData = JSON.parse(JSON.stringify(tempOrgGridData));
  }

  
}
