import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {  tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
@Injectable()

export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService
    ) {}
 
  private router:Router 
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let tokenInfo = sessionStorage.getItem('jwtToken');

    if (!request.url.includes('add')) {
      if (!request.headers.has('Content-Type')) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        request = request.clone({ headers: request.headers.set('Cache-Control', 'no-cache') });
        request = request.clone({ headers: request.headers.set('Pragma', 'no-cache') });
        request = request.clone({ headers: request.headers.set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT') });
        request = request.clone({ headers: request.headers.set('If-Modified-Since', '0') });
      }
      request = request.clone({
        headers: request.headers.set('Access-Control-Allow-Origin', ['https://localhost:44381', 'https://distributorconnect-qa.basf.com', 'https://distributorconnect-dev.basf.com', 'https://distributorconnect.basf.com', 'https://federation-qa.basf.com', 'https://federation.basf.com']),
      });
    }
    if(tokenInfo!=null)
      request = request.clone({ headers: request.headers.set('Authorization', `${tokenInfo}`) });

  let cooval=this.cookieService.get("Authorizarion");
  if(cooval!="" && cooval!=null && cooval!=undefined)
  {
    if(cooval.length>0)
    {
    request = request.clone({ headers: request.headers.set('Authorization', cooval.replace("Bearer ","").toString()) });
    }
  }
  else{
    if (document.location.hostname == "localhost") {
      request = request.clone({ headers: request.headers.set('Authorization', `${tokenInfo}`) });  //btoa("IllaS")
    }
    /*if (document.location.hostname == "10.201.72.112") { uncomment if needed
      request = request.clone({ headers: request.headers.set('Authorization', `${tokenInfo}`) });  //btoa("IllaS") 
    } uncomment if needed */
  }
    console.log("Before making api call : ", request);
    return next.handle(request).pipe(
      tap(
        event => {
          this.eventFunction(event);
        },
        error => {
          this.errorFunction(error);
        },

      )
    );
  }

  eventFunction(event: any) {
    if (event instanceof HttpResponse) {
      console.log("api call success :", event);
    }
  }

  errorFunction(error: any) { 
    if (error instanceof HttpErrorResponse) {
      console.log("api call error :", error);
      console.log("api call error status:", error.status);
      if (error.status == 401 || error.status == 0) {
        this.router.navigate(['/home']);
      }
    }
  }

}
