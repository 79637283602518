import { Component } from '@angular/core';

@Component({
  selector: 'app-price-history-revision',
  templateUrl: './price-history-revision.component.html',
  styleUrls: ['./price-history-revision.component.css']
})
export class PriceHistoryRevisionComponent {
  receivePriceData(priceData) {
    console.log(priceData);
  }
}
