import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CupsService } from 'src/services/cups.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AccountDetailsComponent implements OnInit {

  constructor(private service: CupsService, private confirmationService: ConfirmationService) { }
  displayPopUp = false;
  cols = [{ field: 'customerID', header: 'Customer ID' },
  { field: 'customerName', header: 'Customer Name' },
  { field: 'region', header: 'Customer Region' },
  { field: 'city', header: 'Customer City' }
  ];
  gridData = [];
  allAccounts = [];
  allCities = [];
  allRegions = [];
  filteredAccounts = [];
  filteredCities = [];
  filteredRegions = [];
  isEdit = false;
  accountDetailsObj = {
    customerID: 0,
    customerName: '',
    region: '',
    city: '',
    userID: 0
  };
  ngOnInit() {
    this.getAllAccountDetails();

  }
  getAccountData() {
    this.service.getAccountData().subscribe(res => {
      if (!res['isError']) {
        let accounts = res['response']['table'];
        let cities = res['response']['table2'];
        let regions = res['response']['table1'];
        this.allAccounts = [];
        this.allCities = [];
        this.allRegions = [];
        accounts.forEach(element => {
          this.allAccounts.push(element.customerName);
        });
        cities.forEach(element => {
          this.allCities.push(element.city);
        });
        regions.forEach(element => {
          this.allRegions.push(element.regions);
        });
      }
      else {
        this.service.displayToastr(res);
      }


    }, error => {
      this.service.displayToastr(error);
    })
  }

  getAllAccountDetails() {
    this.service.getAccountDetails().subscribe(res => {
      if (!res['isError']) {
        this.gridData = res['response'];
        this.getAccountData();
      }
      else {
        this.service.displayToastr(res);
      }
    });
  }

  filterAccount(event) {
    let filtered: any[] = [];
    let query = event.query;

    for (let account of this.allAccounts) {
      if (account.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(account);
      }
    }
    this.filteredAccounts = filtered;
  }

  filterRegion(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let region of this.allRegions) {
      if (region.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(region);
      }
    }
    this.filteredRegions = filtered;
  }

  filterCity(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let city of this.allCities) {
      if (city.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(city);
      }
    }
    this.filteredCities = filtered;
  }

  onEdit(data) {

    this.accountDetailsObj.customerID = data.customerID;
    this.accountDetailsObj.customerName = data.customerName;
    this.accountDetailsObj.region = data.region;
    this.accountDetailsObj.city = data.city;
    this.isEdit = true;
    this.displayPopUp = true;

  }

  onDelete(data) {
    this.accountDetailsObj.customerID = data.customerID;
    this.accountDetailsObj.customerName = data.customerName;
    this.accountDetailsObj.region = data.region;
    this.accountDetailsObj.city = data.city;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this Account?',
      accept: () => {
        this.service.deleteAcccountDetails(this.accountDetailsObj).subscribe(res => {
          if (!res['isError']) {
            this.service.displayToastr(res);
            this.getAllAccountDetails();
            this.onClose();
          } else {
            this.service.displayToastr(res);
          }
        }, error => {
          this.service.displayToastr(error);
        });
      }
    });
  }

  addNewAccount() {

    this.resetObj();
    this.displayPopUp = true;

  }

  onClose() {
    this.displayPopUp = false;
    this.resetObj();
    this.isEdit = false;
  }

  resetObj() {
    this.accountDetailsObj = {
      customerID: 0,
      customerName: '',
      region: '',
      city: '',
      userID: 0
    };
  }
  saveAccount() {
    if (this.isEdit) {
      this.service.updateAccountDetails(this.accountDetailsObj).subscribe(res => {
        if (!res['isError']) {
          this.service.displayToastr(res);
          this.getAllAccountDetails();
        } else {
          this.service.displayToastr(res);
        }
      }, error => {
        this.service.displayToastr(error)
      });
    } else {
      this.service.insertAccountDetails(this.accountDetailsObj).subscribe(res => {
        if (!res['isError']) {
          this.service.displayToastr(res);
          this.getAllAccountDetails();
        } else {
          this.service.displayToastr(res);
        }
      }, error => {
        this.service.displayToastr(error)
      })
    }
    this.onClose();
  }

}
