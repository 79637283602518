import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cup-request-tab',
  templateUrl: './cup-request-tab.component.html',
  styleUrls: ['./cup-request-tab.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CupRequestTabComponent implements OnInit {
  labelData: any;
  orgRowData: any;
  formData: any;
  gridData: any;
  tabData = [];
  cupReqData: any;
  cupReqDatas = [];
  selIndex;
  constructor(private router: Router) { }

  ngOnInit() {
    if (!history.state.data) {
      this.router.navigate(['/']);
    } else {
      sessionStorage.setItem('returnUrlparams','');
        sessionStorage.setItem('paramsExist', 'false');
        sessionStorage.setItem('returnUrlpath','');
      this.labelData = history.state.data.labelData;
      this.orgRowData = history.state.data.rowData;
      this.formData = JSON.parse(JSON.stringify(this.orgRowData));
      this.gridData = history.state.data.gridData;
      this.cupReqData = {
        labelData: this.labelData,
        formData: this.formData
      }
      this.selIndex = this.formData.request_reference_number;
    }
  }

  filterCupRequest(cupReqRef) {
    let fCupReqTab = false;
    if(this.tabData.length) {
      this.tabData.forEach(td => {
        if(td.formData['request_reference_number'] === cupReqRef.request_reference_number ) {
          fCupReqTab = true;
          this.selIndex = td.formData['request_reference_number'];
        }
      })
    }
    if (cupReqRef && !fCupReqTab && cupReqRef.request_reference_number !== this.formData.request_reference_number) {
      let cupReqObj = { 
        labelData: this.labelData,
        formData: cupReqRef,
        index: this.tabData.length + 1
      };
      this.tabData.push(cupReqObj);
      this.selIndex = cupReqRef.request_reference_number;
    }
    if(cupReqRef.request_reference_number === this.formData.request_reference_number) {
      this.selIndex = cupReqRef.request_reference_number;
    }
  }

  closeCupRequest(event) {
    this.tabData = this.tabData.filter(td => td.index !== event.index);
  }

  goBack() {
    this.router.navigate(['/home']);
  }
}
