import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CupsService } from 'src/services/cups.service';

@Component({
  selector: 'app-login-test',
  templateUrl: './login-test.component.html',
  styleUrls: []
})
export class LoginTestComponent implements OnInit {
  returnUrl: string;
  returnUrlpath: string;
  user = "";
  usersData: any;
  buUsersData: any;
  userId: any;
  tokenInfo: any;
  loginAudiInfo: any;
  constructor(private router: Router, private _service: CupsService, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    //sessionStorage.clear();
    this.spinner.show();
    this._service.getUsers().subscribe(response => {
      if (!response['isError']) {
        this.usersData = response['lstUsers'].filter(u => u.is_active);
        this.buUsersData = response['lstuserBu'];
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this._service.displayToastr(response);
      }
    });
  }

  loginbtn() {
    this._service.getUserDetailsById(this.user).subscribe(response => {
      if (!response['isError']) {
        this.usersData = response['lstUsers'][0];
        if (this.usersData) {
          sessionStorage.setItem('loginuser', this.usersData.first_name);
          sessionStorage.setItem('loginuseremail', this.usersData.email);
          sessionStorage.setItem('jwtToken', btoa(this.usersData.email));
          sessionStorage.setItem('UserToken', this.usersData);
          //if (this.usersData.businessunit_id == 1 || this.usersData.businessunit_id == 4) {
            sessionStorage.setItem('SalesSupportDistributor', '0');
          //}
          this._service.sendAdminValue(this.usersData.is_admin);
          sessionStorage.setItem('userid', this.usersData.user_id);
          sessionStorage.setItem('isDistributor', this.usersData.is_distributor_user);
          sessionStorage.setItem('isSalesSupport', this.usersData.is_sales_support);
          if (this.usersData.is_distributor_user) {
            sessionStorage.setItem('IsAdmin', 'false');
          }
          else {
            sessionStorage.setItem('IsAdmin', this.usersData.is_admin);
          }
          sessionStorage.setItem('isSalesRep', this.usersData.is_sales_rep);
          this._service.sendDistributorValue(this.usersData.is_distributor_user);
          let sessionObject = {
            isAdmin: this.usersData.is_admin,
            isDistributor: this.usersData.is_distributor_user,
            loginUser: sessionStorage.getItem('loginuser')            
          };
          this._service.sendSessionObject(sessionObject);

          switch (this.usersData.businessunit_id) {
            case 1: sessionStorage.setItem('BU', 'EM'); break;
            case 2: sessionStorage.setItem('BU', 'EV'); break;
            case 3: sessionStorage.setItem('BU', 'PM'); break;
            case 4: sessionStorage.setItem('BU', 'ED'); break;
            default: sessionStorage.setItem('BU', 'EM'); break;
          }
          this._service.sendSalesSupport(this.usersData.is_sales_support);
          this._service.sendSalesRep(this.usersData.is_sales_rep);
          this._service.sendBusinessUnit(sessionStorage.getItem('BU'));
          this._service.sendUserValue(sessionStorage.getItem('userid'));
          this.returnUrl = sessionStorage.getItem('returnUrl');
          if (this.returnUrl == undefined || this.returnUrl == '') {
            this.router.navigate(['/home']);
          }
          else {
            this.returnUrl = decodeURIComponent(this.returnUrl);
            this.returnUrlpath = sessionStorage.getItem('returnUrlpath');
            this.router.navigateByUrl(this.returnUrl);
          }
        } else {
          this.router.navigate(['error'])
        }
      } else {
        this._service.displayToastr(response);
      }
    });
  }
}
