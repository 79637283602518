import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CupsService } from 'src/services/cups.service';
@Component({
  selector: 'app-sales-support',
  templateUrl: './sales-support.component.html',
  styleUrls: ['./sales-support.component.css']
})
export class SalesSupportComponent implements OnInit {
  BusinessUnitData: any;
  CountryData: any;
  RegionData: any;
  response: any;
  BusinessUnit: any;
  SBusinessUnit: any;
  selectedSBusiness: any;
 // selectedBusiness: any;
  cols: any;
  selectedValues: any
  UsersData = [];
  Bu_user: any;
  AllUsersData: any;
  filteredFields = [];
  usersData: any;
  filteredData: any;
  tempFilteredData: any;
  GridData: any;
  selectedRegion: any;
  selectedCountry: any;
  selectedBusiness: any;
  selectedSBU: any;
  salesSupportUsers: any;
  pricingAdminUsers: any;
  selectedSalesSupportUsers: any;
  selectedPricingAdminUsers: any;

  constructor(private _service: CupsService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }
  ngOnInit() {
    this.spinner.show();
    this.cols = [
      {
        "header_id": 2,
        "field": "userName",
        "header": "User Name",
        "is_show": true,
        "is_editable": false,
        "serial_no": 2
      },
      {
        "header_id": 3,
        "field": "emailId",
        "header": "Email ID",
        "is_show": true,
        "is_editable": false,
        "serial_no": 3
      },
      {
        "header_id": 4,
        "field": "sbuName",
        "header": "SBU",
        "is_show": true,
        "is_editable": false,
        "serial_no": 4
      },
      {
        "header_id": 5,
        "field": "regionName",
        "header": "Region",
        "is_show": true,
        "is_editable": false,
        "serial_no": 5
      },
      {
        "header_id": 6,
        "field": "countryName",
        "header": "Country",
        "is_show": true,
        "is_editable": false,
        "serial_no": 6
      },
      {
        "header_id": 7,
        "field": "type",
        "header": "Type",
        "is_show": true,
        "is_editable": false,
        "serial_no": 7
      },
      {
        "header_id": 8,
        "field": "businessUnitName",
        "header": "Bussiness Unit",
        "is_show": true,
        "is_editable": false,
        "serial_no": 8
      }]

    this._service.getAdminConfigMaster().subscribe((res1) => {
      this.spinner.hide();
      if (!res1['isError']) {
        this.response = res1;
        this.BusinessUnitData = this.response.buData;
        this.CountryData = this.response.countryData;
        this.RegionData = this.response.regionData;
        let key = 'businessunit_id';
        this.BusinessUnit = [...new Map(this.BusinessUnitData.map(item =>
          [item[key], item])).values()];
        const currentBU = sessionStorage.getItem("BU");
        this.BusinessUnit.forEach(bu => {
          if (bu.businessunit_name === currentBU) {
            this.selectedBusiness = bu.businessunit_id;
            this.onBusinessChange(this.selectedBusiness);
            this.getBuUser(this.selectedBusiness);
            this.getAllSaleSupportPricingAdmin(this.selectedBusiness);
          }
        });
      } else {
        this._service.displayToastr(res1);
      }
    })
  }

  getAllSaleSupportPricingAdmin(id) {
    this.spinner.show();
    this._service.getAllSaleSupportPricingAdmin(id).subscribe((res) => {
      this.spinner.hide();
      if (!res['isError']) {
        this.UsersData = res['response'];
        this.GridData = this.UsersData;
      } else {
        this._service.displayToastr(res);
      }
    })
  }

  onBusinessChange(event) {
    if (this.BusinessUnitData != undefined) {
      this.SBusinessUnit = this.BusinessUnitData.filter(i => i.businessunit_id == event);
    }
  }

  onSBusinessChange(newValue) {
    console.log(newValue);
  }

  getBuUser(value) {
    let bu_id = value;
    this._service.getBUUsers(bu_id).subscribe((res) => {
      if (!res['isError']) {
        this.Bu_user = this.getUniqueListBy(res['lstUsers'], 'user_name');
      } else {
        this._service.displayToastr(res);
      }
    })
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  submitData() {
    let data = {
      "region_id": this.selectedRegion.region_id,
      "country_id": this.selectedCountry.country_id,
      "business_unit": this.selectedBusiness,
      "sbu": this.selectedSBU.request_master_id,
      "salesSupport_ids": [
      ],
      "pricingAdmin_ids": [
      ]
    }
    if(this.selectedBusiness != 1 && this.selectedBusiness != 4) {
      this.selectedSalesSupportUsers.forEach(x => {
        data.salesSupport_ids.push(x.user_id);
      });
    }
    this.selectedPricingAdminUsers.forEach(x => {
      data.pricingAdmin_ids.push(x.user_id);
    });
    this._service.postSalesSupportUsers(data).subscribe(response => {
      if (!response['isError']) {
        this.spinner.show();
        this.selectedRegion = null;
        this.selectedCountry = null;
        this.selectedSBU = null;
        this.selectedSalesSupportUsers = null;
        this.selectedPricingAdminUsers = null;
        this.getAllSaleSupportPricingAdmin(this.selectedBusiness);
        this._service.displayToastr(response);
      } else {
        this._service.displayToastr(response);
      }
    })
  }

  filterData(filterObj) {
    let { value, filter } = filterObj;
    if (value == '') {
      if (this.filteredFields.length) {
        this.filteredFields = this.filteredFields.filter(ff => ff.fieldName !== filter);
      } else {
        this.filteredFields = [];
      }
    } else {
      let foundField = this.filteredFields.filter(ff => ff.fieldName == filter);
      if (foundField.length) {
        this.filteredFields.forEach(ff => {
          if (ff.fieldName == filter) {
            ff.value = value;
          }
        })
      } else {
        let fieldObj = {
          fieldName: filter,
          value: value
        };
        this.filteredFields.push(fieldObj);
      }
    }
    if (this.filteredFields.length) {
      let temp = this.usersData;
      this.filteredFields.forEach(ff => {
        temp = temp.filter(fd => fd[ff.fieldName].toUpperCase().includes(ff.value.toUpperCase()));
      })
      this.filteredData = temp;
    } else {
      this.filteredData = JSON.parse(JSON.stringify(this.usersData));
    }
    this.tempFilteredData = JSON.parse(JSON.stringify(this.filteredData));
  }

  onChangeSBU(_event1) {
    if(this.selectedCountry){
      this.getCountryUsers();
      this.getUsersBasedOnSBUCountrySelection();
    }
  }

  onChangeSalesSupportUsers() {
    console.log(this.selectedSalesSupportUsers);
  }

  onChangePricingAdminUsers() {
    console.log(this.selectedPricingAdminUsers);
  }

  getCountryUsers() {
    this.salesSupportUsers = this.Bu_user;
    this.pricingAdminUsers = this.Bu_user;
    if(this.selectedSBU) {
      this.getUsersBasedOnSBUCountrySelection();
    }
  }

  getUsersBasedOnSBUCountrySelection() {
    let tempSalesSupportUsers = [];
    let tempPricingAdminUsers = [];
    this.GridData.forEach(gd => {
      if (gd.sbuName === this.selectedSBU.value && gd.type === 'SalesSupport' ){
        let fSalesSupportUser = this.salesSupportUsers.find(bu1 => bu1.user_id === gd.userId );
        if (fSalesSupportUser) {
          tempSalesSupportUsers.push(fSalesSupportUser);
        }
      }
      if (gd.sbuName === this.selectedSBU.value && gd.type === 'PricingAdmin'){
        let fPricingAdminUser = this.pricingAdminUsers.find(bu1 => bu1.user_id === gd.userId );
        if (fPricingAdminUser) {
          tempPricingAdminUsers.push(fPricingAdminUser);
        }
      }
    });
    this.assignTempDataToSalesSupportPricingAdmin(tempSalesSupportUsers, tempPricingAdminUsers);
  }

  assignTempDataToSalesSupportPricingAdmin(ssUsers: any[], paUsers: any[]) {
    if (ssUsers.length) {
      this.selectedSalesSupportUsers = JSON.parse(JSON.stringify(this.getUniqueListBy(ssUsers,'user_name')));
    }
    if (paUsers.length) {
      this.selectedPricingAdminUsers = JSON.parse(JSON.stringify(this.getUniqueListBy(paUsers,'user_name')));
    }
  }
}
