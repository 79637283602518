import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  islogin: boolean = false;
  username: string;

  adminAccess: boolean = false;
  loginvalue: string;
  responsefromJWT: any;
  expireDate: Date;
  logout: any;
  params: { Username: string; Email: string; IsAdmin: string; userid:number};

  ngOnInit() {
    if (sessionStorage.getItem("logout") != undefined) {
      this.logout = JSON.parse(sessionStorage.getItem("logout"))
    }
  }
  
}
