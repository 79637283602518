import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CupsService } from 'src/services/cups.service';

@Component({
  selector: 'app-distributor-view',
  templateUrl: './distributor-view.component.html',
  styleUrls: ['./distributor-view.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DistributorViewComponent implements OnInit {
  @Input() labelData: any;
  @Input() orgRowData: any;
  @Output() backToGrid = new EventEmitter();
  apiResp: any;
  formData: any;
  productsData: any;
  shipToData: any;
  sbuData: any;
  usersData: any;
  salesRepData: any;
  industries: any;
  businessName: any;
  salesSupportUsers: any;
  adminAndPricingSupportMappings: any;
  constructor(private router: Router, private _service: CupsService, private spinner: NgxSpinnerService) {
   }

  ngOnInit() {
    this.labelData = JSON.parse(JSON.stringify(this.labelData.filter(ld => ld.is_show)));
    this.formData = JSON.parse(JSON.stringify(this.orgRowData));
    this.businessName = sessionStorage.getItem("BU");
    this.getDistributorDetails();
  }

  getDistributorDetails() {
    this.spinner.show();
    this._service.getDistributorDetails(this.orgRowData['distributor_id']).subscribe(response => {
      this.spinner.hide();
      if (!response['isError']) {
        this.apiResp = response;
        this.productsData = this.apiResp['distributorProductsMappingData'];
        this.shipToData = this.apiResp['distributorShipToData'];
        this.sbuData = this.apiResp['distributorSbuData'];
        this.usersData = this.apiResp['distributorUserDetailsData'];
        if (this.businessName == 'ED') {
          this.salesRepData = this.apiResp['salesRepEmailData'].filter(x => x.distributor_region_id > 0);
        } else {
          this.salesRepData = this.apiResp['salesRepEmailData'];
        }
        this.industries = this.apiResp['salesRepEmailData'].filter(x=>x.industry_id > 0);
        this.salesSupportUsers = this.apiResp['distributorSalesSupport'];
        this.adminAndPricingSupportMappings = this.apiResp["adminAndPricingSupportMappings"];

        this.salesSupportUsers.forEach(x => {
          let sbu;
          this.sbuData.forEach((y) => { if (y.distributor_sbu_id === x.sbu_id) { sbu = y} });
          x.sbuName = sbu.distributor_sbu_name;
        });

        if (this.businessName == 'ED') {
          this.adminAndPricingSupportMappings.forEach(x => {
            let industry;
            this.industries.forEach(y => { if (y.industry_id === x.industryId) { industry = y } });
            x.industryName = industry.industry_name;
          });
        } else {
          this.adminAndPricingSupportMappings.forEach(x => {
            let industry;
            this.salesRepData.forEach(y => { if (y.industry_id === x.industryId) { industry = y } });
            x.industryName = industry.industry_name;
          });
        }

      } else {
        this._service.displayToastr(response);
      }
    })
  }

  goBack() {
    this.backToGrid.emit(true);
  }
}

