import { Directive, ElementRef} from "@angular/core";

@Directive({
  selector: '[lineSeparator]'
})
export class LineSeparatorDirective {

  constructor(private eleRef: ElementRef) {
    eleRef.nativeElement.style.background = 'red';
  }

  
}