import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CupsService } from 'src/services/cups.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  selectedBU: any;
  isExpanded: boolean;
  IsAdmin: boolean;
  isDistributorUser: boolean;
  logInUser: string;
  isSalesSupport: boolean;
  isSalesRepo: boolean;
  loginUserType = '0';
  distributors: any;
  userId: any;
  isInternalUserAsDistributor = false;
  constructor(private router: Router, private service: CupsService) {
    console.log("header Constructor Initiated");
    this.service.getSalesSupport().subscribe(sales => {
      this.isSalesSupport = (sales == 'true' || sales) ? true : false;
    })
    this.service.getSalesRep().subscribe(salesrep => {
      this.isSalesRepo = (salesrep == 'true' || salesrep) ? true : false;
    })
    this.service.getBusinessUnit().subscribe(bu => {
      this.selectedBU = bu;
    })
    this.service.getUserValue().subscribe(id => {
      this.userId = parseInt(id);
      if ((this.selectedBU == 'EM' || this.selectedBU == 'ED') && (this.isSalesRepo || this.isSalesSupport)) {
        this.service.getSalesSupportDistributors(this.userId, this.isSalesSupport, this.isSalesRepo, this.selectedBU).subscribe(response => {
          this.distributors = response['response'];
          if (sessionStorage.getItem('SalesSupportDistributor') != '0') {
            this.loginUserType = this.distributors.filter(x => x.distributor_id == parseInt(sessionStorage.getItem('SalesSupportDistributor'), 0))[0].distributor_id.toString();
          }
        });
      }
    })
  }

  ngOnInit() {
    this.service.getSessionObject().subscribe(response => {
      this.isDistributorUser = response['isDistributor'];
      if (this.isDistributorUser) {
        this.IsAdmin = false;
      } else {
        this.IsAdmin = response['isAdmin'];
      }
      this.logInUser = response['loginUser'];
      if (!this.logInUser) {
        this.logInUser = sessionStorage.getItem('loginuser');
      }
    });
    if (this.IsAdmin === undefined) {
      this.IsAdmin = sessionStorage.getItem('IsAdmin') == 'true' ? true : false;
      this.service.sendAdminValue(this.IsAdmin);
    }
  }

  changeUser(value) {
    this.isInternalUserAsDistributor = value == 0 ? false : true;
    sessionStorage.setItem('SalesSupportDistributor', value);
    this.router.navigate(['/home']);
  }

}
