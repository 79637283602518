import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value !== undefined && value !== null && value !== '') {
    let x = `$${value}`;
    return x;
    } else {
        return '0';
    }
  }
}
