import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CupsService } from 'src/services/cups.service';
@Component({
  selector: 'app-product-manager',
  templateUrl: './product-manager.component.html',
  styleUrls: ['./product-manager.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProductManagerComponent implements OnInit {
  BusinessUnitData: any;
  SBusinessUnit: any;
  response: any;
  CountryData: any;
  RegionData: any;
  BusinessUnit: any;
  products: any;
  filteredFields = [];
  usersData =[];
  filteredData: any;
  tempFilteredData: any;
  Bu_user: any;
  filteredBU_users: any;
  currentBU: string = sessionStorage.getItem("BU");
  displayProductInfoPopup: boolean = false;
  massChangePMPopup: boolean = false;
  massChangeCurrentPM: any;
  massChangeNewPM: any;
  currentProductsManagesList: any;
  productInfo: Object;
  activeStatus: any = [
    {
      name: "Active",
      value: true
    },
    {
      name: "IN-Active",
      value: false
    }
  ];
  currentBusinessUnit: any;
  selectedRegion: any;
  selectedCountry: any;
  cols: any
  selectedBusiness: any;
  emCols: any;
  evCols: any;
  pmCols: any;
  edCols: any;
  bu: any;
  EVID = 2;
  EMID = 1;
  apiResp: any;
  PMID = 3;
  EDID = 4;
  countryData =[
    {
        "country_id": 1,
        "country_name": "US"
    },
    {
        "country_id": 2,
        "country_name": "Mexico"
    },
    {
        "country_id": 3,
        "country_name": "Canada"
    }
];
productsDD: any;
productPackageDD: any;
selectedProductPackage: any;
selectedProductManager: any;

  constructor(private _service: CupsService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit() {
    this.bu = sessionStorage.getItem('BU');
    this.evCols = [
      {
        "header_id": 1,
        "field": "product_id",
        "header": "Product Id",
        "is_show": false,
        "is_editable": false,
        "serial_no": 1,
        "isHyperlink": false
      },
      {
        "header_id": 2,
        "field": "product_name",
        "header": "Product Name",
        "is_show": true,
        "is_editable": false,
        "serial_no": 2,
        "isHyperlink": this.currentBU === "EV",
      },
      {
        "header_id": 2,
        "field": "package_type",
        "header": "Product Type",
        "is_show": true,
        "is_editable": false,
        "serial_no": 2,
        "isHyperlink": false,
      },
      {
        "header_id": 3,
        "field": "product_managerName",
        "header": "Product Manager",
        "is_show": true,
        "is_editable": false,
        "serial_no": 3,
        "isHyperlink": false
      },
      {
        "header_id": 4,
        "field": "article_number",
        "header": "Article Number",
        "is_show": true,
        "is_editable": false,
        "serial_no": 4,
        "isHyperlink": false
      },
      {
        "header_id": 5,
        "field": "threshold_volume",
        "header": "Threshold Volume",
        "is_show": true,
        "is_editable": false,
        "serial_no": 5,
        "isHyperlink": false
      },
      {
        "header_id": 6,
        "field": "threshold_price_discount",
        "header": "Threshold Price Discount",
        "is_show": true,
        "is_editable": false,
        "serial_no": 6,
        "isHyperlink": false
      },
      {
        "header_id": 7,
        "field": "target_price",
        "header": "Target Price",
        "is_show": true,
        "is_editable": false,
        "serial_no": 7,
        "isHyperlink": false
      },
      {
        "header_id": 8,
        "field": "distributor_price_from_basf",
        "header": "Distributor Price From Basf",
        "is_show": true,
        "is_editable": false,
        "serial_no": 8,
        "isHyperlink": false
      },
      {
        "header_id": 9,
        "field": "valid_from",
        "header": "Product Valid From",
        "is_show": true,
        "is_editable": false,
        "serial_no": 9,
        "isHyperlink": false
      },
      {
        "header_id": 10,
        "field": "valid_to",
        "header": "Product Valid To",
        "is_show": true,
        "is_editable": false,
        "serial_no": 10,
        "isHyperlink": false
      },
      {
        "header_id": 11,
        "field": "minimum_quantity",
        "header": "Minimum Quantity",
        "is_show": true,
        "is_editable": false,
        "serial_no": 11,
        "isHyperlink": false
      },
      {
        "header_id": 12,
        "field": "is_active",
        "header": "Status",
        "is_show": false,
        "is_editable": false,
        "serial_no": 12,
        "isHyperlink": false
      },
    ];
    this.emCols = [
      {
        "header_id": 1,
        "field": "product_id",
        "header": "Product Id",
        "is_show": false,
        "is_editable": false,
        "serial_no": 1,
        "isHyperlink": false
      },
      {
        "header_id": 2,
        "field": "product_name",
        "header": "Product Name",
        "is_show": true,
        "is_editable": false,
        "serial_no": 2,
        "isHyperlink": this.currentBU === "EV",
      },
      {
        "header_id": 2,
        "field": "package_type",
        "header": "Product Type",
        "is_show": true,
        "is_editable": false,
        "serial_no": 2,
        "isHyperlink": false,
      },
      {
        "header_id": 3,
        "field": "product_managerName",
        "header": "Product Manager",
        "is_show": true,
        "is_editable": false,
        "serial_no": 3,
        "isHyperlink": false
      },
      {
        "header_id": 4,
        "field": "article_number",
        "header": "Article Number",
        "is_show": true,
        "is_editable": false,
        "serial_no": 4,
        "isHyperlink": false
      },
      {
        "header_id": 9,
        "field": "distributor_price_from_basf",
        "header": "Distributor Price From Basf",
        "is_show": true,
        "is_editable": false,
        "serial_no": 9,
        "isHyperlink": false
      },
      {
        "header_id": 10,
        "field": "valid_from",
        "header": "Product Valid From",
        "is_show": true,
        "is_editable": false,
        "serial_no": 10,
        "isHyperlink": false
      },
      {
        "header_id": 11,
        "field": "valid_to",
        "header": "Product Valid To",
        "is_show": true,
        "is_editable": false,
        "serial_no": 11,
        "isHyperlink": false
      },
      
      {
        "header_id": 12,
        "field": "incoTerms",
        "header": "INCOTERMS",
        "is_show": true,
        "is_editable": false,
        "serial_no": 12,
        "isHyperlink": false
      },
      {
        "header_id": 13,
        "field": "sbu_name",
        "header": "SBU Name",
        "is_show": true,
        "is_editable": false,
        "serial_no": 13,
        "isHyperlink": false
      },
      {
        "header_id": 14,
        "field": "sold_To",
        "header": "Sold To / Hierarchy",
        "is_show": true,
        "is_editable": false,
        "serial_no": 14,
        "isHyperlink": false
      },
      {
        "header_id": 15,
        "field": "distributor_Name",
        "header": "Distributor",
        "is_show": true,
        "is_editable": false,
        "serial_no": 15,
        "isHyperlink": false
      },
      {
        "header_id": 16,
        "field": "is_active",
        "header": "Status",
        "is_show": false,
        "is_editable": false,
        "serial_no": 16,
        "isHyperlink": false
      },
    ];
    this.edCols = [
      {
        "header_id": 1,
        "field": "product_id",
        "header": "Product Id",
        "is_show": false,
        "is_editable": false,
        "serial_no": 1,
        "isHyperlink": false
      },
      {
        "header_id": 2,
        "field": "product_name",
        "header": "Product Name",
        "is_show": true,
        "is_editable": false,
        "serial_no": 2,
        "isHyperlink": this.currentBU === "EV",
      },
      {
        "header_id": 2,
        "field": "package_type",
        "header": "Product Type",
        "is_show": true,
        "is_editable": false,
        "serial_no": 2,
        "isHyperlink": false,
      },
      {
        "header_id": 3,
        "field": "product_managerName",
        "header": "Product Manager",
        "is_show": true,
        "is_editable": false,
        "serial_no": 3,
        "isHyperlink": false
      },
      {
        "header_id": 4,
        "field": "article_number",
        "header": "Article Number",
        "is_show": true,
        "is_editable": false,
        "serial_no": 4,
        "isHyperlink": false
      },
      {
        "header_id": 9,
        "field": "distributor_price_from_basf",
        "header": "Distributor Price From Basf",
        "is_show": true,
        "is_editable": false,
        "serial_no": 9,
        "isHyperlink": false
      },
      {
        "header_id": 10,
        "field": "valid_from",
        "header": "Product Valid From",
        "is_show": true,
        "is_editable": false,
        "serial_no": 10,
        "isHyperlink": false
      },
      {
        "header_id": 11,
        "field": "valid_to",
        "header": "Product Valid To",
        "is_show": true,
        "is_editable": false,
        "serial_no": 11,
        "isHyperlink": false
      },

      {
        "header_id": 12,
        "field": "incoTerms",
        "header": "INCOTERMS",
        "is_show": true,
        "is_editable": false,
        "serial_no": 12,
        "isHyperlink": false
      },
      {
        "header_id": 13,
        "field": "sbu_name",
        "header": "SBU Name",
        "is_show": true,
        "is_editable": false,
        "serial_no": 13,
        "isHyperlink": false
      },
      {
        "header_id": 14,
        "field": "is_active",
        "header": "Status",
        "is_show": false,
        "is_editable": false,
        "serial_no": 164,
        "isHyperlink": false
      },
    ];
    this.pmCols = [
      {
        "header_id": 1,
        "field": "product_id",
        "header": "Product Id",
        "is_show": false,
        "is_editable": false,
        "serial_no": 1,
        "isHyperlink": false
      },
      {
        "header_id": 2,
        "field": "product_name",
        "header": "Product Name",
        "is_show": true,
        "is_editable": false,
        "serial_no": 2,
        "isHyperlink": this.currentBU === "EV",
      },
      {
        "header_id": 5,
        "field": "article_number",
        "header": "Article Number",
        "is_show": true,
        "is_editable": false,
        "serial_no": 5,
        "isHyperlink": false
      },
      {
        "header_id": 9,
        "field": "distributor_price_from_basf",
        "header": "List Price",
        "is_show": true,
        "is_editable": false,
        "serial_no": 9,
        "isHyperlink": false
      },
      {
        "header_id": 10,
        "field": "scale_quantity1",
        "header": "Scale Quantity1",
        "is_show": true,
        "is_editable": false,
        "serial_no": 10,
        "isHyperlink": false
      },
      {
        "header_id": 11,
        "field": "scale_rate1",
        "header": "Scale Rate1",
        "is_show": true,
        "is_editable": false,
        "serial_no": 11,
        "isHyperlink": false
      },
      {
        "header_id": 12,
        "field": "scale_quantity2",
        "header": "Scale Quantity2",
        "is_show": true,
        "is_editable": false,
        "serial_no": 12,
        "isHyperlink": false
      },
      {
        "header_id": 13,
        "field": "scale_rate2",
        "header": "Scale Rate2",
        "is_show": true,
        "is_editable": false,
        "serial_no": 13,
        "isHyperlink": false
      },
      {
        "header_id": 14,
        "field": "scale_quantity3",
        "header": "Scale Quantity3",
        "is_show": true,
        "is_editable": false,
        "serial_no": 14,
        "isHyperlink": false
      },
      {
        "header_id": 15,
        "field": "scale_rate3",
        "header": "Scale Rate3",
        "is_show": true,
        "is_editable": false,
        "serial_no": 15,
        "isHyperlink": false
      },
      {
        "header_id": 16,
        "field": "scale_quantity4",
        "header": "Scale Quantity4",
        "is_show": true,
        "is_editable": false,
        "serial_no": 16,
        "isHyperlink": false
      },
      {
        "header_id": 17,
        "field": "scale_rate4",
        "header": "Scale Rate4",
        "is_show": true,
        "is_editable": false,
        "serial_no": 17,
        "isHyperlink": false
      },
      {
        "header_id": 18,
        "field": "scale_quantity5",
        "header": "Scale Quantity5",
        "is_show": true,
        "is_editable": false,
        "serial_no": 18,
        "isHyperlink": false
      },
      {
        "header_id": 19,
        "field": "scale_rate5",
        "header": "Scale Rate5",
        "is_show": true,
        "is_editable": false,
        "serial_no": 19,
        "isHyperlink": false
      },
      {
        "header_id": 20,
        "field": "scale_quantity6",
        "header": "Scale Quantity6",
        "is_show": true,
        "is_editable": false,
        "serial_no": 20,
        "isHyperlink": false
      },
      {
        "header_id": 21,
        "field": "scale_rate6",
        "header": "Scale Rate6",
        "is_show": true,
        "is_editable": false,
        "serial_no": 21,
        "isHyperlink": false
      },
      {
        "header_id": 22,
        "field": "valid_from",
        "header": "Product Valid From",
        "is_show": true,
        "is_editable": false,
        "serial_no": 22,
        "isHyperlink": false
      },
      {
        "header_id": 23,
        "field": "valid_to",
        "header": "Product Valid To",
        "is_show": true,
        "is_editable": false,
        "serial_no": 23,
        "isHyperlink": false
      },
      {
        "header_id": 24,
        "field": "status",
        "header": "Status",
        "is_show": false,
        "is_editable": false,
        "serial_no": 24,
        "isHyperlink": false
      },
    ];
    switch (this.bu) {
      case 'EM': this.currentBusinessUnit = this.EMID;this.cols = this.emCols; break;
      case 'EV': this.currentBusinessUnit = this.EVID;this.cols = this.evCols; break;
      case 'PM': this.currentBusinessUnit = this.PMID;this.cols = this.pmCols; break;
      case 'ED': this.currentBusinessUnit = this.EDID;this.cols = this.edCols; break;
      default: this.currentBusinessUnit = this.EMID;this.cols = this.emCols; break;
    }
    this._service.getAdminConfigMaster().subscribe((res: any) => {
      if (!res['isError']) {
        this.response = res;
        this.BusinessUnitData = this.response.buData;
        this.CountryData = this.response.countryData;
        this.selectedCountry = this.CountryData[0];
        this.RegionData = this.response.regionData;
        this.productPackageDD = this.response.packageType ? this.response.packageType.filter(type => type.businessunit_name == this.bu) : [];
        this.selectedRegion = this.RegionData[0];
        let key = 'businessunit_id';
        this.BusinessUnit = [...new Map(this.BusinessUnitData.map(item =>
          [item[key], item])).values()];
        this.BusinessUnit.forEach(bu => {
          if (bu.businessunit_name === this.currentBU) {
            this.selectedBusiness = bu.businessunit_id;
          }
        });
        this.getAllProductsyBu(this.currentBusinessUnit);
      } else {
        this._service.displayToastr(res);
      }
    });
    this.selectedCountry = this.countryData[0];
    this.getAllProductsyBu(this.currentBusinessUnit);
    this.getBuUser(this.currentBusinessUnit);
  }

  getAllProductsyBu(value) {
    this.spinner.show();
    let bu_id = value;
    this._service.getAllProductsyBu(bu_id, this.selectedCountry.country_id).subscribe((res) => {
      this.spinner.hide();
      if (!res['isError']) {
        this.apiResp = res;
        this.products = JSON.parse(JSON.stringify(this.apiResp['response']));
        this.productsDD = this.getUniqueListBy(this.products, 'product_name');
        this.products.forEach(p => {
          if (p.sold_To == 0) {
            p.sold_To = '';
          }
          if(p.is_active) {
            p.status = 'Active';
          } else {
            p.status = 'InActive';
          }
        })
      } else {
        this._service.displayToastr(res);
      }
    })
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  getBuUser(value) {
    let bu_id = value;
    this._service.getBUUsers(bu_id).subscribe((res) => {
      if (!res['isError']) {
        this.Bu_user = res['lstUsers']
      } else {
        this._service.displayToastr(res);
      }
    })
  }

  filterProductsByCountry(_event) {
    this.getAllProductsyBu(this.currentBusinessUnit);
  }

  submitData(value) {
    let pid = []
    value.product_name.filter(x => { console.log(x.product_id); pid.push(x.product_id) })
    
    if (this.selectedProductManager || this.selectedProductPackage) {
      let data = {
        "SelectedProductId": pid.toString(),
        "ManagerId": this.selectedProductManager ? this.selectedProductManager.user_id : 0,
        "ModifiedBy": parseInt(sessionStorage.getItem('userid')),
        "BuId": this.selectedBusiness,
        "PackageType": this.selectedProductPackage ? this.selectedProductPackage.value : ''
      }
      this._service.UpdateProductMangerMapping(data).subscribe(res => {
        this.getAllProductsyBu(this.currentBusinessUnit);
        this.displayResult(res);
        this.selectedProductPackage = undefined;
        this.selectedProductManager = undefined;
      })
    } else {
      this.toastr.warning("Select any Package Type or Product Manager to Update");
    }
  }

  onSelectRow(rowObject) {
    let { rowData, type } = rowObject;
    switch (type) {
      case 'hyperlink': this.onHyprelinkClick(rowData); break;
      case 'status': this.productStatusUpdate(rowData); break;
      default: break;
    }
  }

  productStatusUpdate(rowData) {
    this.spinner.show();
    this._service.ChangeProductStatus(rowData.product_id, sessionStorage.getItem("loginuser")).subscribe((response) => {
      this.getAllProductsyBu(this.selectedBusiness);
      this.spinner.hide();
    })
  }

  onHyprelinkClick(value) {
    this.displayProductInfoPopup = true;
    this.productInfo = JSON.parse(JSON.stringify(value));
  }
  closePopup() {
    this.displayProductInfoPopup = false;
    this.massChangePMPopup = false;
  }

  showMassChangeDialog() {
    this.filteredBU_users = this.Bu_user;
    this.currentProductsManagesList = this.getUniqueListBy(this.products, 'product_manager');
    this.massChangePMPopup = true;
  }

  currentPmDDChange(value) {
    this.filteredBU_users = this.Bu_user.filter(user => user.user_id !== value.product_manager);
  }

  changePM() {
    if(!this.massChangeCurrentPM || !this.massChangeNewPM) {
      this.toastr.error("Required to select values before continuing");
      return;
    }
    this._service.ChangeProductManager(this.massChangeCurrentPM.product_manager, this.massChangeNewPM.user_id).subscribe(res => {
      this.massChangePMPopup = false;
      if(!res['isError']) {
        this.getAllProductsyBu(this.currentBusinessUnit);
        this.toastr.success("Product manager changed", "Success");
      } else {
        this._service.displayToastr(res);
      }
    });
  }

  displayResult(res) {
      this._service.displayToastr(res);
  }

  filterData(filterObj) {
    let { value, filter } = filterObj;
    if (value == '') {
      if (this.filteredFields.length) {
        this.filteredFields = this.filteredFields.filter(ff => ff.fieldName !== filter);
      } else {
        this.filteredFields = [];
      }
    } else {
      let foundField = this.filteredFields.filter(ff => ff.fieldName == filter);
      if (foundField.length) {
        this.filteredFields.forEach(ff => {
          if (ff.fieldName == filter) {
            ff.value = value;
          }
        })
      } else {
        let fieldObj = {
          fieldName: filter,
          value: value
        };
        this.filteredFields.push(fieldObj);
      }
    }
    if (this.filteredFields.length) {
      let temp = this.usersData;
      this.filteredFields.forEach(ff => {
        temp = temp.filter(fd => fd[ff.fieldName].toUpperCase().includes(ff.value.toUpperCase()));
      })
      this.filteredData = temp;
    } else {
      this.filteredData = JSON.parse(JSON.stringify(this.usersData));
    }
    this.tempFilteredData = JSON.parse(JSON.stringify(this.filteredData));
  }

  submitProduct() {
    this.spinner.show();
    this._service.updateEVProductDetails(this.productInfo).subscribe(res => {
      this.getAllProductsyBu(this.selectedBusiness);
      this.spinner.hide();
      this.closePopup();
    });
  }
}
