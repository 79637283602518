import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CupsService } from 'src/services/cups.service';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css']
})
export class UserRegistrationComponent implements OnInit {
  @Input() formData: any;
  @Input() formType: any;
  @Output() reLoadGrid = new EventEmitter();
  apiResp: any;
  apiRequest = {
    "created_by": sessionStorage.getItem('userid'),
    "business_unit": [],
    "region_id": 0,
    "is_active": true
  };
  selectedBU: any;
  businessUnits = [
    { id: 1, businessUnit: 'EM'},
    { id: 2, businessUnit: 'EV'},
    { id: 3, businessUnit: 'PM'},
    { id: 4, businessUnit: 'ED'}
  ]
  constructor(private _service: CupsService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit() {
    if (this.formType !== 'New') {
      this.selectedBU = [];
      let tBUnits = this.formData['businessUnits'].split(",");
      tBUnits.forEach(t => {
        let fBUnit = this.businessUnits.find(bu => bu.businessUnit == t);
        if (fBUnit) {
          this.selectedBU.push(fBUnit);
        }
      });
    }
  }

  submitUser() {
    if (this.checkValidations()) {
      this.spinner.show();
      if (this.formType === 'New') {
        this.apiRequest['user_id'] = 0;
      } else {
        this.apiRequest['user_id'] = this.formData.user_id;
      }
      this.apiRequest['basf_user_id'] = this.formData.basf_user_id;
      this.apiRequest['first_name'] = this.formData.first_name;
      this.apiRequest['last_name'] = this.formData.last_name;
      this.apiRequest['email'] = this.formData.email;
      this.apiRequest['is_admin'] = this.formData.is_admin;
      let businessUnits = [];
      this.selectedBU.forEach(sbu => {
        let bObj = {
          "Id": 0,
          "userid": this.apiRequest['user_id'],
          "businessunit_id": sbu.id
        };
        businessUnits.push(bObj);
      });
      this.apiRequest['business_unit'] = businessUnits;
      this._service.submitUser(this.apiRequest).subscribe(response => {
        this.spinner.hide();
        if (!response['isError']) {
          this._service.displayToastr(response);
          this.apiResp = response;
          this.reLoadGrid.emit(true);
        } else {
          this._service.displayToastr(response);
        }
      })
    }
  }

  checkValidations() {
    if(this.formData.basf_user_id == null || this.formData.basf_user_id == undefined || this.formData.basf_user_id == '') {
      this.toastr.error("BASF UserName required field");
      return false;
    }
    if(this.formData.first_name == null || this.formData.first_name == undefined || this.formData.first_name == '') {
      this.toastr.error("FirstName required field");
      return false;
    }
    if(this.formData.last_name == null || this.formData.last_name == undefined || this.formData.last_name == '') {
      this.toastr.error("LastName required field");
      return false;
    }
    if(this.formData.email == null || this.formData.email == undefined || this.formData.email == '' || !this.checkValidEmail(this.formData.email)) {
      this.toastr.error("Email required field");
      return false;
    }
    if(!this.selectedBU) {
      this.toastr.error("Business Unit required field");
      return false;
    } 
    if(this.selectedBU && this.selectedBU.length > 1) {
      this.toastr.error("Select only one Business Unit");
      return false;
    }
    return true;
  }


  checkValidEmail(email) {
    if (email == '') {
      return false;
    } else {
      let reg = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
      if (!reg.test(email)) {
        this.toastr.error("Invalid Email");
        return false;
      }
    }
    return true;
  }

  cancel() {
    this.reLoadGrid.emit(false);
  }

}
